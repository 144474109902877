.createOrderCard-bgSecond {
    background-color: #EDEFF2 !important;
    border: 2px solid #EDEFF2 !important;
}
.createOrderCard-deliveryLoc{
    background-image: url(../../../SVG/LocationGrey.svg);
    background-repeat: no-repeat;
    background-size: 16px;
    width: 100%;
    text-align: left;
    background-position: 20px;
    padding-left: 55px !important;
}
.createOrderCard-bgSecond:focus{
    border: 2px solid#61A60E !important;
    background-color: #fff !important;
}

.createOrderCard-deliveryDateBtn {
    color: #989CA6;
    font-size: 20px;
    font-weight: 400;
    background: #F5F6F7;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    border: 1px solid #F5F6F7;
    padding: 12px 16px;
    background-image: url(../../../SVG/DownArrowGrey.svg);
    background-repeat: no-repeat;
    background-size: 16px;
    width: 100%;
    text-align: left;
    background-position: 95%;
}
.createOrderCard-deliveryDateBtn img{
    margin-right: 20px;
}
.createOrderCard-deliveryDateBtn:focus{
    border: 2px solid#61A60E !important;
    background-color: #fff !important;
}
.createOrderCard-deliveryTimeSelect{
    padding: 12px 16px !important;
    background-image: url(../../../SVG/Watch.svg), url(../../../SVG/DownArrowGrey.svg) !important;
    background-repeat: no-repeat, no-repeat;
    background-position: 5%, 95% !important;
    padding-left: 52px !important;

}
.createOrderCard p span {
    font-size: 20px;
    font-weight: 400;
    color: #989CA6;
    margin: 5px 0 0;
}

.createOrderCard img {
    width: 16px;
}

.createOrderCardGreyBtn {
    background: transparent;
    padding: 10px 25px;
    font-size: 20px;
    color: #61A60E;
    border: 1px solid #61A60E;
    outline: none;
    border-radius: 30px;
    font-weight: 400;
    min-width: 160px;
}

.createOrderCardGreenBtn {
    background: #61A60E;
    padding: 10px 25px;
    font-size: 20px;
    color: #fff;
    border: 1px solid #61A60E;
    outline: none;
    border-radius: 30px;
    font-weight: 400;
    min-width: 160px;
}
.createOrderCardDisabledBtn{
    background: #EDEFF2;
    padding: 10px 25px;
    font-size: 20px;
    color: #989CA6;
    border: 1px solid #EDEFF2;
    outline: none;
    border-radius: 30px;
    font-weight: 400;
    min-width: 160px;
    cursor: not-allowed;
}
.createOrderCard p {
    font-size: 20px;
    font-weight: 400;
    color: #464B55;
    margin: 5px 0 0;
}

.createOrderCard label {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    margin-bottom: 8px;
}

.createOrderCard input[type=text] {
    background-color: #f5f6f7;
    border-radius: 8px;
    font-size: 20px;
    padding: 12px 16px;
    width: 100%;
    border: 2px solid #f5f6f7;
    margin-bottom: 15px;
}

.createOrderCard input[type=text]:focus,
.createOrderCard select:focus {
    outline: none;
    border: 2px solid #61A60E;
    background-color: #fff;
}
.createOrderCardRadio-focus{
    border: 2px solid #61A60E !important;
}

.createOrderCard select {
    background: #f5f6f7;
    border-radius: 8px;
    font-size: 20px;
    padding: 12px 16px;
    width: 100%;
    border: 2px solid #f5f6f7;
    margin-bottom: 15px;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(../../../SVG/DownArrowGrey.svg);
    background-repeat: no-repeat;
    background-size: 16px;
    width: 100%;
    text-align: left;
    background-position: 95%;
    color:#464B55;

}

.createOrderCard textarea {
    background: #F5F6F7;
    border-radius: 8px;
    font-size: 20px;
    padding: 12px 16px;
    width: 100%;
    border: 2px solid #F5F6F7;
    min-height: 48px;
    margin-bottom: 15px;
}

.createOrderCard textarea:focus {
    outline: none;
    border: 2px solid #61A60E;
    background-color: #fff;
}

.createOrderCardRadio {
    border: 2px solid #D3D6DA;
    border-radius: 8px;
    padding: 15px 5px;
    margin-bottom: 20px;
}
.createOrderCardRadio-selected {
    border: 2px solid #61A60E;
    border-radius: 8px;
    padding: 15px 5px;
    margin-bottom: 20px;
}
.createOrderCardRadio input[type=radio] {
    accent-color: #61A60E;
  }
.createOrderCardRadio label {
    margin-bottom: 0px;
}

.createOrderCardRadio:focus {
    border: 2px solid #61A60E;
}

.createOrderCardRadio-selected input[type=radio] {
    accent-color: #61A60E;
  }
.createOrderCardRadio-selected label {
    margin-bottom: 0px;
}

.createOrderCardRadio-selected:focus {
    border: 2px solid #61A60E;
}

.createOrderCardRadioWithoutBorder{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.createOrderCardRadioWithoutBorder label {
    margin-bottom: 0px;
}

.createOrderCardRadioWithoutBorder{
    accent-color: #61A60E;
}

.react-calendar.hide {
    display: none;
}

.createOrderCard-edit {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.createOrderCard-edit p span {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 0px;
    border-bottom: 1px solid #61A60E;
    color: #61A60E;
}

.createOrderCard-edit p img {
    margin-right: 5px;
    width: 14px;
}

.createOrderCard-editAddress {
    border: 2px solid #61A60E;
    border-radius: 8px;
    padding: 20px 20px 24px;
}

.createOrderCard-editAddress input[type=checkbox] {
    accent-color: #61A60E;
  }

.createOrderCard-editAddress-cancel {
    outline: none;
    border: 1px solid #61A60E;
    color: #61A60E;
    border-radius: 30px;
    min-width: 80px;
    height: 44px;
    background-color: #fff;
    font-size: 20px;
    font-weight: 400;
    padding: 5px 24px;
}

.createOrderCard-editAddress-save {
    font-size: 20px;
    font-weight: 400;
    background: #61A60E;
    border: 1px solid #61A60E;
    outline: none;
    border-radius: 32px;
    height: 44px;
    min-width: 80px;
    padding: 5px 24px;
    color: #fff;
}

.createOrderCard-editAddress-save-disbled {
    font-size: 20px;
    font-weight: 400;
    background: #EDEFF2;
    border: 1px solid #EDEFF2;
    outline: none;
    border-radius: 32px;
    height: 44px;
    min-width: 80px;
    padding: 5px 24px;
    color: #989CA6;
}

.createOrderCard-CalanderDesktop .react-calendar{
    position: absolute;
    width: 100%;
    left: 0;
    top: 60px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 12px;
    z-index: 1;
    padding: 10px;
}
.createOrderCard-CalanderDesktop .react-calendar__navigation{
    display: flex;
    align-items: center;
}
.createOrderCard-CalanderDesktop button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
.createOrderCard-CalanderDesktop button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button{
display: none;
}
.createOrderCard-CalanderDesktop .react-calendar__navigation button,
.createOrderCard-CalanderDesktop button.react-calendar__tile {
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 20px;
    color: #464B55;
    margin-bottom: 10px;

}

.createOrderCard-CalanderDesktop .react-calendar__month-view__weekdays__weekday {
    text-transform: uppercase;
    font-size: 16px;
    border: none;
}

.createOrderCard-CalanderDesktop .react-calendar__month-view__weekdays__weekday abbr[title] {
    text-decoration: none;
}

.createOrderCard-CalanderDesktop button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    color: #61A60E;
}
.createOrderCard-CalanderDesktop button.react-calendar__tile.react-calendar__month-view__days__day{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.createOrderCard-CalanderDesktop button.react-calendar__tile.react-calendar__month-view__days__day:hover{
color: #464B55;;
background-color: #F5F6F7;
}
.createOrderCard-CalanderDesktop button.react-calendar__tile.react-calendar__month-view__days__day:focus{
    color: #61A60E;
    background-color: #EFF6E7;
}
.createOrderCard-editAddress-textarea-counts{
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    margin-top: 8px;
    float: right;
}
/* color: #989CA6 */
.row .createOrderCardRadio .css-ahj2mt-MuiTypography-root {
    letter-spacing: 0.00938em;
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
    color: #464B55;
    margin: 0;
}