@import "../index.css";
* {
    box-sizing: border-box;
    font-family: 'DB Helvethaica X 55 Regular';
}
.my-translated-paragraph-nextline{
    white-space: pre-wrap;
}
.let-go-heading{
    font-size: 64px!important;
    font-weight: 500!important;
}
.letsShop {
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    padding: 48px;
    border-radius: 20px;
}
.letShopheader{
    display: flex;
    margin-bottom: 5%;
    align-items: center;
}
.letShopheader h1{
    font-size: 64px;
    /* font-style: italic; */
    width: 80%;
    color: #FFFFFF;
    font-weight: 500;
}

.letShopheader button{
    width: 213px;
    background: #FFFFFF;
    border-radius: 80px;
    color: #61A60E;
    height: 45px;
    border: none;
    font-size: 24px!important;
}

.cardCenter{
    text-align: center;
}
.letshopText{
    font-weight: 700;
    color: #fff;
    font-size: 28px;
    font-family: "DB Helvethaica X 55 Regular"!important;
}
.letShopImage{
    width: 124px;
    height: 124px;
}
/* 
@media (min-width: 576px) and (max-width: 767px){
    .lestShop .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 768px) and (max-width: 991px)
{
    .lestShop .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
}
@media (min-width: 992px)
{
    .lestShop .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
} */

body{
    background-color: #F5F5F5;
}
.let-start-main-container{
    margin-top: 90px;
}
.find-out-more{
    text-transform: none!important;
    font-size: 16px!important;
}
.find-out-more:hover {
    background-color: #61A60E!important;
    color: #fff!important;
}
.find-out-button-mobile-view {
    display: none;
}
.lets-betagro-background-image {
    background: url("./bg.svg") no-repeat;
    position: static;
    overflow: hidden;
    background-repeat: repeat;
    background-size: 100% 100%;
}