@import "../index.css";

* {
    font-family: 'DB Helvethaica X 55 Regular' !important;
    box-sizing: border-box !important;
}
body {
    font-family: 'DB Helvethaica X 55 Regular' !important;
    box-sizing: border-box !important;
}
/* .my-cart-overflow-hidden {
    overflow: hidden;
} */
.my-cart-main-container {
    padding-top: 48px;
    padding-bottom: 64px;
}
.my-cart-summary-section-main-container {
    margin-top: 48px!important;
    margin-bottom: 10px;
}

.my-cart-main-heading {
    font-weight: 500;
    font-size: 48px;
    line-height: 50px;
    color: #464B55;
}

.my-cart-summary-heading {
    font-weight: 500;
    font-size: 48px;
    color: #464B55;
}

.my-cart-items-heading {
    color: #464B55;
    font-weight: 500;
    font-size: 24px;
}

.my-cart-customer-name-heading {
    font-size: 20px;
    font-weight: 400;
    color: #464B55;
}

.my-cart-dropdown {
    margin-top: 24px;
}

.table-my-cart {
    margin-top: 24px;
}

.table-my-cart .table-my-cart-header-heading {
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    position: absolute;
    left: 30px;
    top: 27px;
}
.my-card-table-all-product-content-main .css-i4bv87-MuiSvgIcon-root {
    opacity: 0.7!important;
}
.table-my-cart .css-i4bv87-MuiSvgIcon-root {
    opacity: 0.7!important;
}
.table-my-cart .css-vubbuv {
    opacity: 0.7!important;
}
.my-card-table-all-product-content-main .css-vubbuv {
    opacity: 0.7!important;
}

.my-cart-summary-heading
/* .table-my-cart .css-i4bv87-MuiSvgIcon-root {
    height: 16px !important;
    width: 16px !important;
} */

.table-my-cart .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #61A60E !important;
}

.my-card-table-all-product-content .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root:hover {
    background-color: #fff !important;
}

.my-card-gray-line {
    border: 1px solid #D3D6DA;
    width: 100% !important;
    margin-top: 12px;
}

.my-cart-summary-section-gray-line {
    border: 1px solid #D3D6DA;
    width: 100% !important;
    margin-top: 48px;
}


.my-card-table-all-product-content {
    margin-top: 16px;
}
.my-card-table-all-product-content-main {
    margin-top: 16px;
}
.my-card-table-all-product-content-main .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #61A60E!important;
}
.my-card-table-all-product-content-main .css-zun73v.Mui-checked {
    color: #61A60E!important;
}
.my-card-table-all-product-content-main .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root:hover{
    background-color: #fff!important;
}
.my-card-table-all-product-content-main .css-zun73v:hover{
    background-color: #fff!important;
}
.my-card-product-image {
    height: 72px;
    width: 72px;
    object-fit: contain;
}
.my-cart-product-name-id {
    padding-left: 6px;
    cursor: pointer !important;
}
.my-cart-sales-artical-code {
    color: #989CA6;
    font-size: 20px;
    font-weight: 400;
}
.my-cart-items-out-of-stock {
    padding: 2px 2px;
    width: 82;
    height: 24px;
    background: #F5F6F7;
    border-radius: 26px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #989CA6;
}

.my-cart-product-content-heading {
    font-weight: 600;
    font-size: 20px;
    color: #464B55;
}
.my-cart-product-content-heading-product-name {
    font-weight: 600;
    font-size: 20px;
    color: #464B55;
}
.my-cart-product-content-heading-product-name:hover {
    color: #61A60E;
}

.my-card-table-all-product-content .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #61A60E !important;
}
.my-card-table-all-product-content .css-zun73v.Mui-checked {
    color: #61A60E !important;
}

.table-my-cart .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #61A60E !important;
}
.table-my-cart .css-zun73v.Mui-checked {
    color: #61A60E !important;
}
.my-cart-delete-image {
    height: 11px;
    margin-top: 7px;
    padding-left: 17px;
    cursor: pointer;
}
.my-cart-input-group {
    width: 100%;
    outline: none;
    background-color: #F5F6F7;
    border: none;
    padding: 0px;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #464B55;
    font-family: 'DB Helvethaica X 55 Regular' !important;
    font-weight: 600!important;
}

.my-cart-add-minus-background {
    padding: 4px;
    height: 36px;
    background: #F5F6F7;
    border-radius: 40px;
    width: 120px;
}

.my-cart-counts-heading {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 2px;
}

.my-cart-add-plus-image {
    cursor: pointer;
    width: 28px;
    height: 28px;
}

.my-cart-text-center {
    text-align: center;
}

.my-cart-table-inner-padding {
    padding-top: 25px;
}

.my-cart-table-plus-minus-padding {
    padding-top: 20px;
}

.my-cart-summary-section {
    background: #F5F6F7;
}

.my-cart-quantity-subtitle {
    font-size: 20px;
    font-weight: 400;
    color: #464B55;
    margin-top: 8px;
}

.my-cart-quantity-subtitle-price {
    font-size: 20px;
    font-weight: 700;
    color: #464B55;
    text-align: right;
    margin-top: 8px;
}

.my-cart-summary-padding {
    padding-left: 25px;
    padding-right: 25px;
}

.my-cart-summary-main-container {
    margin-top: 40px;
}

.my-cart-promo-input {
    padding: 7px 20px;
    background: #FFFFFF;
    border-radius: 8px 0px 0px 8px;
    color: #989CA6;
    border: none;
    outline: none;
    font-size: 20px;
}
.my-cart-promo-input-mobile-view {
    padding: 7px 20px;
    background: #FFFFFF;
    border-radius: 8px 0px 0px 8px;
    color: #989CA6;
    border: none;
    outline: none;
    font-size: 20px;
    border-width: 2px 0px 2px 2px;
    border-style: solid;
    border-color: #D3D6DA;
    border-radius: 8px 0px 0px 8px;
}
.my-cart-promo-code {
    color: #464B55;
    font-size: 20px;
    font-weight: 600;
    margin-top: 16px;
}

.my-cart-apply-active-button {
    outline: none;
    background-color: #61a60e;
    color: #fff;
    width: 100%;
    border-radius: 0px 8px 8px 0px;
    justify-content: center;
    padding: 10px 16px;
    border: none;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 20px;
}
.my-cart-apply-disable-button {
    outline: none;
    background: #EDEFF2;
    color: #989CA6;
    width: 100%;
    border-radius: 0px 8px 8px 0px;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 20px;
}
.my-cart-apply-disable-button-mobile-view {
    outline: none;
    background: #EDEFF2;
    color: #989CA6;
    width: 100%;
    border-radius: 0px 8px 8px 0px;
    justify-content: center;
    padding: 7px 20px;
    border: none;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 20px;
}
.my-cart-appy-button-container {
    margin-top: 48px;
}

.my-cart-checkout-button {
    outline: none;
    background-color: #61a60e;
    color: #fff;
    justify-content: center;
    padding: 12px 24px;
    border: none;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 20px;
    margin-top: 24px;
    border-radius: 80px;
}
.my-cart-checkout-button-mobile-view {
    outline: none;
    background-color: #61a60e;
    color: #fff;
    justify-content: center;
    padding: 10px 24px 10px 24px;
    border: none;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 20px;
    border-radius: 80px;
    float: right;
}
.my-cart-total-price-container {
    margin-top: 48px !important;
}
.my-cart-out-of-stock-heading {
    font-weight: 500;
    font-size: 16px;
    color: #BFC2C9;
    padding-left: 6px;
}
.my-cart-out-of-stock-image {
    height: 14px;
    width: 14px;
    margin-top: 1px;
}
/* my-cart-is-empty start from here */
.my-cart-is-empty-main-container {
    margin-top: 128px;
    margin-bottom: 128px;
    text-align: center;
}
.my-cart-is-empty-heading{
  color:#989CA6;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
}
.my-cart-is-empty-button {
    outline: none;
    background-color: #61a60e;
    color: #fff;
    border-radius: 32px;
    padding: 10px 0px;
    border: none;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 20px;
    margin-top: 40px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 7px;
}
.my-cart-is-empty-button a {
    color: #fff;
}
.my-cart-checkout-button a {
    color: #fff;
}
/* my-cart-is-empty end */
.my-cart-stricky-right-section {
    position: sticky;
    top: 5px;
    margin-bottom: 20px;
}
/* price-change-banner css */
.price-change-banner {
    padding: 8px 12px 8px 6px;
    height: auto;
    background: #FEF7EB;
    border-radius: 8px;
    margin-top: 40px!important;
}
.my-cart-vertical-line {
    width: 4px;
    height: auto;
}
.my-cart-alert-image {
    height: 16px;
    width: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
}
.price-banner-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
}
.price-banner-common-padding {
    padding-top: 8px;
    padding-bottom: 8px;
}
.price-banner-cross-icon {
    height: 16px;
    width: 16px;
    color: #464B55;
    margin-top: 4px;
    cursor: pointer;
}
.offer-green-banner-section {
    padding: 2px 20px;
    width: 100%;
    height: 32px;
    background: #EFF6E7;
    border-radius: 6px;
    margin-top: 16px;
}
.green-banner-image {
    width: 16px;
    height: 16px;
    margin-bottom: 8px;
}
.green-banner-heading {
    font-weight: 400;
    font-size: 20px;
    color: #61A60E;
    padding-left: 9px;
}
.my-cart-product-content-delete-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: line-through;
    color: #464B55;
}
.my-cart-new-promo-badge {
    position: absolute;
    top: 2px;
    left: 0px;
}
.my-cart-new-items-badge {
    position: absolute;
    top: 2px;
    left: 0px;
}
.my-cart-product-image-border {
    border: 1px solid #D3D6DA;
    border-radius: 8px;
    height: 76px;
    width: 76px;
    position: relative;
    cursor: pointer !important;
}
.my-cart-error-message {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #EF3145;
    margin-top: 8px;
}
.my-cart-information-indicator {
    padding: 0px 6px 0px 6px;
    background: #FCD6DA;
    border-radius: 26px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #EF3145;
    white-space: nowrap;
    margin-left: 2px;
}
.my-cart-information-indicator-image {
    height: 12px;
    width: 12px;
    margin-bottom: 2px;
}
/* customer-name-dropdown-section start from here */
.my-cart-dropdown .MuiOutlinedInput-root {
    border-radius: 6px!important;
    align-items: center!important;
    padding: 6px 48px!important;
    background-color: #F5F6F7!important;
    font-weight: 400!important;
    font-size: large!important;
}
.my-cart-dropdown .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
    display: none!important;
    font-size: large!important;
}
.my-cart-dropdown .css-vubbuv {
    display: none;
}
.my-cart-dropdown .css-rnuy54-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-weight: 400!important;
    font-size: 20px!important;
    align-items: center!important;
    color: #989CA6!important;
}
.my-cart-dropdown .css-bvz20t .MuiAutocomplete-input  {
    font-weight: 400!important;
    font-size: 20px!important;
    color: #989CA6!important;
}
.my-cart-dropdown .css-gcwvw8-MuiAutocomplete-root .MuiAutocomplete-input {
    font-weight: 400!important;
    font-size: 20px!important;
    color: #989CA6!important;
}
.my-cart-dropdown .css-1tmnquq .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    font-weight: 400!important;
    font-size: 20px!important;
    align-items: center!important;
    color: #989CA6!important;
}
.my-cart-dropdown .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border:none!important;
}
.my-cart-dropdown .css-igs3ac {
    border:none!important;
}
.my-cart-dropdown .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid #61A60E!important;
}
.my-cart-dropdown .css-i4bv87-MuiSvgIcon-root {
    display: none!important;
}
.customer-name-avatar {
    position: absolute!important;
    height: 16px!important;
    width: 16px!important;
    z-index: 12!important;
    top: 65px!important;
    left: 38px!important;
}
.my-cart-dropdown .css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
}
.my-cart-dropdown .css-ue1yok .MuiAutocomplete-option {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
}
.my-cart-dropdown .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    font-weight: 400!important;
    font-size: 20px!important;
    color: #989CA6!important;
}
.my-cart-dropdown .css-1ixds2g.Mui-disabled  {
    font-weight: 400!important;
    font-size: 20px!important;
    color: #989CA6!important;
}
/* customer-name-dropdown-section end */
.my-cart-desktop-view{
    display: none;
}
.table-my-cart-header-heading-mobile-view {
    font-size: 20px;
    font-weight: 400;
    color: #464B55;
}
.my-cart-total-mobile-view {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 22px;
}
.my-cart-total-heading-mobile-view {
    font-size: 20px;
    font-weight: 400;
    color:#464B55;
}
.my-cart-total-price-mobile-view {
    font-size: 20px;
    font-weight: 700;
    color: #464B55;
    padding-left: 8px;
}
.my-cart-total-mobile-view .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    margin-bottom: 8px!important;
}
.my-cart-back-button {
    height: 16px;
    width: 10px;
    cursor: pointer;
}
.my-cart-back-heading {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: #464B55;
    margin-top: 8px;
    white-space: nowrap;
}
.my-cart-back-button-main-container {
    margin-top: 24px;
    align-items: center;
}
.my-cart-total-mobile-view .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #61A60E!important;
}
.my-cart-total-mobile-view .css-zun73v.Mui-checked {
    color: #61A60E!important;
}
.my-cart-total-mobile-view .css-vubbuv {
    color: #61A60E!important;
}
.out-of-stock-container-green {
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    width: 100%;
    height: 24px;
    background: #EFF6E7;
    border-radius: 26px;
    margin-bottom: 8px;
}
.out-of-stock-heading-green {
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #61A60E;
    margin-bottom: 8px;
}
.out-of-stock-container-disable {
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    width: 100%;
    height: 24px;
    background:  #F5F6F7;
    border-radius: 26px;
    margin-bottom: 8px;
}
.out-of-stock-heading-disable {
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color:  #989CA6;;
    margin-bottom: 8px;
}
.table-my-cart  .css-zun73v.MuiCheckbox-indeterminate {
    color: #61A60E!important;
}
.quantity-out-of-stock-container {
    padding-top: 9px;
}
.my-cart-delete-image-mobile {
    display: none;
}
.my-cart-left-container-padding {
    padding-left: 60px;
}