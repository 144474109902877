@import "../index.css";
* {
    box-sizing: border-box;
    font-family: 'DB Helvethaica X 55 Regular';
  }
footer {
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
    /* margin: 100px 0 0; */
    padding: 30px 0 0px;
width: 100% !important;
}
.footer-container{
    padding: 0 100px;
}
.bg-footer-logo svg{
    width: 190px;
    display: block;
}
.bg-footer-in h5{
    color: #464B55;
    font-size: 20px;
    font-weight: 600;
}
.bg-footer-in li{
    margin-bottom: 5px;
}
.bg-footer-in li a {
    color: #464B55;
    text-decoration: none;
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    white-space: nowrap;
}

.bg-footer-in li a svg {
    width: 15px;
    margin-right: 5px;
    margin-top: -3px;
}

.footer-terms {
    display: flex;
    flex-direction: row;
    color: #464B55;
    padding-bottom: 14px;
}

.footer-terms ul {
    display: flex;
    flex-direction: row;
}

.footer-terms ul li{
font-size: 16px;
}
.footer-terms ul li span {
    padding: 0 15px;
}

.footer-terms ul li span:before {
    content: '';
    width: 5px;
    height: 5px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 5px;
    background-color: #4d4d4d;
}
.dbd-company-logo-main-container {
    position: relative;
}
.dbd-company-logo {
    margin-top: 2px;
    width: 74px;
    padding: 0px!important;
    position: absolute;
    left: 26px;
}
.footer-logo-image {
    margin-right: 9px;
    margin-bottom: 2px;
}
@media(max-width: 991px) {

    footer {
        margin: 0;
        padding: 15px 0 0px;
    }
    .footer-container{
        padding: 0 40px;
    }
    .bg-footer-logo svg{
        width: 150px;
        display: block;
    }
    .bg-footer-in li a svg {
        width: 15px;
        margin-right: 5px;
    }
    .footer-terms ul li span {
        padding: 0 10px;
    }
}

@media screen and (max-width: 767px) {
    .bg-footer-logo svg {
        width: 230px;
        display: block;
        margin: 15px 0 20px;
    }
    .footer-container{
        padding: 0 20px;
    }
    .footer-terms ul{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .footer-terms ul li span:before {
        width: 3px;
        height: 3px;
        border-radius: 50%;
    }
    .footer-terms ul li:first-child{
        flex: 100% !important;
}
    .footer-order1 {
        order: 1;
    }

    .footer-order2 {
        order: 3;
    }

    .footer-order3 {
        order: 4
    }

    .footer-order4 {
        order: 2;
    }
    .footer-terms ul li span {
        padding: 0 5px;
    }
    .bg-footer-copyright {
        padding: 5px 0 15px;
    }
}
@media screen and (max-width: 400px) {
    .footer-terms{
        font-size: 10px;
    }
    .footer-terms ul li span {
        padding: 0 3px;
    }
}
