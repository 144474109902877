.LogIn-Card {
  background: #FFFFFF;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.08);
  width: 85%;
  border-radius: 20px;
  margin: 45px auto;
  overflow: hidden;
}

.LogIn-Card-margin{
  margin-top: 190px;
}

*::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #989CA6 !important ;
  opacity: 1 !important; /* Firefox */
}

*:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #989CA6 !important;
    font-weight: 400 !important;
}

*::-ms-input-placeholder { /* Microsoft Edge */
    color: #989CA6 !important;
    font-weight: 400 !important;
}

.logincard {}

.passwordIconShowInValid {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50px;
  right: 15px;
}

.banner-promotion .carousel-control-prev,
.banner-promotion .carousel-control-next {
  display: none;
}

.loginInput .form-group {
  text-align: left;
}

.loginheaderimage {
  display: flex;
  align-items: center;
  justify-content: end;
}

.loginheaderimage img {
  width: 220px;
}

.loginInput h2 {
  font-family: "DB Helvethaica X 65 Med" !important;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 64px;
  color: #464B55;
}

.loginInput .form-group input[type="text"],
.loginInput .form-group input[type="password"] {
  font-family: "DB Helvethaica X 55 Regular" !important;
  padding: 10px 15px;
  border-radius: 6px;
  background-color: #f5f6f7;
  font-size: 20px !important;
  height: 48px;
  line-height: unset !important;
  box-shadow: none !important;
  border: 2px solid #f5f6f7;
}
::-ms-reveal {
  display: none !important;
}

.loginInput .form-group input[type="text"]:focus,
.loginInput .form-group input[type="password"]:focus{
  border: 2px solid #61A60E;
  background-color: #fff;
}

input.form-check-input:checked::after {
  background-color: #61a60e;
  height: 0;
  width: 0;
}

.loginheaderimage {
  text-align: right;
  margin-bottom: 28px;
}

.loginImage {
  padding-left: 0px;
}

.loginImage img {
  width: 100%;
  border-radius: 16px;
}

.passwordText {
  position: relative;
}

.passwordIconShow {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 42%;
  right: 20px;
  cursor: pointer;
}

.passwordIconHide {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 42%;
  right: 15px;
}

.loginForgot {
  position: relative;
  /* margin-top: 11%; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loginForgot input[type=checkbox] {
  margin: 0;
  margin-top: 10px !important;
  accent-color: #61A60E;
  cursor: pointer;
}
.is-invalid{
  border-color:#EF3145 !important;
  background-color: #fff !important;
}
.loginForgot .form-check-input[type=checkbox]:checked {
  background-color: #61A60E;
}

.forgot-password {
  color: #61a60e;
}

.forgot-password:hover {
  color: #61a60e !important;
}

.ForgotLink {
  color: green;
  float: right;
  /* margin-right: 0px; */
  text-decoration: none;
}

.loginBtn {
  font-family: "DB Helvethaica X 55 Regular" !important;
  width: 100%;
  outline: none;
  background-color: #61a60e !important;
  border: 1px solid #61a60e;
  height: 56px;
  border-radius: 30px;
  text-transform: none;
  font-size: 24px;
  color: #fff;
  margin-top: 30px;
}
.firstTimeLogin{
  display: flex;
  align-items: center;
  justify-content: center;
}
.firstTimeLogin a,
.firstTimeLogin a:hover,
.firstTimeLogin a:active {
  font-family: "DB Helvethaica X 55 Regular" !important;
  text-decoration: none;
  color: #61A60E;
  border-bottom: 1px solid #61A60E;
  padding-bottom: 1px;
  font-size: 24px;
  margin-top: 20px;
  text-align: center;
}

input.form-control.custom-focus {
  border-color: #61a60e;
  box-shadow: 0 0 0 0.1rem #61a60e;
}

input.form-control.custom-focus1 {
  border-color: #61a60e;
  box-shadow: 0 0 0 0.1rem #61a60e;
}

.loggin-text {
  font-family: "DB Helvethaica X 55 Regular" !important;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.loggin-text span {
  font-family: "DB Helvethaica X 55 Regular" !important;
  font-size: 20px;
  flex: 50%;
  text-align: left;
  color: #464b55;
}

.loggin-text a {
  font-size: 20px;
  text-decoration: none;
  flex: 50%;
  text-align: right;
}

.loggin-text input:checked {
  background-color: green !important;
}

.input-label-st {
  font-family: "DB Helvethaica X 55 Regular" !important;
  font-size: 20px;
  font-weight: 400;
  color: #464b55;
  margin: 10px 0 5px;

}

.form-main-container {
  margin-right: 22px;
}

.login-invalid-feedback {
  /* font-family: "DB Helvethaica X 55 Regular";
  font-size: 14px !important;
  font-weight: 400 !important;
  position: absolute !important;
  top: 75px !important; */
}

.login-forgotPW {
  position: relative;
}

.invalid-feedback.login-invalid-feedback {
  font-family: "DB Helvethaica X 55 Regular" !important;
  position: unset !important;
  font-size: 20px !important;
  color: #EF3145 !important;
  font-weight: 400 !important;
  margin-top: -5px !important;
  margin-bottom: 10px !important;
}

.login-InputField {
  position: relative;
}

.LogIn-Card .form-control.is-invalid {
  background-position: right 40px center;
  background-size: 16px;
}

@media screen and (max-width: 991px) {
  .LogIn-Card {
    width: 90%;
    margin: 40px auto;
  }

  .loginheaderimage {
    margin-bottom: 15px;
  }
  .LogIn-Card-margin{
    margin-top: 155px;
  }
}

@media screen and (max-width: 767px) {
  
.loginInput h2 {
  font-size: 40px;
}
  .loginheaderimage {
    display: none;
  }

  .loginImage {
    order: 2;
    -webkit-order: 2;
  }

  .loginInput {
    order: 1;
    -webkit-order: 1;
  }

  .LogIn-Card {
    width: 100%;
    border-radius: 0;
    padding: 10px 7px 25px;
    margin: 0;
  }

  .logincard {
    display: flex;
    flex-wrap: wrap;
  }

  .logincard .banner-wraper {
    padding: 0;
  }

  .firstTimeLogin {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .loginBtn {
    font-size: 20px;
    margin-top: 20px;
    height: 44px;
  }

  .firstTimeLogin a,
  .firstTimeLogin a:hover,
  .firstTimeLogin a:active {
    font-size: 20px;
    margin: 0;
  }

  .form-main-container {
    margin-right: unset;
  }
  .invalid-feedback.login-invalid-feedback{
    margin-bottom: 0;
  }
}

@media screen and (max-width: 576px){
  .LogIn-Card-margin{
    margin-top: 60px;
  }
}