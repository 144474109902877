.loading-overlay {
    display: none;
    background: rgba(255, 255, 255, 0.7);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99999;
    align-items: center;
    justify-content: center;
  }
  
  .loading-overlay {
    display: flex;
  }
  
  .code {
    font-family: monospace;
  /*   font-size: .9em; */
    color: #dd4a68;
    background-color: rgb(238, 238, 238);
    padding: 0 3px;
  }