#SearchBox:active {
    border: none;
}

.SearchBarContainer {
    width: 80% !important;
    margin: 0 auto !important;
    position: relative;
    padding-top: 2%;
}

.CancelSearchScreen {
    display: inline-block;
    position: absolute;
    top: 35px;
    right: 75px;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
}


.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #61A60E !important;
}

.MuiOutlinedInput-root {
    border-radius: 50px;
}

.serchLeftCon {
    display: flex;
    gap: 3px
}

.serchLeftChild {
    height: 72px;
    width: 72px;
    border: 1px solid #D3D6DA;
    border-radius: 6px;
    cursor: pointer !important;
}

.stockLabel {
    padding: 5px;
    border-radius: 10px;
    background: #EFF6E7;
    color: #61A60E;
    font-weight: 500;
    font-size: 1rem;
}

.stockAmount {
    color: #464B55;
    font-size: 1.3rem;
    font-weight: bold;
    margin-left: 12px !important;
}

.mtTop {
    margin-top: 3%;
}

.child2nd {
    flex-basis: 42%;
    color: #989CA6;
}

.productN {
    font-size: 1rem;
}

.productName {
    font-size: 1.2rem !important;
}


.hrLine {
    /* width: 992px; */
    /* width: 992px;
    height: 0px; */

    /* N20 */

    border-bottom: 1px solid #D3D6DA;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.product-lbl {
    font-family: 'DB Helvethaica X';
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #989CA6;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.product-lbl-name {
    font-size: 20px;
    line-height: 24px;
    color: #989CA6;
    ;
    /* padding-top: 0px; */
    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer !important;
}

.product-lbl-name-tax-modal {
    font-size: 16px;
    line-height: 24px;
    color: #989CA6;
    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer !important;
}

.product-lbl-name-tax-modal:hover {
    color: #61A60E;
    cursor: pointer !important;
}

.product-lbl-name:hover {
    color: #61A60E;
    cursor: pointer !important;
}

.badge-color {
    background: #EFF6E7;
    border-radius: 26px;
}

.price-field {
    width: 100px;
    height: 54px;

    /* Desktop/B2 Bold */

    font-family: 'DB Helvethaica X';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 120% */


    /* N60 */

    color: #464B55;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.price-field-heading {
    width: 100%;
    height: 54px;
    font-family: 'DB Helvethaica X';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
}

.stock-field-in {
    background: #EFF6E7;
    color: #61A60E;
    padding: 2px 8px;
    font-size: 16px;
    border-radius: 26px;
}

.stock-field-out {
    background: #F5F6F7;
    color: #989CA6;
    padding: 2px 8px;
    font-size: 16px;
    border-radius: 26px;
}

.promo-btn-addtocart:hover {
    color: #fff;
    /* background-color: #117c11; */
    border-color: var(--bs-btn-hover-border-color);
}

/* .promo-btn-wishlist:hover {
    color: #fff;
    background-color: #117c11;
    border-color: var(--bs-btn-hover-border-color);
} */
.product-card-image-badge-search-bar {
    position: absolute;
    top: 32px;
    left: 5px;
}

.search-bar-autocomplete .css-1omv4fy-MuiAutocomplete-root {
    width: 100% !important;
    height: 36px !important;
}

.search-bar-autocomplete .css-18hxdr3 {
    width: 100% !important;
    height: 36px !important;
}

.search-bar-autocomplete .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: #F5F6F7 !important;
    border: none !important
}

.search-bar-autocomplete .css-segi59 {
    background-color: #F5F6F7 !important;
    border: none !important
}

.search-bar-autocomplete .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    outline: none !important;
    border: none !important;
}

.search-bar-autocomplete .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 16px !important;
}

.search-bar-autocomplete .css-1uvydh2 {
    font-size: 16px !important;
}

.search-bar-autocomplete .css-1omv4fy-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 6px 12px 6px 12px !important
}

.search-bar-autocomplete .css-18hxdr3 .MuiOutlinedInput-root {
    padding: 6px 12px 6px 12px !important
}

.search-bar-add-minus {
    outline: none;
    border: none;
    color: #fff;
    background-color: #61a60e;
    text-align: center;
    font-size: 13px;
    padding: 7px 0px;
    border-radius: 20px;
    width: 100%;
    margin: auto;
}

.add-to-chart-count-search-bar {
    font-size: 20px;
    font-weight: 500;
    margin-top: 0px;
    position: absolute !important;
    top: 0px !important;
    left: 40px !important;
}

.css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 10px 16px !important;
}

.css-x0qxq3 {
    padding: 10px 16px !important;
}

.css-1gnht4k {
    padding: 16.5px 14px !important;
}

.css-152mnda-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 16.5px 14px !important;
}

.search-bar-product-image {
    height: 68px;
    width: 68px;
}

/* search-bar-mobile-section start from here */
.search-bar-mobile-main-container .search-bar-mobile-inner-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 1400;
    position: fixed;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    opacity: 1 !important;
    background-color: rgba(24, 27, 33, 0.4) !important;
    height: 100%;
    overflow: auto;
}

.search-bar-mobile-main-container .search-bar-mobile-custom-container {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 12px 12px;
    padding: 20px;
    position: absolute;
    top: 0px;
    overflow-y: auto;
}

.search-bar-desktop-main-container .search-bar-desktop-inner-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 999;
    position: fixed;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    opacity: 0.5;
    opacity: 1 !important;
    background-color: rgba(24, 27, 33, 0.4) !important;
    height: 100%;
    overflow: auto;
}

.search-bar-desktop-main-container .search-bar-desktop-custom-container {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 16px 16px;
    padding: 22px 120px 24px 224px;
    position: absolute;
    top: 0px;
    overflow-y: auto;
}

.search-bar-mobile-inner {
    position: relative;
}

.search-bar-mobile-search-input {
    padding: 8px 42px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 40px;
    outline: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    border: none;
    position: relative;
    border: 2px solid #61A60E;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
}

.search-bar-desktop-search-input {
    padding: 8px 42px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 40px;
    outline: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    border: none;
    position: relative;
    border: 2px solid #61A60E;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    margin-bottom: 24px;
}

.search-bar-mobile-main-container .search-bar-mobile-search-input-image {
    width: 14px;
    position: absolute;
    left: 16px;
    top: 14px;
    height: 14px;
}

.search-bar-desktop-main-container .search-bar-desktop-search-input-image {
    width: 16px;
    position: absolute;
    left: 16px;
    top: 14px;
    height: 16px;
}

.search-bar-mobile-main-container .search-bar-mobile-search-cross-image {
    position: absolute;
    height: 14px;
    width: 14px;
    right: 16px;
    top: 14px;
}

.search-bar-desktop-main-container .search-bar-desktop-search-cross-image {
    position: absolute;
    height: 14px;
    width: 14px;
    right: 16px;
    top: 14px;
    cursor: pointer;
}

.search-bar-mobile-main-container .search-bar-mobile-search-cancel-heading {
    font-weight: 600;
    font-size: 16px;
    color: #464B55;
    top: 14px;
    position: absolute;
    right: 14px;
    font-size: 600;
}

.search-bar-desktop-main-container .search-bar-desktop-search-cancel-heading {
    font-weight: 600;
    font-size: 24px;
    color: #464B55;
    top: 8px;
    position: absolute;
    right: 14px;
    cursor: pointer;
}

.search-bar-mobile-image-border {
    border: 1px solid #D3D6DA;
    border-radius: 8px;
    width: 72px;
    height: 72px;
    position: relative;
}

.search-bar-mobile-image-border .search-bar-mobile-image-border-image {
    padding: 4px;
    object-fit: contain;
    height: 68px;
    width: 68px;
}

.search-bar-right-product-name {
    font-weight: 500;
    font-size: 18px;
    color: #989CA6;
    min-height: 42px;
}

.search-bar-right-product-price {
    font-weight: 700;
    font-size: 20px;
    color: #464B55;
    text-align: right;
}

.search-bar-mobile-wishlist-border {
    background: #F5F6F7;
    border-radius: 32px;
    height: 28px;
    width: 28px;
    padding: 8px;
}
.search-bar-mobile-wishlist-border-active {
    background: #EF3145;
    border-radius: 32px;
    height: 28px;
    width: 28px;
    padding: 8px;
}

.search-bar-mobile-heart-gray {
    width: 12px;
    height: 12px;
}

.search-bar-mobile-select-main-container {
    padding: 4px 14px 4px 8px;
    background: #F5F6F7;
    border-radius: 32px;
    width: 100%;
    height: 28px;
    position: relative;
}

.search-bar-mobile-select-main-container .search-bar-mobile-select-heading {
    font-weight: 400;
    font-size: 16px;
    color: #464B55;
    width: max-content;
}

.search-bar-mobile-select-main-container .search-bar-mobile-select-image {
    height: 7px;
    width: 8px;
    position: absolute;
    right: 8px;
    bottom: 10px;
}

.search-bar-mobile-add-to-cart-button-main-container {
    background: #61A60E;
    border-radius: 32px;
    width: 100%;
    height: 28px;
    text-align: center;
    padding: 4px 6px;
    justify-content: center;
}

.search-bar-mobile-add-to-cart-button-main-container .search-bar-mobile-add-to-cart-image {
    width: 12px;
    height: 12px;
    margin-top: 4px;
}

.search-bar-mobile-add-to-cart-button-main-container .search-bar-mobile-add-to-cart-heading {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    margin-left: 6px;
    margin-top: 1px;
}

.search-bar-gray-line {
    border: 1px solid #D3D6DA;
    width: 100%;
    margin-top: 16px;
}

.search-bar-mobile-select-single-heading {
    font-weight: 400;
    font-size: 16px;
    color: #464B55;
    margin-top: 6px;
}

.search-bar-mobile-heading {
    font-weight: 500;
    font-size: 16px;
    color: #fff;
}

.search-bar-mobile-article-code {
    font-weight: 400;
    font-size: 12px;
    color: #989CA6;
    line-height: 2px;
    margin-top: 6px;
}

/* search-bar-mobile-section end */
/* search-bar-desktop-section start from here */
.search-bar-desktop-product-main-container {
    align-items: center;
}

.search-bar-desktop-product-inner-container {
    align-items: center;
}

.search-bar-desktop-image-border {
    width: 72px;
    height: 72px;
    border: 1px solid #D3D6DA;
    border-radius: 8px;
    position: relative;
}

.search-bar-desktop-image-border-image {
    padding: 2px;
    object-fit: contain;
    height: 70px;
    width: 70px;
}

.search-bar-desktop-in-stock-main-container {
    width: 100%;
    background: #EFF6E7;
    border-radius: 26px;
    width: 100%;
    height: 24px;
    padding: 2px 0px;
}

.search-bar-desktop-in-stock-heading {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    color: #61A60E;
}

.search-bar-desktop-out-of-stock-main-container {
    background: #F5F6F7;
    border-radius: 26px;
    width: 100%;
    height: 24px;
    padding: 2px 0px;
}

.search-bar-desktop-out-of-stock-heading {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    color: #989CA6;
}

.search-bar-desktop-article-code {
    font-weight: 400;
    font-size: 16px;
    color: #989CA6;
}

.search-bar-desktop-product-name {
    font-weight: 500;
    font-size: 20px;
    color: #989CA6;
    cursor: pointer;
    line-height: 2px;
}

.search-bar-desktop-product-name:hover {
    color: #61A60E!important;
}
.search-bar-desktop-product-name:hover mark{
    color: currentColor!important;
}

.search-bar-desktop-article-code-main-container {
    margin-left: 12px;
}
.search-bar-highlighted-heading:hover {
    color: #61A60E!important;
}

.search-bar-desktop-price-heading {
    font-weight: 700;
    font-size: 20px;
    color: #464B55;
    text-align: center;
}

.search-bar-desktop-select-main-container {
    background: #F5F6F7;
    border-radius: 32px;
    height: 36px;
    padding: 6px 12px 6px 16px;
    width: 160px;
    cursor: pointer;
    position: relative;
}

.search-bar-desktop-select-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
}

.search-bar-desktop-select-main-container .search-bar-desktop-select-image {
    height: 9px;
    width: 14px;
    position: absolute;
    right: 10px;
    bottom: 12px;
}

.search-bar-desktop-wishlist-border {
    background: #F5F6F7;
    border-radius: 32px;
    padding: 4px;
    width: 46px;
    height: 36px;
    text-align: center;
    cursor: pointer;
}
.search-bar-desktop-wishlist-border-active {
    background: #EF3145;
    border-radius: 32px;
    padding: 4px;
    width: 46px;
    height: 36px;
    text-align: center;
    cursor: pointer;
}

.search-bar-desktop-heart-gray {
    width: 16px;
    height: 16px;
    margin-top: 6px;
}

.search-bar-desktop-add-to-cart-button {
    background: #61A60E;
    border-radius: 32px;
    padding: 6px 8px;
    outline: none;
    border: none;
    width: 100%;
    height: 36px;
    margin-left: 8px;
    text-align: center;
    cursor: pointer;
}

.search-bar-desktop-add-to-cart-heading {
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    margin-left: 6px;
}

.search-bar-desktop-add-to-cart-main-container {
    height: 16px;
    width: 16px;
    text-align: left;
    margin-left: 14px;
    margin-bottom: 6px;
}

.search-bar-desktop-gray-line {
    width: 98% !important;
    border: 1px solid #D3D6DA;
    margin-top: 12px !important;
    margin-left: 16px;
    margin-bottom: 12px !important;
}

.search-bar-view-all-search-result-main-container {
    margin-top: 20px;
}

.search-bar-view-all-search-result-heading {
    font-weight: 400;
    font-size: 20px;
    color: #61A60E;
    cursor: pointer;
}

.search-bar-desktop-add-to-cart-button-main-container {
    background: #61A60E;
    border-radius: 40px;
    width: 100%;
    height: 36px;
    padding: 4px;
    margin-left: 8px;
}

.search-bar-desktop-add-to-cart-button-main-container .search-bar-desktop-add-to-cart-image {
    width: 12px;
    height: 12px;
    margin-top: 4px;
}

.search-bar-desktop-add-to-cart-button-main-container .search-bar--add-to-cart-heading {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    margin-left: 6px;
    margin-top: 2px;
}

.search-bar-desktop-add-button {
    height: 28px;
    width: 28px;
    cursor: pointer;
}

.search-bar-desktop-minus-button {
    height: 28px;
    width: 28px;
    cursor: pointer;
}
.search-bar-desktop-heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 3px;
}
.search-bar-desktop-dropdown-main-container {
    padding: 12px 0px;
    position: absolute;
    width: 160px;
    height: auto;
    right: -5px;
    top: 42px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    z-index: 99999;
}
.search-bar-desktop-dropdown-main-container .my-order-all-status-filter-desktop-dropdown-active-heading {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 4px;
    color: #61A60E;
}
.search-bar-desktop-dropdown-main-container .my-order-all-status-filter-desktop-dropdown-active-disable {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 4px;
    color: #989CA6;
}
.search-bar-relative-position {
    position: relative;
}
.search-bar-desktop-dropdown-main-container .my-order-all-status-filter-desktop-dropdown-disable-heading {
    font-weight: 500;
    font-size: 20px;
    color: #989CA6;
}
.search-bar-desktop-dropdown-main-container .dropdown-item:hover {
    background: #F5F6F7;
    color: #989CA6;
}
.search-bar-no-result-found-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
}
.product-lbl-name {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
}
.search-bar-view-all-search-result-main-container a:hover {
    color:#61A60E!important;
    text-decoration: none!important;
}
.search-bar-promo-banner {
    position: absolute;
    top: 4px;
    left: 5px;
    width: 40px;
    height: 24px;
}
.search-bar-mobile-promo-banner {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 40px;
    height: 24px;
}
.search-bar-highlishted-text{
    background: none; 
    color: #000; 
    padding: 0px;  
}
.search-bar-highlishted-text:hover {
    color: #61A60E;
}
/* search-bar-desktop-section end */
/* Search-bar-modal-scroll css start from here */
.search-bar-desktop-inner-container::-webkit-scrollbar {
    width: 10px;
    scrollbar-width: auto;
    scrollbar-color: #D3D6DA #ffffff;
}

.search-bar-desktop-inner-container::-webkit-scrollbar-track {
    background: #ffffff;
}

.search-bar-desktop-inner-container::-webkit-scrollbar-thumb {
    background-color: #D3D6DA;
    border-radius: 20px;
    border: 3px solid #ffffff;
}
/* Search-bar-modal-scroll css end */