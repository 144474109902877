.desktopbrandButtonChild,
.desktopshopByBrandChild {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--white);
}

.desktopshopByBrandChild {
    border-radius: var(--br-2xl);
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
}

.desktopbrandButtonChild {
    border-radius: var(--br-3xl) 0 0 var(--br-3xl);
}

.betagroLogoIcon {
    position: absolute;
    height: 66.67%;
    width: 38.5%;
    top: 16.67%;
    right: 30.75%;
    bottom: 16.67%;
    left: 30.75%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.desktopbrandButton,
.desktopbrandButtonItem {
    position: absolute;
    height: 100%;
    width: 33.33%;
    top: 0;
    right: 66.67%;
    bottom: 0;
    left: 0;
}

.desktopbrandButtonItem {
    width: 100%;
    right: 0;
    background-color: var(--white);
}

.sPureLogo {
    position: absolute;
    height: 38.33%;
    width: 47%;
    top: 30.83%;
    right: 26.5%;
    bottom: 30.83%;
    left: 26.5%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.desktopbrandButton1,
.desktopbrandButtonInner {
    position: absolute;
    height: 100%;
    width: 33.33%;
    top: 0;
    right: 33.33%;
    bottom: 0;
    left: 33.33%;
}

.desktopbrandButtonInner {
    width: 100%;
    right: 0;
    left: 0;
    border-radius: 0 var(--br-3xl) var(--br-3xl) 0;
    background-color: var(--white);
}

.itohamLogoIcon {
    position: absolute;
    height: 40%;
    width: 40.5%;
    top: 30%;
    right: 29.75%;
    bottom: 30%;
    left: 29.75%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.desktopbrandButton2 {
    position: absolute;
    height: 100%;
    width: 33.33%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 66.67%;
}

.betagroLogoIcon1 {
    position: absolute;
    height: 65.71%;
    width: 43.5%;
    top: 17.14%;
    right: 28.25%;
    bottom: 17.14%;
    left: 28.25%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.desktopbrandButtonbetagrod {
    position: absolute;
    height: 96.44%;
    width: 22.8%;
    top: 0;
    right: 49.64%;
    bottom: 3.56%;
    left: 27.56%;
    display: none;
}

.desktopbrandButtonitohamdeChild {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-2xl) 0 0 var(--br-2xl);
    background-color: var(--white);
}

.itohamLogoIcon1 {
    position: absolute;
    height: 40%;
    width: 48.25%;
    top: 30%;
    right: 25.75%;
    bottom: 30%;
    left: 26%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.desktopbrandButtonitohamde {
    position: absolute;
    height: 96.44%;
    width: 22.8%;
    top: 0;
    right: 77.2%;
    bottom: 3.56%;
    left: 0;
    display: none;
}

.desktopshopByBrandInner,
.desktopshopByBrandItem {
    position: absolute;
    height: 60%;
    width: 0.07%;
    top: 20%;
    right: 66.63%;
    bottom: 20%;
    left: 33.3%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.desktopshopByBrandInner {
    right: 33.3%;
    left: 66.63%;
}

.desktopshopByBrand {
    position: absolute;
    top: 0;
    left: 0;
    width: 1100px;
    height: 120px;
}

.desktopshopByBrandParent {
    position: relative;
    width: 100%;
    height: 120px;
}