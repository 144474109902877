@import "../index.css";

* {
    font-family: 'DB Helvethaica X 55 Regular' !important;
    box-sizing: border-box !important;
}
.head-text {
    position: relative;
 }
.text-on-image {
    position: absolute;
    bottom: 0%;
    font-weight: 500;
    font-size: 17px;
    background-color: rgb(255 255 255 / 75%);
  }
.all-product-section {
    position: relative;
}

.all-product-inner-section {
    margin-top: 64px;
}

.filter-text {
    font-weight: 500;
    font-size: 22px;
    color: #464B55;
}

.reset-text {
    text-align: right;
    font-size: 18px;
    color: #989CA6;
    font-weight: 500;
}

.all-product-heading {
    font-weight: 500;
    font-size: 56px;
    color: #464B55;
}

.reset-filter-text {
    font-weight: 500;
    font-size: 20px;
    color: #989CA6;
    margin-top: 14px;
    text-align: right;
}

.gray-line {
    border: 1px solid #D3D6DA;
    width: 84% !important;
    margin-left: 27px;
}

.gray-line-inner {
    border: 1px solid #D3D6DA;
    width: 94% !important;
    margin-left: 10px;
}

.product-count-text {
    font-size: 20px;
    font-weight: 400;
}

.sort-by-text {
    font-size: 22px;
    font-weight: 500;
    margin-left: 6px;
    margin-top: 6px !important;
}

.sort-by-container {
    text-align: end;
}

.all-prouduct-found-card-container {
    margin-top: 34px;
}


.all-product-card-image-container {
    height: 150px;
    width: 100%;
}

.all-product-card-image-container img {
    text-align: center;
    width: 100%;
    height: auto;
}

.all-product-card-inner {
    text-align: center;
}

.product-card-image-badge {
    position: absolute;
    top: 15px;
    left: 20px;
}

.card-product-quantity {
    font-weight: 400;
    font-size: 16px;
    align-items: right;
    color: #989CA6;
}

.card-product-dropdown select {
    padding: 6px 12px 6px 16px;
    gap: 6px;

    width: 200px;
    height: 36px;
    background: #F5F6F7;
    border-radius: 32px;
    font-size: 14px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url(../../public/DownArrow.svg);
    border: none;
    outline: none;
    margin-top: 8px;
}

.card-add-to-card-whishlist {
    border: none;
    font-size: 13px;
    padding: 6px;
    outline: none;
    text-align: center;
    width: 36px;
    height: 36px;
    background: #F5F6F7;
    border-radius: 32px;
    margin-left: 8px;
}

.added-to-card-button {
    background-color: #61a60e;
    border: none;
    border-radius: 32px;
    color: #fff;
    font-size: 20px;
    outline: none;
    padding: 3px 8px;
    text-align: center;
    width: 100%;
    /* margin-left: 18px; */
}

.main-container-filter-accordion .accordion {
    width: 100% !important;
}

.main-container-filter-accordion .accordion-item {
    border: none !important;
}

.filter-count-container {
    padding-left: 26px !important;
}

.main-container-filter-accordion .accordion-button:not(.collapsed) {
    box-shadow: none !important;
}

.accordion-top-header {
    position: relative;
}

.main-container-filter-accordion .accordion-button {
    color: #464B55 !important;
}

.main-container-filter-accordion .accordion-body .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #61A60E !important;
}

.main-container-filter-accordion .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root:hover {
    background-color: white !important;
}

.main-container-filter-accordion .accordion-body {
    padding: 0px !important;
}

.inner-checkbox-container {
    margin-left: 5px;
}

.sort-by-price-text {
    color: #61A60E;
    font-weight: 500;
    margin-left: 6px;
    margin-top: 6px !important;
    font-size: 22px;
}

.sort-by-dropdown .dropdown-menu {
    position: absolute !important;
    width: 200px !important;
    height: auto !important;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05) !important;
    border-radius: 12px !important;
    top: 27px !important;
    left: 17px !important;
    z-index: 99999 !important;
}

.sort-by-container .dropdown-item {
    padding: 10px !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #989CA6 !important;
}

.out-of-stock-button {
    background-color: #F5F6F7;
    border: none;
    border-radius: 32px;
    color: #989CA6;
    font-size: 16px;
    outline: none;
    padding: 6px 8px;
    text-align: center;
    width: 100%;
    margin-left: 18px;
}
.nested-accordion-main-container .accordion {
    width: 100%!important;
}
.nested-accordion-main-container .nested-inner-container{
    margin-left: 12px;
}
.nested-accordion-main-container .accordion-header .accordion-button{
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 6px;
}
.pagination-main-container{
    margin-top: 45px!important;
    margin-bottom: 45px!important;
    text-align: right!important;
}
.pagination-main-container .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #61A60E!important;
    font-size: 18px!important;
    font-weight: 500!important;
    color: white!important;
}
.pagination-main-container .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
    color: 'white'!important;
    font-size: 18px!important;
}
.main-container-filter-accordion .nested-accordion-main-container .accordion {
    width: 100%!important;
}
.nested-accordion-main-container .nested-inner-container{
    margin-left: 12px!important;
}
.nested-accordion-main-container .accordion-header .accordion-button{
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    padding-left: 6px!important;
}
.stricky-top{
    position: sticky;
    top: 5px;
    margin-bottom: 20px;
}
.main-container-scroller-design{   
    border-radius: 4px;
    color: #3B3C3E;
    font-size: 12px;
    font-weight: bold;
    left: -1px;
    padding: 10px 7px 5px;
}

.inner-container-scroller-design{
    height: 500px;
    overflow: auto;
    overflow-x:hidden;
}
/* ===== Scrollbar CSS ===== */
  /* Firefox */
.inner-container-scroller-design {
    scrollbar-width: auto;
    scrollbar-color: #D3D6DA #ffffff;
}

  /* Chrome, Edge, and Safari */
.inner-container-scroller-design::-webkit-scrollbar {
    width: 10px;
    scrollbar-width: auto;
    scrollbar-color: #D3D6DA #ffffff;
}

.inner-container-scroller-design::-webkit-scrollbar-track {
    background: #ffffff;
}

.inner-container-scroller-design::-webkit-scrollbar-thumb {
    background-color: #D3D6DA;
    border-radius: 20px;
    border: 3px solid #ffffff;
}

.out-of-stock-containers {
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    width: 100%;
    height: 24px;
    background: #F5F6F7;
    border-radius: 26px;
    margin-bottom: 8px;
}

.out-of-stock-headings {
    font-weight: 500;
    font-size: 18px;
    align-items: center;
    color: #989CA6;
    margin-bottom: 8px;
}

.active-heading-accodion span {
    position: absolute;
    bottom: 3px;
}

.active-heading-accodion {
    font-size: 18px;
    font-weight: 500;
    margin-top: 4px;
    color: #61A60E;
    text-transform: capitalize;
}

.promo-img-badge1 {
    position: absolute;
    top: 5px;
    left: 0;
  }
  
  .promo-img-badge1 img {
    width: 50px;
  }
.promo-desc .css-1omv4fy-MuiAutocomplete-root {
    width: 100%!important;
    height: 36px!important;
}
.promo-desc .css-18hxdr3 {
    width: 100%!important;
    height: 36px!important;
}
.promo-desc .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: #F5F6F7!important;
    border: none!important
}
.promo-desc .css-segi59 {
    background-color: #F5F6F7!important;
    border: none!important
}
.promo-desc .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    outline: none!important;
    border: none!important;
}
.promo-desc .css-igs3ac {
    outline: none!important;
    border: none!important;
}
.promo-desc .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 16px!important;
}
.promo-desc .css-1uvydh2 {
    font-size: 16px!important;
}
.promo-desc .css-1omv4fy-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 6px 12px 6px 12px!important
}
.promo-desc .css-18hxdr3 .MuiOutlinedInput-root {
    padding: 6px 12px 6px 12px!important
}
.no-found-result-text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    margin-top: 8px;
}
.my-cart-is-empty-search-not-round {
    height: 104px!important;
    width: 104px!important;
}
.nested-accordion-main-container .accordion-button:not(.collapsed) {
    color:#3B3C3E;
}