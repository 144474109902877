/* Common classes */
.mt-12 {
    margin-top: 12px;
}
.mt-32 {
    margin-top: 32px!important;
}
.mt-24 {
    margin-top: 24px !important;
}
.my-account-hamburger-main-container {
    display: block;
}

.my-order-main-right-container {
    background-color: #F5F5F5;
}

.my-order-main-container .my-order-right-main-container {
    padding: 48px 20px 40px 20px;
}

.my-order-main-container .my-order-right-main-container .my-orders-right-main-heading {
    font-weight: 500;
    font-size: 48px;
    color: #464B55;
}

/* tabs-section start from here */
.my-order-tab-menu .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    color: #61A60E !important;
    text-transform: capitalize;
    padding: 0px 20px 0px 0px !important;
    align-items: baseline!important;
}

.my-order-tab-menu .css-1q2h7u5.Mui-selected {
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    color: #61A60E !important;
    text-transform: capitalize;
    padding: 0px 20px 0px 0px !important;
    align-items: baseline!important;
}

.my-order-tab-menu .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    text-transform: capitalize;
    padding: 0px 20px 0px 0px !important;
    align-items: baseline!important;
}

.my-order-tab-menu .css-1q2h7u5 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    text-transform: capitalize;
    padding: 0px 20px 0px 0px !important;
    align-items: baseline!important;
}

.my-order-tab-menu .css-1aquho2-MuiTabs-indicator {
    background-color: #61A60E;
    height: 4px;
    background-clip: content-box;
    padding-right: 20px;
}

.my-order-tab-menu .css-ttwr4n {
    background-color: #61A60E;
    height: 4px;
    background-clip: content-box;
    padding-right: 20px;
}

.my-order-tab-menu .css-19kzrtu {
    padding: 0px;
}

.my-order-tab-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my-order-tab-menu ul {
    list-style-type: none;
    display: flex;
    padding: 0px;
}

.my-order-tab-menu li a {
    text-decoration: none;
    color: #464B55;
    font-size: 20px;
    padding-right: 0px;
    line-height: 60px;
    font-weight: 400;
}

.my-order-tab-menu .tabs-active-count {
    padding: 0px 6px;
    background: #EFF6E7;
    border-radius: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #61A60E;
    margin-left: 2px;
}

.my-order-tab-search-bar-main-container {
    position: relative;
    margin-top: 32px;
}

.my-order-tab-search-bar-input {
    padding: 10px 16px 10px 48px;
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    border-radius: 40px;
    outline: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #989CA6;
    border: none;
    position: relative;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
}

.my-order-tab-search-bar-input::placeholder {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #989CA6;
}

.my-order-tab-accordion-custom-container {
    margin-top: 16px !important;
    display: block;
}

.my-order-tab-accordion-cards {
    margin-top: 0px;
    margin-bottom: 0px;
}
.my-order-search-order-number-main-container .my-order-search-order-number-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    margin-left: 11px;
}

.my-order-search-order-number-main-container .my-order-search-order-date-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #989CA6;
    margin-left: 11px;
}

.my-order-search-order-date-right-heading {
    margin-left: 8px;
}

.my-order-search-order-number-main-container .my-order-search-order-tag-image {
    height: 16px;
    width: 16px;
}

.my-order-search-order-number-main-container .my-order-search-calendar-image {
    height: 18px;
    width: 16px;
    margin-bottom: 8px;
}

.my-order-search-order-number-main-container .my-order-order-status-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    width: auto;
    height: 24px;
    background: #FEF7EB;
    border-radius: 40px;
    margin-left: 16px;
}

.my-order-search-order-number-main-container .my-order-order-status-confirmed-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    width: auto;
    height: 24px;
    background: #E9F8FB;
    border-radius: 40px;
    margin-left: 16px;
}

.my-order-search-order-number-main-container .my-order-order-heading {
    font-weight: 500;
    font-size: 16px;
    color: #F5B335;
    padding-left: 6px;
    padding-top: 6px !important;
}

.my-order-search-order-number-main-container .my-order-confirmed-order-heading {
    font-weight: 500;
    font-size: 16px;
    color: #23B5D3;
    padding-left: 6px;
    padding-top: 6px !important;
}

.my-order-search-order-number-main-container .my-order-order-confirmed-status-image {
    height: 14px;
    width: 14px;
}

.my-order-search-order-number-main-container .my-order-order-status-image {
    height: 11px;
    width: 11px;
}

.my-order-view-details-main-container .my-order-view-details-button {
    background: #fff;
    border: 1px solid #61a60e;
    border-radius: 32px;
    box-shadow: 0 4px 80px rgba(0, 0, 0, .04);
    float: right;
    outline: none;
    padding: 10px 24px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #61A60E;
}
.my-order-view-details-main-container .my-order-view-details-button-th {
    background: #fff;
    border: 1px solid #61a60e;
    border-radius: 32px;
    box-shadow: 0 4px 80px rgba(0, 0, 0, .04);
    float: right;
    outline: none;
    padding: 10px 16px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #61A60E;
}

.my-order-tab-accordion-cards .accordion-item {
    background-color: #fff;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
}

.my-order-tab-accordion-cards .accordion-button:not(.collapsed) {
    box-shadow: none !important;
}

.my-order-tab-accordion-cards .accordion-button {
    padding: 20px 30px 20px 0px;
}

.my-order-search-order-tag-margin {
    margin-top: 6px;
}

.my-order-accordion-custom-main-container .my-order-accordion-custom-image-border {
    border: 1px solid #d3d6da;
    border-radius: 8px;
    cursor: pointer !important;
    height: 76px;
    position: relative;
    width: 76px;
}

.my-order-accordion-custom-main-container {
    margin-top: 16px;
    margin-bottom: 16px;
}

.my-order-accordion-custom-image-border .my-order-accordion-custom-image {
    height: 72px;
    object-fit: contain;
    width: 72px;
    padding: 2px;
}

.my-order-accordion-custom-main-container .my-order-accordion-custom-center {
    align-items: center;
}

.my-order-accordion-custom-main-container .my-order-accordion-custom-product-heading {
    font-weight: 600;
    font-size: 20px;
    color: #464B55;
    margin-left: 12px;
}


.my-order-accordion-custom-main-container .my-order-accordion-custom-product-heading:hover {
    color: #61A60E;
    cursor: pointer;
}

.my-order-gray-line {
    width: 100%;
    border: 1px solid #D3D6DA;
}

.my-order-details-product-info-gray-line {
    width: 100%;
    border: 1px solid #D3D6DA;
    margin-top: 12px;
}

.my-order-details-product-info-inner-gray-line:last-of-type {
    width: 100%;
    border: 1px solid #D3D6DA;
    display: none;
}
.my-order-details-product-info-inner-gray-line {
    width: 100%;
    border: 1px solid #D3D6DA;
}
.my-order-details-payment-section-inner-gray-line {
    width: 100%;
    border: 1px solid #D3D6DA;
}

.my-order-accordion-custom-main-container .my-order-customer-price-main-container {
    text-align: end;
}

.my-order-customer-price-heading {
    font-weight: 700;
    font-size: 20px;
    text-align: end;
    color: #464B55;
    margin-top: 28px;
}

.my-order-customer-price-inner-heading {
    font-weight: 500;
    font-size: 20px;
    color: #989CA6;
    text-align: end;
}

.my-order-customer-total-price-main-container .my-order-customer-total-main-container {
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    margin-right: 8px;
    margin-top: 4px;
}

.my-order-customer-total-price-main-container .my-order-customer-total-price-heading {
    font-weight: 700;
    font-size: 24px;
    color: #61A60E;
}

.my-order-customer-total-price-main-container {
    float: right;
    margin-top: 16px;
    margin-bottom: 16px;
}

.my-order-tab-accordion-cards .accordion-item:last-of-type {
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
}

.my-order-tab-accordion-cards .accordion-item:first-of-type {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}

.my-order-tab-accordion-cards .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(14rem - 9px);
    border-bottom-left-radius: calc(14rem - 9px);
}

.my-order-tab-accordion-cards .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(14rem - 9px);
    border-top-right-radius: calc(14rem - 9px);
}

/* Completed-status */
.my-order-search-order-number-main-container .my-order-order-completed-status-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    width: auto;
    height: 24px;
    background: #EFF6E7;
    border-radius: 40px;
    margin-left: 16px;
}

.my-order-search-order-number-main-container .my-order-completed-status-heading {
    font-weight: 500;
    font-size: 16px;
    color: #61A60E;
    padding-left: 6px;
    padding-top: 6px !important;
}

.my-order-search-order-number-main-container .my-order-completed-status-image {
    height: 14px;
    width: 14px;
}

/* cancelled status */
.my-order-search-order-number-main-container .my-order-order-cancelled-status-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    width: auto;
    height: 24px;
    background: #F5F6F7;
    border-radius: 40px;
    margin-left: 16px;
}

.my-order-search-order-number-main-container .my-order-cancelled-status-heading {
    font-weight: 500;
    font-size: 16px;
    color: #989CA6;
    padding-left: 6px;
    padding-top: 6px !important;
}

.my-order-search-order-number-main-container .my-order-cancelled-status-image {
    height: 14px;
    width: 14px;
}

/* shipped status */
.my-order-search-order-number-main-container .my-order-shipped-status-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    width: auto;
    height: 24px;
    background: #F3F2EB;
    border-radius: 40px;
    margin-left: 16px;
}

.my-order-search-order-number-main-container .my-order-shipped-status-heading {
    font-weight: 500;
    font-size: 16px;
    color: #8A803C;
    padding-left: 6px;
    padding-top: 6px !important;
}

.my-order-search-order-number-main-container .my-order-shipped-status-image {
    height: 14px;
    width: 14px;
}

.to-review-button-container {
    justify-content: space-around;
}

/* Reorder-button */
.my-order-view-details-main-container .my-order-view-details-reorder-button {
    background: #fff;
    border: 1px solid #61a60e;
    border-radius: 32px;
    box-shadow: 0 4px 80px rgba(0, 0, 0, .04);
    float: right;
    outline: none;
    padding: 10px 24px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    background: #61A60E;
}

/* my account left menu */

.my-account-hamburger-main-container {
    margin-top: 48px;
    padding: 0px 10px 0px 60px;
}

.my-account-hamburger-main-container .my-account-hamburger-main-heading {
    font-weight: 500;
    font-size: 32px;
    line-height: 34px;
    color: #464B55;
    padding-left: 12px;
}

.my-account-hamburger-main-container .my-account-hamburger-menu-section-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    margin-left: 15px;
    cursor: pointer;
}
.my-account-hamburger-main-container .my-account-hamburger-menu-section-heading-dashboard {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    margin-left: 15px;
    cursor: not-allowed;
}

.my-account-hamburger-main-container .my-account-hamburger-menu-section-image {
    height: 16px;
    width: 16px;
    margin-top: 4px;
    margin-left: 12px;
}
.my-account-hamburger-main-container .my-account-hamburger-menu-section-image-dashboard {
    height: 16px;
    width: 16px;
    margin-top: 4px;
    margin-left: 12px;
    cursor: not-allowed;
}

.my-account-hamburger-main-container .my-account-hamburger-menu-section {
    margin-top: 24px;
}

.my-account-hamburger-main-container .my-account-hamburger-logout-menu-section {
    margin-top: 96px;
}

.my-account-hamburger-main-container .my-account-hamburger-hover {
    width: 4px;
    height: 24px;
    background: #61A60E;
    border-radius: 20px;
}

.my-account-hamburger-main-container .my-account-hamburger-menu-section-heading-active {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #61A60E;
    margin-left: 15px;
    cursor: pointer;
}


 .my-account-hamburger-menu-counts {
    width: 24px;
    height: 30px;
    background: #EFF6E7;
    border-radius: 40px;
    font-weight: 600;
    font-size: 25px;
    line-height: 20px;
    text-align: center;
    color: #61A60E;
    margin-left: 6px;
    margin-top: 4px;
    text-align: center;
}
.my-account-hamburger-menu-section .my-account-hamburger-menu-counts {
    width: 19px;
    height: 20px;
    background: #EFF6E7;
    border-radius: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #61A60E;
    margin-left: 6px;
    margin-top: 4px;
    text-align: center;
}

/* my-order-details-section start from here */
.my-order-details-main-container {
    background-color: #F5F5F5;
}

.my-order-details-main-container .my-order-details-inner-container {
    padding: 48px 90px 40px 90px;
}

.my-order-details-main-container .my-order-details-main-container-breadcrumb-heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #989CA6;
    cursor: pointer;
}
.my-order-details-main-container .my-order-details-main-container-breadcrumb-heading:hover{
    border-bottom: 1.5px solid #989CA6;
}

.my-order-details-main-container .my-order-details-main-container-breadcrumb-image {
    height: 16px;
    width: 10px;
    margin-right: 11px;
    margin-left: 11px;
    margin-top: 5px;
}

.my-order-details-main-container .my-order-details-main-container-breadcrumb {
    display: inline-block;
}

.my-order-details-inner-container .my-order-details-main-container-main-heading {
    font-weight: 500;
    font-size: 48px;
    line-height: 50px;
    color: #464B55;
    margin-top: 16px;
}

.my-order-details-inner-container .my-order-details-status-image {
    height: 16px;
    width: 16px;
    margin-top: 6px;
}

.my-order-details-inner-container .my-order-details-status-order-number {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #464B55;
    margin-left: 11px;
    margin-right: 16px;
}

.my-order-details-inner-container .my-order-details-status-order-date {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #989CA6;
    margin-left: 11px;
    margin-right: 16px;
    margin-top: 4px;
}

.my-order-details-inner-container .my-order-details-status-calendar-image {
    height: 16px;
    width: 16px;
    margin-top: 6px;
}

/* Order placed status start from here */
.my-order-details-inner-container .my-order-details-order-placed-status-button {
    padding: 4px 12px;
    width: auto;
    height: 32px;
    background: #FEF7EB;
    border: 1px solid #F5B335;
    border-radius: 40px;
}

.my-order-details-order-placed-status-button .my-order-details-order-placed-status-heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color: #F5B335;
    padding-left: 6.5px;
}
/* Order placed status end */
/* Confirmed placed status start from here */
.my-order-details-order-confirmed-status-button {
    padding: 4px 12px;
    width: auto;
    height: 32px;
    background: #E9F8FB;
    border: 1px solid #23B5D3;
    border-radius: 40px;
}

.my-order-details-order-confirmed-status-button .my-order-details-order-confirmed-status-heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color: #23B5D3;
    padding-left: 6.5px;
}
/* Confirmed placed status end */
/* Completed place status start from here */
.my-order-details-order-completed-status-button {
    padding: 4px 12px;
    width: auto;
    height: 32px;
    background:  #EFF6E7;
    border: 1px solid #61A60E;
    border-radius: 40px;
}

.my-order-details-order-completed-status-button .my-order-details-order-completed-status-heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color: #61A60E;
    padding-left: 6.5px;
}
/* Completed place status end */
/* Shipped place status start from here */
.my-order-details-order-shipped-status-button {
    padding: 4px 12px;
    width: auto;
    height: 32px;
    background: #F3F2EB;
    border: 1px solid #8A803C;
    border-radius: 40px;
}

.my-order-details-order-shipped-status-button .my-order-details-order-shipped-status-heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color:  #8A803C;
    padding-left: 6.5px;
}
/* Shipped place status end */
/* Cancelled place status start from here */
.my-order-details-order-cancelled-status-button {
    padding: 4px 12px;
    width: auto;
    height: 32px;
    background:   #F5F6F7;
    border: 1px solid #989CA6;
    border-radius: 40px;
}

.my-order-details-order-cancelled-status-button .my-order-details-order-cancelled-status-heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color: #989CA6;
    padding-left: 6.5px;
}
/* Cancelled place status end */
.my-order-details-order-placed-status-image {
    height: 14px;
    width: 14px;
    margin-bottom: 7px;
}
.my-account-hamburger-menu a:hover {
    text-decoration: none!important;
}

.table-empty-data-main-container a:hover {
    text-decoration: none;
    color: #fff;
}

.my-cart-is-empty-button:hover{
    background-color: #59980c;
}

.my-account-hamburger-menu-section:hover{
    border: none;
}

.my-order-details-cancellation-section {
    width: 100%;
    padding: 24px 32px;
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    margin-top: 16px;
}

.my-order-details-cancellation-inner-section .my-order-details-cancellation-main-heading {
    font-weight: 500;
    font-size: 24px;
    color: #464B55;
    margin-left: 11px;
}

.my-order-details-cancellation-inner-section .my-order-details-cancellation-inner-heading {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
    margin-top: 16px;
}

.my-order-details-cancellation-inner-section .my-order-details-cancellation-section-image {
    height: 16px;
    width: 16px;
    margin-bottom: 8px;
}

/* product-info-section start from here */
.my-order-details-product-information-section {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    margin-top: 16px;
    padding: 24px 32px;
}

.my-order-details-product-information-inner-section .my-order-details-product-information-card-shopping-image {
    height: 16px;
    width: 18px;
    margin-top: 6px;
}

.my-order-details-product-information-inner-section .my-order-details-product-information-main-heading {
    font-weight: 500;
    font-size: 24px;
    color: #464B55;
    margin-left: 9px;
}

.my-order-details-product-information-content .my-order-details-product-information-content-header {
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
}

.my-order-details-product-information-main-content {
    margin-top: 16px !important;
}

.my-order-details-product-information-data {
    align-items: center !important;
}

.my-order-details-product-information-data .my-order-details-product-information-image-border {
    border: 1px solid #d3d6da;
    border-radius: 8px;
    cursor: pointer !important;
    height: 76px;
    position: relative;
    width: 76px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.my-order-details-product-information-data .my-order-details-product-information-image {
    height: 72px;
    object-fit: contain;
    width: 72px;
    padding: 2px;
}

.my-order-details-product-information-data .my-order-details-product-information-heading {
    font-weight: 600;
    font-size: 20px;
    color: #464B55;
    margin-left: 11px;
    width: 100%;
    cursor: pointer;
}

.my-order-details-product-information-data .my-order-details-product-information-heading:hover {
    color: #61A60E;
    cursor: pointer;
}

.my-order-details-product-information-unit-price {
    font-weight: 700;
    font-size: 20px;
    color: #464B55;
}

.my-order-details-inner-product-price {
    align-items: center;
}

/* product-info-section end*/
/* shipping-section section start from here */
.my-order-details-shipping-summary-section {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    margin-top: 16px;
    padding: 24px 32px;
}

.my-order-details-shipping-summary-section .my-order-details-shipping-summary-section-image {
    height: 16px;
    width: 16px;
    margin-top: 5px;
}

.my-order-details-shipping-summary-section .my-order-details-shipping-summary-section-heading {
    font-weight: 500;
    font-size: 24px;
    color: #464B55;
    margin-left: 11px;
}

.my-order-details-shipping-summary-section .my-order-details-shipping-summary-left-heading {
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    margin-top: 16px;
}

.my-order-details-shipping-summary-section .my-order-details-shipping-summary-right-heading {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
    margin-top: 16px;
}

/* shipping-section section end */
/* payment-summary-section start from here */
.my-order-details-payment-section {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    margin-top: 16px;
    padding: 24px 32px;
}

.my-order-details-payment-inner-section .my-order-details-payment-image {
    height: 16px;
    width: 20px;
    margin-top: 5px;
}

.my-order-details-payment-inner-section .my-order-details-payment-heading {
    font-weight: 500;
    font-size: 24px;
    color: #464B55;
    margin-left: 11px;
}

.my-order-details-payment-section .my-order-details-payment-custom-section {
    margin-top: 16px
}

.my-order-details-payment-custom-section .my-order-details-payment-custom-heading-left {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
}

.my-order-details-payment-custom-section .my-order-details-payment-custom-heading-right {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #464B55;
}

.my-order-details-payment-custom-section .my-order-details-payment-custom-total-heading {
    font-weight: 400;
    font-size: 24px;
    color: #464B55;
}

.my-order-details-payment-custom-section .my-order-details-payment-custom-amount-heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #61A60E;
    text-align: right;
}

/* payment-summary-section end */

.my-order-all-state-selection-option-inner-container {
    margin-top: 32px;
    text-align: center;
}
.my-order-all-state-selection-option-inner-container .my-order-all-state-selection-select-custom-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 10px 16px;
    width: 100%;
    height: 44px;
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
    border: none;
    outline: none;
}

.my-order-all-status-filter-custom-dropdown {
    padding: 10px 16px;
    background: #FFFFFF;
    border-radius: 8px;
    width: 180px;
    margin-top: 32px;
    height: 44px;
    position: relative;
    float: right;
    cursor: pointer;
}
.my-order-all-status-filter-custom-dropdown .my-order-all-status-filter-custom-dropdown-heading {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
    line-height: 24px
}

.my-order-all-status-filter-custom-dropdown .my-order-all-status-filter-custom-dropdown-image {
    width: 16px;
    height: 8px;
}
.my-order-all-status-filter-desktop-dropdown-main-container {
    padding: 12px 0px;
    position: absolute;
    width: 180px;
    height: auto;
    right: 14px;
    top: 136px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    z-index: 9;
}
.my-order-all-status-filter-desktop-dropdown-main-container .my-order-all-status-filter-desktop-dropdown-active-heading {
    font-weight: 600;
    font-size: 20px;
    color: #61A60E;
}
.my-order-all-status-filter-desktop-dropdown-main-container .my-order-all-status-filter-desktop-dropdown-disable-heading {
    font-weight: 500;
    font-size: 20px;
    color: #989CA6;
}
.my-order-all-status-filter-desktop-dropdown-main-container .dropdown-item:hover {
    background: #F5F6F7;
    color: #989CA6;
}

.my-order-main-container .my-order-main-right-container {
    padding: 0px 90px 0px 90px;
}
.my-order-all-state-selection-option-custom-container {
    color: #989CA6!important;
    padding: 10px!important;
}

.my-order-all-state-selection-option-custom-container:hover{
    background: #F5F6F7!important;
}
.my-order-all-state-selection-options .css-13cymwt-control {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    width: 100%;
    height: 44px;
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
    border: none;
    outline: none;
}

.my-account-hamburger-main-container .my-account-hamburger-menu-section-heading:hover {
    opacity: 0.5;
}
.my-account-hamburger-main-container .my-account-hamburger-menu-section-image:hover {
    opacity: 0.5;
}


.my-order-all-state-selection-options .css-1jqq78o-placeholder{
    position: absolute;
    left: 16px;
    color: #464B55;
}
.my-order-all-state-selection-options .css-1xc3v61-indicatorContainer {
    color: #989CA6;
}
.my-order-all-state-selection-options .css-1u9des2-indicatorSeparator{
    display: none;
}
.my-order-all-state-selection-options .css-tj5bde-Svg {
    height: 16px;
    width: 16px;
}
/* stepper-section start from here */
.my-order-details-stepper-section {
    width: 100%;
    padding: 32px 32px;
    background: #FFFFFF;
    border-radius: 16px;
    margin-top: 32px;
}
.stepper-status-main-container {
    text-align: center;
}
.stepper-status-main-container .stepper-status-main-heading {
    margin-top: 8px;
    font-weight: 500;
    font-size: 20px;
    color: #464B55;
}
.stepper-status-main-container .stepper-status-image {
    height: 12px;
    width: 12px;
}
.stepper-status-main-container .stepper-status-inner-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #989CA6;
    margin-top: 6px;
    margin-left: 6px;
    position: relative;
    top: 3px;
}
/* stepper-section end */
.my-order-details-section-min-height {
    min-height: 630px!important;
}
.my-order-detail-main-heading-section {
    position: relative;
}

.my-order-details-reorder-button-inner-container {
    outline: none;
    border: none;
    width: 160px;
    height: 44px;
    background: #61A60E;
    border-radius: 32px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    position: absolute;
    right:6px;
    top: 34px;
}
/* Mobile-design start from here */
.my-orders-search-cards-mobile-main-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 20px 16px 20px 16px;
    width: 100%;
    margin-top: 12px;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-tag-image {
    height: 11px;
    width: 11px;
    margin-bottom: 4px;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-calendar-image {
    height: 12px;
    width: 12px;
    margin-bottom: 4px;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-order-number {
    font-weight: 400;
    font-size: 16px;
    color: #464B55;
    margin-left: 6px;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-order-date {
    font-weight: 400;
    font-size: 16px;
    color: #989CA6;
    margin-left: 6px;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-quantity-heading {
    font-weight: 400;
    font-size: 16px;
    color: #989CA6;
    text-transform: capitalize
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-quantity-counts{
    font-weight: 700;
    font-size: 16px;
    color: #464B55;
    margin-left: 4px;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-total-heading {
    font-weight: 400;
    font-size: 16px;
    color: #989CA6;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-total-counts{
    font-weight: 700;
    font-size: 16px;
    color: #464B55;
    margin-left: 4px;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-quantity-container {
    margin-top: 8.5px;
}
/* mobile-my-orders status */
/* Mobile status class start from here */
/* Completed */
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-completed-button {
    padding: 2px 8px;
    background: #EFF6E7;
    border-radius: 40px;
    width: auto;
    text-align: center;
    height: 30px;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-completed-button-heading {
    font-weight: 500;
    font-size: 16px;
    color: #61A60E;
    margin-left: 5px;
    position: relative;
    bottom: 2px;
}
/* Order Placed */
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-placed-button {
    padding: 2px 4px;
    background: #FEF7EB;
    border-radius: 40px;
    width: auto;
    text-align: center;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-placed-button-heading {
    font-weight: 500;
    font-size: 16px;
    color:  #F5B335;
    margin-left: 5px;
}
/* Comfirmed Placed */
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-comfirmed-button {
    padding: 2px 8px;
    background: #E9F8FB;
    border-radius: 40px;
    width: auto;
    text-align: center;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-comfirmed-button-heading {
    font-weight: 500;
    font-size: 16px;
    color:   #23B5D3;
    margin-left: 5px;
}
/* Shipped Placed */
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-shipped-button {
    padding: 2px 8px;
    background: #F3F2EB;
    border-radius: 40px;
    width: auto;
    text-align: center;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-shipped-button-heading {
    font-weight: 500;
    font-size: 16px;
    color: #8A803C;
    margin-left: 5px;
}
/* Cancelled Placed */
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-cancelled-button {
    padding: 2px 8px;
    background:  #F5F6F7;
    border-radius: 40px;
    width: auto;
    text-align: center;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-cancelled-button-heading {
    font-weight: 500;
    font-size: 16px;
    color:  #989CA6;
    margin-left: 5px;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-completed-button-image {
    width: 14px;
    height: 14px;
    margin-bottom: 4px;
}
/* Completed Status end */
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-view-details-button {
    padding: 2px 8px;
    background-color: #FFFFFF;
    border-radius: 32px;
    width: auto;
    outline: none;
    font-weight: 400;
    font-size: 16px;
    color: #61A60E;
    border: 1px solid #61a60e;
    height: 30px;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-reorder-button {
    background: #61a60e;
    border: none;
    border-radius: 32px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    padding: 2px 8px;
    width: 100%;
    height: 30px;
}
.my-orders-search-cards-mobile-main-container .my-orders-search-cards-button-section {
    margin-top: 38px;
}
.my-order-display-block-mobile {
    display: none!important;
}
.my-order-back-button-inner-container {
    align-items: center!important;
}
.my-orders-filter-icon-container {
    margin-top: 42px;
    text-align: center;
}
.my-orders-filter-icon-container .my-orders-filter-icon-inner-container {
    height: 16px;
    width: 16px;
}

/* Mobile-design end */
.my-order-details-cancellation-section .add-cancellation-image {
    height: 16px;
    width: 16px;
    margin-left: 20px;
    margin-bottom: 6px;
    cursor: pointer;
}
.my-order-details-cancellation-section .add-cancellation-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-decoration-line: underline;
    color: #61A60E;
    margin-left: 11px;
    cursor: pointer;
}
.my-order-details-cancelled-input-main-container .my-order-details-cancelled-input-main-container {
    width: 100%;
    padding: 10px 10px 10px 10px;
    outline: none;
    margin-top: 16px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    background: #F5F6F7;
    color: #464B55;
    border: 2px solid #F5F6F7;
    border-radius: 8px;
    height: auto;
}
.my-order-details-cancelled-input-main-container .my-order-details-cancelled-input-main-container:focus {
    border: 2px solid #61A60E;
    background-color: #FFFFFF;
}
.my-order-details-cancelled-cancel-button {
    background: #fff;
    border: 1px solid #61a60e;
    border-radius: 32px;
    box-shadow: 0 4px 80px rgba(0, 0, 0, .04);
    float: right;
    outline: none;
    padding: 10px 30px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #61A60E;
    margin-top: 12px;
    width: 104px;
}
.my-order-details-cancelled-save-button {
    border: 1px solid #61a60e;
    border-radius: 32px;
    box-shadow: 0 4px 80px rgba(0, 0, 0, .04);
    float: right;
    outline: none;
    padding: 10px 30px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: white;
    background-color: #61A60E;
    margin-top: 12px;
    width: 104px;
}
.my-order-details-cancelled-save-button-disabled {
    border: 1px solid  #EDEFF2;
    border-radius: 32px;
    box-shadow: 0 4px 80px rgba(0, 0, 0, .04);
    float: right;
    outline: none;
    padding: 10px 30px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #989CA6;
    background-color: #EDEFF2;
    margin-top: 12px;
    width: 104px;
}

.my-order-search-order-user-image {
    height: 16px;
    width: 16px;
    margin-bottom: 8px;
}

.my-order-search-order-user-image-mobile {
    height: 16px;
    width: 16px;
    margin-bottom: 8px;
}
.my-order-search-order-user-name-heading {
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    margin-left: 11px;
    margin-right: 19px;
}
/* Modal-section start from here */
.order-all-status-section-modal {
    position: fixed;
    z-index: 99999;
}
.order-all-status-section-modal .order-all-status-inner-section-modal {
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 10;
    position: fixed;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    opacity: 0.5;
    opacity: 1 !important;
    background-color: rgba(24, 27, 33, 0.4) !important;
    height: 100%;
}
.order-all-status-section-modal .order-all-status-custom-section-modal {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
    border-radius: 24px 24px 0px 0px;
    text-align: center;
    padding: 35px 22px 24px 22px;
    position: absolute;
    bottom: 0;
}
.order-all-status-section-modal .order-all-status-inner-custom-modal {
    text-align: left;
    position: relative;
}
.order-all-status-section-modal .order-all-status-inner-custom-heading-active {
    font-weight: 500;
    font-size: 24px;
    color: #61A60E;
    margin-bottom: 10px;
}
.order-all-status-section-modal .order-all-status-inner-custom-heading-disable {
    font-weight: 400;
    font-size: 24px;
    color: #989CA6;
    margin-bottom: 10px;
}
.order-all-status-section-modal .dropdown-item:hover {
    font-weight: 400;
    font-size: 24px;
    color: #989CA6;
}
.order-all-status-section-modal .dropdown-item:active {
    background-color: #fff;
    font-weight: 400;
    font-size: 24px;
    color: #989CA6;
    border: none;
}
.order-all-status-section-modal .order-all-status-inner-custom-checkmark-image {
    width: 16px;
    height: 16px;
    float: right;
    margin-top: 8px;
}
.order-all-status-custom-section-modal .order-all-status-gray-line {
    background: #EDEDED;
    border-radius: 100px;
    width: 48px;
    height: 5px;
    text-align: center;
    position: absolute;
    left: 48%;
    right: 50%;
    top: 3%;
}
.my-order-search-bar-margin-bottom {
    margin-bottom: 32px;
}
.my-order-details-product-information-main-content {
    display: block;
}
/* .my-account-left-menu-stricky {
    position: sticky;
    top: 40px;
    margin-bottom: 60px;
} */
.my-order-details-status-section-mobile {
    display: none!important;
}
.my-order-tab-menu .css-1q2h7u5:hover {
    color: #61A60E;
}
.my-order-tab-menu .css-1h9z7r5-MuiButtonBase-root-MuiTab-root:hover {
    color: #61A60E;
}
.my-order-tab-menu .css-1aquho2-MuiTabs-indicator:hover{
    background-color: #61A60E;
    height: 4px;
    background-clip: content-box;
    padding-right: 20px;
}
.my-order-details-articles-code {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #989CA6;
}
.my-order-details-created-by-image {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    margin-bottom: 5px;
}
.created-by-right-side-content {
    font-weight: 400;
    font-size: 16px;
    color: #464B55;
}
.my-order-all-status-filter-desktop-dropdown-main-container a:focus {
    outline: 0px!important;
}
.cursor-pointer {
    cursor: pointer!important;
}
.common-header-fixed {
    position: fixed;
    width: 100%;
    z-index: 999999;
}
.common-header-margin-fixed {
    padding-top: 140px;
}
.cross-image-product-modal {
    height: 13px;
    width: 13px;
    cursor: pointer;
    float: right;
}
/* Modal-section end */
.my-order-tab-accordion-custom-container .accordion-button::after {
    margin-top: 8px!important;
}