/* Thin */
@font-face {
  font-family: "DB Helvethaica X 35 Thin";
  src: local("DB Helvethaica X 35 Thin"),
    url("./font/DB Helvethaica X 35 Thin.ttf") format("truetype");
}
/* Regular */
@font-face {
  font-family: "DB Helvethaica X 55 Regular";
  src: local("DB Helvethaica X 55 Regular"),
        url("./font/DB Helvethaica X 55 Regular.ttf") format("truetype");
    }
/* Medium */
@font-face {
  font-family: "DB Helvethaica X 65 Med";
  src: local("DB Helvethaica X 65 Med"),
    url("./font/DB Helvethaica X 65 Med.ttf") format("truetype");
}
/* Bold */
@font-face {
  font-family: "DB Helvethaica X 75 Bd";
  src: local("DB Helvethaica X 75 Bd"),
    url("./font/DB Helvethaica X 75 Bd.ttf") format("truetype");
}

/* Light */
@font-face {
  font-family: "DB Helvethaica X AG";
  src: local("DB Helvethaica X AG"),
    url("./font/Helvethaica/Inter-Light.ttf") format("truetype");
}
/* REGULAR */
@font-face {
  font-family: "DB Helvethaica X REGULAR";
  src: local("DB Helvethaica X AG"),
    url("./font/Helvethaica/Inter-Regular.ttf") format("truetype");
}
/* MEDIUM */
@font-face {
  font-family: "DB Helvethaica X MEDIUM";
  src: local("DB Helvethaica X AG"),
    url("./font/Helvethaica/Inter-Medium.ttf") format("truetype");
}
/* BOLD */
@font-face {
  font-family: "DB Helvethaica X BOLD";
  src: local("DB Helvethaica X AG"),
    url("./font/Helvethaica/Inter-Bold.ttf") format("truetype");
}