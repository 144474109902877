.marginTop-20 {
    margin-top: 20px !important;
}
.marginTop-15{
    margin-top: 15px !important;
}
.marginTop-10 {
    margin-top: 10px !important;
}
.customerCheckout-outer{
    margin-top: 90px;
}

.customerCheckoutCard-bgSecond{
    border: 2px solid #EDEFF2 !important;
    background-color: #EDEFF2 !important;
}
.customerCheckout {
    box-sizing: border-box;
    background-color: #F5F5F5;
    padding: 0 100px;
}

.customerCheckout h1 {
    font-weight: 600;
    font-size: 40px;
    color: #464B55;
    margin: 30px 0 20px;
}

.customerCheckoutCard {
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 15px;
    min-height: 80px;
}

.customerCheckoutCardHead {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
}

.customerCheckoutCardHeadLt {
    flex: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.customerCheckoutCardHeadRt {
    flex: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.customerCheckoutCard h3 {
    color: #464B55;
    font-size: 24px;
    font-weight: 600;
    line-height: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    line-height: 24px;
}

.customerCheckoutCard h3 img {
    width: 16px;
    margin-top: -2px;
    margin-right: 12px;
}

.customerCheckoutCardHeadRt h2 {
    margin: 0;
    color: #61A60E;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}

.customerCheckoutCardHeadRt h2 span {
    border-bottom: 1px solid #61A60E;
}

.customerCheckoutCardHeadRt h2 img {
    width: 15px;
    margin-right: 7px;
}

.customerCheckoutCard p {
    color: #989CA6;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
    /* word-wrap: break-word; */
}

.customerCheckoutCard h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #464B55;
    margin-bottom: 0;
    /* text-transform: capitalize; */
}

.customerCheckoutCard label {
    font-size: 20px;
    margin-bottom: 0;
    font-weight: 400;
    color: #464B55;
    display: block;
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
    margin-bottom: 4px;
}

.customerCheckoutCard h5 {
    font-size: 20px;
    height: auto;
    border-radius: 5px;
    background-color: #EDEFF2;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;
    word-wrap: break-word;
    text-transform: capitalize;
}

.customerCheckoutCard h5 img {
    width: 10px;
}

.customerCheckoutCard h5 span {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
}

.customerCheckoutCardGreyBorderedLabel {
    border: 1px solid #D3D6DA;
    padding: 5px;
    border-radius: 5px;
    margin: 0 !important;
}

.customerCheckoutCard input[type="text"] {
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    height: 48px;
    border-radius: 5px;
    background-color: #F5F6F7;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    border: 2px solid #F5F6F7;
    outline: none;
}

.customerCheckoutCard input[type="textarea"] {
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    /* line-height: 24px; */
    color: #464B55;
    height: 48px;
    border-radius: 5px;
    background-color: #F5F6F7;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    border: 2px solid #f5f6f7;
    outline: none;
    
}

.customerCheckoutCard textarea {
    background: #F5F6F7;
    border-radius: 8px;
    font-size: 20px;
    padding: 12px 16px;
    width: 100%;
    border: 2px solid #F5F6F7;
    min-height: 48px;
    outline: none;
}

.customerCheckoutCard textarea:focus,
.customerCheckoutCard input[type="text"]:focus {
    outline: none;
    border: 2px solid #61A60E;
    background-color: #fff;
}
.customerCheckoutCard input[type="date"] {
    font-size: 20px;
    color: #464B55 !important;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    width: 100%;
    height: 48px;
    background-color: #EDEFF2;
    border-radius: 8px;
    outline: none;
    border: 1px solid #EDEFF2;
}

.customerCheckoutCard-ShippingAddress input[type=checkbox] {
    accent-color: #61A60E;
    cursor: pointer;
  } 
  .customerCheckoutCard-ShippingAddress-textarea-counts{
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    margin-top: 8px;
    float: right;
}
.customerCheckoutCardBtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: 0px;
}

.customerCheckoutCardSaveBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    padding: 7px;
    gap: 6px;
    width: 165px;
    background: #61A60E;
    border-radius: 32px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    border: 1px solid #61A60E;
}
.customerCheckoutCardSaveBtnDisable{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    padding: 7px;
    gap: 6px;
    width: 165px;
    background: #EDEFF2;
    border-radius: 32px;
    color: #989CA6;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    border: 1px solid #EDEFF2;
    cursor: pointer;
}
.customerCheckoutCardCancelBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 6px;
    width: 110px;
    border: 1px solid #61A60E;
    border-radius: 30px;
    color: #61A60E;
    font-size: 20px;
    outline: none;
    background-color: #fff;
}

.customerCheckoutCardCancelBtn img {
    width: 12px;
}

.customerCheckoutCard .btn-primary.dropdown-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    width: 100%;
    font-size: 20px;
    height: 48px;
    color: #989CA6 !important;
    background-color: #EDEFF2 !important;
    border: 1px solid #EDEFF2 !important;
    border-radius: 8px;
    width: 100%;
    text-transform: capitalize;
    box-shadow: none;
    text-transform: capitalize;
}

.customerCheckoutCard .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
    text-align: left;
    outline: none;
    color: #989CA6 !important;
    background-color: #EDEFF2 !important;
    border: 1px solid #EDEFF2 !important;
}

.customerCheckoutCard .dropdown-toggle:focus::after,
.customerCheckoutCard .dropdown-toggle::after {
    border: none !important;
}

.customerCheckoutCardDropDown .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    display: block;
    width: 100% !important;
    font-size: 20px;
    color: #989CA6 !important;
    border: 1px solid #F5F6F7 !important;
    background: #F5F6F7 !important;
    box-shadow: none !important;
    border-radius: 5px!important;
    padding: 5px 20px !important;
    height: 48px !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.customerCheckoutCardDropDown .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.customerCheckoutCardDropDown .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0 !important;
}

.customerCheckoutCardDropDown .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    top: 145px
}

.customerCheckoutCard .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
    border-radius: unset !important;
}

.css-1nrlq1o-MuiFormControl-root {
    width: 100%;
}

.customerCheckoutCard .customerCheckoutCard .dropdown-item:hover {
    color: #989CA6 !important;
    background-color: #F5F6F7 !important;
}

.customerCheckoutCard .dropdown-item:focus {
    outline: none !important;
    outline-offset: none !important;
    color: #61A60E;
}

.customerCheckoutCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.customerCheckoutCard .css-ahj2mt-MuiTypography-root {
    color: #464B55;
    font-size: 20px;
    font-weight: 500;
    margin-top: 2px;
}
.customerCheckoutCard .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
    margin-top: 2px !important;
}

/* .customerCheckoutCard .css-j204z7-MuiFormControlLabel-root {
    padding: 16px;
    width: 100% !important;
    height: 48px;
    padding: 5px;
    border: 2px solid #61A60E;
    border-radius: 6px;
    margin: unset !important;
    position: relative;
} */
.selected-payment {
    padding: 16px;
    width: 100% !important;
    height: 48px;
    padding: 5px;
    border: 2px solid #61A60E;
    border-radius: 6px;
    margin: unset !important;
    position: relative;
}

.unselected-payment {
    padding: 16px;
    width: 100% !important;
    height: 48px;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #D3D6DA;
    border-radius: 6px;
    margin: unset !important;
    position: relative;
}
.customerCheckoutCard .css-1a5icme:hover{
    background-color: transparent !important;
}
.customerCheckoutCard .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked .css-11zohuh-MuiSvgIcon-root {
    color: #61A60E;
}

.customerCheckoutCard .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked .css-1hbvpl3-MuiSvgIcon-root {
    color: #61A60E;
}

.customerCheckoutCard .css-vqmohf-MuiButtonBase-root-MuiRadio-root .css-1hbvpl3-MuiSvgIcon-root {
    color: #BFC2C9;
}

.customerCheckoutCard .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked .css-ahj2mt-MuiTypography-root {
    color: #61A60E;
}

.customerCheckoutCard .customerCheckoutCardLabelWithoutBorder .css-j204z7-MuiFormControlLabel-root {
    padding: 16px;
    width: unset !important;
    height: 48px;
    padding: 5px;
    border: none !important;
    border-radius: 6px;
    margin: unset !important;
}

.customerCheckoutCard .customerCheckoutCardLabelWithoutBorder .css-dmmspl-MuiFormGroup-root {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.customerCheckoutCard .MuiOutlinedInput-root {
    border-radius: none !important;
}

.customerCheckoutCard .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.customerCheckoutCard .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover,
.customerCheckoutCard .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:focus {
    border-radius: 5px !important;
    border: 1px solid #f5f6f7 !important;
    border: none !important;
    background-color: #f5f6f7 !important;
    font-size: 20px !important;
}

.customerCheckoutCard .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 48px !important;
}

.ShippingAdddressEdit {
    width: 20px;
    cursor: pointer;
}

.customerCheckoutCard-ShippingAddress {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0 20px;
    border-radius: 10px;
    border: 2px solid #61A60E;
}

.customerCheckoutCard .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.customerCheckoutCard-select .MuiIconButton-root-MuiAutocomplete-popupIndicator:hover {
    background-color: #F5F6F7 !important;
}

.customerCheckoutCard-select .MuiIconButton-root-MuiAutocomplete-popupIndicator.Mui-selected {
    background-color: #fff !important;
    color: #61A60E !important;
}


.customerCheckoutCard-select .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 12px 16px !important;
    font-size: 20px !important;
}

.customerCheckoutCard-select {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #464B55;
}

.customerCheckoutCard-select .css-1qqsdnr-MuiAutocomplete-root {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.customerCheckoutCard-select .MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root,
.customerCheckoutCard-select .MuiAutocomplete-hasClearIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
}

.customerCheckoutCard-select .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator:hover {
    background-color: transparent;
}

.customerCheckoutCard-select .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 9px !important;
    top: 5px !important;
}
.customerCheckoutCard-select .css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 9px !important;
    top: 5px !important;
}

.customerCheckoutCard-select .css-i4bv87-MuiSvgIcon-root {
    font-size: 30px !important;
}

.customerCheckoutCard-select .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 20px !important;
}

.customerCheckoutCard-select li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 17px !important;
}

/* .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
        font-size: 20px;
    } */
    .customerCheckoutCard-calendarDesktop{
        position: relative;
    }
.customerCheckoutCard-calendarDesktop-btn{
    outline: none;
    width: 100%;
    font-size: 20px;
    color: #464B55;
    border: 2px solid #EDEFF2;
    background: #EDEFF2;
    box-shadow: none;
    border-radius: 5px;
    padding: 5px 15px;
    height: 48px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    /* background-image: url(./Assets/SVG/Calander.svg);
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 4% center;
    cursor: pointer;
    padding-left: 40px;
    padding-right: 40px; */
}
.customerCheckoutCard-calendarDesktop .react-calendar{
    position: absolute;
    width: 95%;
    left: -3px;
    top: 61px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border-radius: 12px;
    z-index: 1;
    padding: 10px;
}
.customerCheckoutCard-calendarDesktop .react-calendar__navigation{
    display: flex;
    align-items: center;
}
.customerCheckoutCard-calendarDesktop button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
.customerCheckoutCard-calendarDesktop button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button{
display: none;
}
.customerCheckoutCard-calendarDesktop .react-calendar__navigation button,
.customerCheckoutCard-calendarDesktop button.react-calendar__tile {
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 20px;
    color: #464B55;
    margin-bottom: 10px;

}

.customerCheckoutCard-calendarDesktop .react-calendar__month-view__weekdays__weekday {
    text-transform: uppercase;
    font-size: 16px;
    border: none;
}

.customerCheckoutCard-calendarDesktop .react-calendar__month-view__weekdays__weekday abbr[title] {
    text-decoration: none;
}

.customerCheckoutCard-calendarDesktop button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    color: #61A60E;
}
.customerCheckoutCard-calendarDesktop button.react-calendar__tile.react-calendar__month-view__days__day{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.customerCheckoutCard-calendarDesktop button.react-calendar__tile.react-calendar__month-view__days__day:hover{
color: #464B55;;
background-color: #F5F6F7;
}
.customerCheckoutCard-calendarDesktop button.react-calendar__tile.react-calendar__month-view__days__day:focus{
    color: #61A60E;
    background-color: #EFF6E7;
}
.css-13sljp9{
display: flex !important;
}
.css-9l3uo3{   
    margin: 2px !important;
    font-size: 20px !important;
}
.checkout-payment-section-main-container .css-1a5icme.Mui-checked {
    color: #61A60E !important;
}
.css-qiwgdb.MuiSelect-select{
    height: 48px !important;
}
.customerCheckout .css-fvipm8{
    font-weight: 400;
    font-size: 20px !important;
    border-radius: 5px;
    background-color: #f5f6f7!important;
}
.customerCheckout .css-qiwgdb{
    background-color: #F5F6F7 !important;
    padding: 10px 15px !important;
    color: #464B55 !important;
}
.customerCheckout .css-igs3ac{
background-color: transparent !important;
}
.customerCheckout .css-fvipm8:hover{
    background-color: transparent !important;
}
.css-xxees4{
    width: 100% !important;
}
.css-xxees4 .MuiOutlinedInput-root {
    width: 100% !important;
    padding: 0px !important;
    background-color: #f5f6f7 !important;
}
.customerCheckout .css-segi59:hover .MuiOutlinedInput-notchedOutline {
border-color: unset !important;
    border: 1px solid transparent !important;
}
.customerCheckout .css-igs3ac{
    border-color: unset !important;
    border: 1px solid transparent !important;
}
.MuiAutocomplete-hasPopupIcon.css-xxees4 .MuiOutlinedInput-root{
    background-color: #f5f6f7 !important;
}
.customerCheckoutCardLabelWithoutBorder{
    padding-left: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.customerCheckoutCardLabelWithoutBorder input[type="radio"]{
    accent-color: #61A60E;
}
.customerCheckoutCardDropDown .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    font-size: 30px !important;
}
.customerCheckoutCardDropDown .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
font-size: 20px !important;
color: #464B55 !important;

}
@media (min-width: 600px){
    .css-1km1ehz {
        font-size: 18px !important;
    }
}
@media screen and (max-width: 1100px) {
    .customerCheckout {
        padding: 0 60px;
    }
    .customerCheckout-outer {
        margin-top: 80px;
    }
}

@media screen and (max-width: 991px) {
    .customerCheckout {
        padding: 0 20px;
    }

    .customerCheckout h1 {
        font-size: 32px;
        margin: 20px 0;
    }

    .customerCheckoutCard {
        margin-bottom: 10px;
    }

    .customerCheckoutCard h3 {
        font-size: 20px;
        line-height: 16px;
    }

    .customerCheckoutCard h3 img {
        width: 15px;
        margin-right: 15px;
    }

    .customerCheckoutCard label {
        font-size: 20px;
        height: auto;
        padding-right: 30px;
    }

    .customerCheckoutCard p {
        font-size: 20px;
    }

    .customerCheckoutCard h4 {
        font-size: 20px;
        line-height: 20px;
    }

    .customerCheckoutCardHeadRt h2 {
        font-size: 20px;
        line-height: 20px;
    }

    .customerCheckoutCardHeadRt h2 img {
        width: 15px;
        margin-right: 8px;
    }

    .customerCheckoutCard h5 {
        padding: 12px;
        gap: 10px;
    }

    .customerCheckoutCard h5 span {
        font-size: 20px;
        line-height: 20px;
    }

    .ShippingAdddressEdit {
        width: 15px;
    }

    .customerCheckoutCard-ShippingAddress {
        padding: 10px;
    }

    .customerCheckoutCard label {
        margin-top: 10px !important;
    }
}

@media screen and (max-width: 767px) {
    .customerCheckout-outer {
        margin-top: 58px;
    }
    .customerCheckoutCard-mobModal.modal {
        display: flex !important;
        align-items: center;
        justify-content: baseline;
    }

    .fade.customerCheckoutCard-mobModal {
        opacity: 1 !important;
        background-color: rgba(24, 27, 33, 0.4) !important;
        transition: none !important;
    }

    .customerCheckoutCard-mobModal .modal.fade .modal-dialog {
        transition: none;
        width: 100%;
    }

    .customerCheckoutCard-mobModal .modal-dialog {
        margin: 0;
        position: absolute;
        bottom: 0;
        border: none;
        right: 0;
        left: 0;
    }

    .customerCheckoutCard-mobModal .modal.show .modal-dialog {
        transform: none;
        width: 100%;
    }

    .customerCheckoutCard-mobModalCalander .react-calendar__navigation {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .customerCheckoutCard-mobModalCalander .react-calendar__navigation button,
    .customerCheckoutCard-mobModalCalander button.react-calendar__tile {
        outline: none;
        border: none;
        background-color: transparent;
        font-size: 18px;
        color: #464B55;
        margin-bottom: 10px;

    }

    .customerCheckoutCard-mobModal .modal-body {
        padding: 20px 15px 10px;
    }

    .customerCheckoutCard-mobModal .modal-content {
        border: none;
        border-radius: 0.3rem;
        outline: 0;
        box-shadow: none;
        border-radius: 20px 20px 0 0;
    }

    .react-calendar__month-view__weekdays__weekday {
        text-transform: uppercase;
        font-size: 20px;
        border: none;
    }

    .react-calendar__month-view__weekdays__weekday abbr[title] {
        text-decoration: none;
    }

    .customerCheckoutCard-mobModalCalander button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
        display: none;
    }

    .customerCheckoutCard-mobModalCalander button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
        display: none;
    }

    button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
        color: #61A60E;
        background-color: #EFF6E7;
        border-radius: 50%;

    }

    .CheckoutDeliveryDate,
    .CheckoutDeliveryTime {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        gap: 5;
        width: 100%;
        background: #EDEFF2;
        border-radius: 8px;
        color: #464B55;
        font-size: 20px;
        outline: none;
        border: 1px solid #EDEFF2;
        padding: 5px;
    }

    .CheckoutDeliveryTime {
        background-color: #F5F6F7;
        color: #989CA6;
        border: 1px solid #F5F6F7;

    }

    .CheckoutDeliveryDate img,
    .CheckoutDeliveryTime img {
        width: 10px;
    }

    .customerCheckoutCard-mobModalCalander ul {
        padding: 0;
        width: 100%;
    }

    .customerCheckoutCard-mobModalCalander ul li {
        list-style: none;
        width: 100%;
        padding: 5px 0;
    }

    .customerCheckoutCard-mobModalCalander ul li a {
        color: #989CA6 !important;
        font-size: 20px !important;
    }

    .customerCheckoutCard-mobModalCalander ul li a:focus,
    .customerCheckoutCard-mobModalCalander ul li a:visited,
    .customerCheckoutCard-mobModalCalander ul li a:active {
        color: #61A60E;
    }

    .customerCheckoutCard {
        margin-bottom: 10px;
    }

    .marginBottom-10 {
        margin-bottom: 10px !important;
    }

    .customerCheckout {
        padding: 15px 10px;
    }

    .customerCheckout h1 {
        font-size: 20px;
        text-align: center;
    }

    .customerCheckoutCard h3 {
        font-size: 20px;
        line-height: 16px;
    }

    .customerCheckoutCard h5 {
        font-size: 20px;
        line-height: 20px;
    }

    .customerCheckoutCard {
        padding: 20px 15px 20px;
        border-radius: 10px;
        min-height: 50px;
    }

    .customerCheckoutCardHead {
        margin-bottom: 10px;
    }

    .customerCheckoutCard .col-sm-5 {
        width: 41.66666667% !important;
    }

    .customerCheckoutCard .col-sm-7 {
        width: 58.33333333% !important;
    }

    .customerCheckoutCard label {
        font-size: 20px;
        margin-top: 1px !important;
        margin-bottom: 0px;
        padding-right: 0;
    }
    .customerCheckoutCard-ShippingAddress-textarea-counts{
        margin-bottom: 0;
    }
    .customerCheckoutCard p {
        font-size: 20px;
        line-height: 28px;
    }

    .customerCheckoutCard h4 {
        font-size: 20px;
        line-height: 28px;
    }

    .customerCheckoutCardHeadRt h2 {
        font-size: 16px;
        line-height: 16px;
    }
    

    .customerCheckoutCardHeadRt h2 img {
        width: 12px;
        margin-right: 3px;
    }

    .customerCheckoutCard input[type="text"] {
        height: 44px;
        padding: 10px 12px;
    }
    .customerCheckoutCard input[type="textarea"]{
        height: 44px;
        padding: 10px 12px;
    }

    .customerCheckoutCard .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        height: 48px !important;
    }

    .customerCheckoutCard .css-ahj2mt-MuiTypography-root {
        font-size: 20px;
    }

    .customerCheckoutCard h5 {
        align-items: flex-start;
        padding: 12px;
    }

    .customerCheckoutCard h5 span {
        font-size: 15px;
    }

    .customerCheckoutCard h5 img {
        margin-top: 5px;
    }

    .ShippingAdddressEdit {
        width: 12px;
    }

    .customerCheckoutCard-ShippingAddress {
        padding: 10px 0px 20px;
    }
    
    .customerCheckoutCardBtn-xs{
        width: 100% !important;
    }

    .customerCheckoutCardSaveBtn {
        width: 50%;
        font-size: 20px;
        padding: 5px;
    }

    .customerCheckoutCardSaveBtn-xs{
        width: 100% !important;
    }

    .customerCheckoutCardCancelBtn {
        width: 50%;
        font-size: 20px;
        padding: 5px;
    }
    .marginBottom-10xs{
        margin-bottom: 10px;
    }
    .customerCheckoutCardHeadRt{
        flex: 30%;
    }
    .customerCheckoutCardHeadLt{
        flex: 70%;
    }
    .css-qiwgdb.MuiSelect-select{
        height: 44px !important;
    }
}

@media screen and (min-width: 576px) {
    .customerCheckoutCard-mobModal .modal-dialog {
        max-width: unset !important;
    }
}

@media screen and (max-width: 576px) {}
@media screen and (max-width: 374px) {
    .CheckoutDeliveryDate,
    .CheckoutDeliveryTime {
        font-size: 17px;
    }
    .customerCheckoutCard-mobModalCalander ul li a {
        font-size: 17px !important;
    }
    .react-calendar__month-view__weekdays__weekday {
        font-size: 17px;
    }
    
    .customerCheckoutCard h3 {
        font-size: 17px;
    }

    .customerCheckoutCard h5 {
        font-size: 17px;
    }
    .customerCheckoutCard p {
        font-size: 17px;
    }

    .customerCheckoutCard h4 {
        font-size: 17px;
    }
    .customerCheckoutCard label {
        font-size: 17px;
    }
    .css-9l3uo3{
        font-size: 17px !important;
    }
    .checkout-payment-section-main-container .css-1a5icme.Mui-checked {
        color: #61A60E !important;
    
    }
    .css-fvipm8{
        font-size: 17px;
    }
    .customerCheckoutCardSaveBtn {
        font-size: 17px;
    }
}

.invalid-feedback-tax {
    width: 100%;
    /* margin-top: 0.25rem; */
    font-size: 12px;
    color: #dc3545;
}

.margintop16 {
    margin-top: 16%
}

.margintop12 {
    margin-top: 12%;
}

.invalid-feedback-tax {
    width: 100%;
    /* margin-top: 0.25rem; */
    font-size: 1.5em;
    color: #dc3545;
}

.margintop16 {
    margin-top: 16%
}

.margintop12 {
    margin-top: 12%;
}

.createOrderCardDisabledBtn{
    background: #EDEFF2;
    padding: 10px 25px;
    font-size: 20px;
    color: #989CA6;
    border: 1px solid #EDEFF2;
    outline: none;
    border-radius: 30px;
    font-weight: 400;
    min-width: 160px;
    cursor: not-allowed;
}

.customerCheckoutCardGreyBorderedLabel .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
    color: #464B55 !important;
    font-size: 20px;
    font-weight: 500;
    margin-top: 2px;
}

.customerCheckoutCardGreyBorderedLabel .css-9l3uo3 {
    color: #464B55 !important;
    font-size: 20px;
    font-weight: 500;
    margin-top: 2px;
}

.customerCheckoutCard-errorBorder {
        border: 2px solid #EF3145 !important;
}
.checkout-payment-section-main-container .css-1a5icme {
    color: #BFC2C9!important;
}
.checkout-screen-dropdown-options {
    font-size: 20px!important;
}