.odmProduct-ProductDetails {
    width: 100%;
    display: flex;
    align-items: center;
    width: 230px;
    position: relative;
}

.odmProduct-ProductDetails-Img-badge {
    position: absolute;
    cursor: pointer !important;
}

.odmProduct-ProductDetails-Img {
    width: 60px;
    height: 60px;
    border: 1px solid #D3D6DA;
    border-radius: 8px;
    /* margin-right: 10px; */
    cursor: pointer !important;
}

.main-table-section .form-control.is-invalid {
    background-image: none !important;
}

.odmProduct-ProductDetails-Img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer !important;
}

.odmProduct-ProductDetails h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    /* display: flex;
    align-items: center; */
    color: #464B55;
    /* word-wrap: break-word; */
    text-transform: capitalize;
    text-align: left;
    cursor: pointer;
}
.odmProduct-ProductDetails h5:hover{
    color: #61A60E;
}
.odmProduct-UserDetails h4 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 0;
    text-transform: capitalize;
}

.odmProduct-UserDetails h4 img {
    width: 15px;
    margin-right: 5px;
}

.odmProduct-UserDetails h4 span {
    color: #464B55;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
}

.odmProduct-UserDetails p {
    color: #989CA6;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    text-transform: capitalize;
}

.odmProduct-ProductDetails-select {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.odmProduct-ProductDetails-select p {
    color: #989CA6;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    text-transform: capitalize;
}

.odmProduct-ProductDetails-select h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #464B55;
    width: 100%;
    text-transform: capitalize;
}

.odmProduct-ProductDetails-desc h3 {
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    white-space: nowrap;
    text-transform: capitalize;
}

.odmProduct-ProductDetails-desc h4 {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
    white-space: nowrap;
    text-transform: capitalize;
}

.odmProduct-ProductDetails-desc h4 span {
    color: #464B55;
    font-weight: 600;
    text-transform: capitalize;
}

.odmProduct-ProductDetails-desc h5 {
    font-size: 20px;
    color: #BFC2C9;
    font-weight: 600;
    text-transform: capitalize;
}

.odmProduct-ProductDetails-desc input[type="text"] {
    outline: none;
    padding: 5px;
    border-radius: 8px;
    border: 1px solid #61A60E;
    color: #61A60E;
    font-size: 20px;
    width: 50px;
    text-transform: capitalize;
    background-color: transparent;
    text-align: center;
}

.odmProduct-UserDetails {
    /* width: 300px; */
    padding-right: 10px;
    position: relative;
}

.OrderManagementByOrder {
    width: 100%;
}
.OrderManagementByOrder-productText h3{
font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #464B55;
}

.OrderManagementByOrder-productText h3:hover{
    color: #61A60E;
    cursor: pointer;
}

.OrderManagementByOrder .css-15wwp11-MuiTableHead-root {
    background: #F5F6F7 !important;
    border-radius: 8px !important;
    white-space: nowrap;
}

.OrderManagementByOrder .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #61A60E !important;
    width: 16px;
    height: 16px;
}

.OrderManagementByOrder .css-1m9m2yn {
    width: 230px !important;
}

.OrderManagementByOrder .css-1ygcj2i-MuiTableCell-root {
    padding: 0 5px !important;
}

.OrderManagementByOrder .css-1ex1afd-MuiTableCell-root {
    padding: 0 !important;
}

.OrderManagementByOrder .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none !important;
}
.odmProduct-UserDetails-userInfo{
    position: relative;
}

.odmProduct-UserDetails-userInfoDesc{
    display: none;
    min-width: 160px;
    background: #FFFFFF;
border-radius: 8px;
    position: absolute;
    /* top: -110px; */
    top: 50px;
    left: -30px;
    padding: 10px;
    z-index: 9999;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
}
.odmProduct-UserDetails-userInfoDesc h6 span{
    font-size: 16px;
    /* color: #464B55; */
    color: #989CA6;
    font-weight: 400;
    line-height: 1;
}
.odmProduct-UserDetails-userInfoDesc h6 {
    font-size: 16px;
    color: #989CA6;
    font-weight: 400;
    line-height: 1;
}

.odmProduct-UserDetails-userInfoDesc img {
    width: 10px;
    margin: 0px 5px 5px 3px;
    opacity: .5;
}

.odmProduct-UserDetails .odmProduct-UserDetails-userInfo:hover + .odmProduct-UserDetails-userInfoDesc{
      display: block;
}

.odmProduct-UserDetails .odmProduct-UserDetails-userInfo-product:hover + .odmProduct-UserDetails-userInfoDesc{
    display: block;
}

.auto-complete-order-by-product-table select{
    padding: 5px;
    border-radius: 8px;
    border: 1px solid #D3D6DA;
    width: 200px;
    font-size: 20px;
    color: #464B55;
    cursor: pointer;
    text-transform: capitalize;
}

.auto-complete-order-by-product-table select:focus{
    border: 2px solid #61A60E;
    outline: none !important;
}
.OrderManagementFilter-subCategory .searchWrapper {
    /* background: #f5f6f7!important; */
    border: 1px solid #bfc2c9!important;
    border-radius: 8px!important;
    font-size: 20px!important;
    min-height: 36px!important;
    padding: 0 !important;
    width: 100%!important;
    background-color: #fff !important;
}

.OrderManagementFilter-subCategory .searchWrapper:focus{
    border: 2px solid #61A60E !important;
}

.OrderManagementFilter-subCategory .chip {
    background: #eff6e7!important;
    border-radius: 6px!important;
    color: #61a60e;
    display: inline-flex;
    font-size: 20px;
    height: 28px!important;
    margin: 5px;
    padding: 2px 4px 2px 8px!important;
    text-transform: capitalize !important;
}

.OrderManagementFilter-subCategory .multiSelectContainer input{
margin-top: 0 !important;
}
.css-2sahls-MuiAutocomplete-root .MuiAutocomplete-tag{
    font-size: 20px !important;
    color: #61a60e !important;
}
/* .OrderManagementFilter-subCategory .multiSelectContainer input:focus{
    border: 2px solid #61A60E !important;
} */


.OrderManagementFilter-subCategory li.groupChildEle {
    padding-left: 30px !important;
    display: flex !important;
    align-items: center;
    font-size: 20px !important;
    text-transform: capitalize !important;
    color: #61A60E !important;
    font-weight: 400 !important;
    padding: 5px 10px !important;
}

.OrderManagementFilter-subCategory input[type="checkbox"]{
    accent-color: #61A60E !important;
    margin-right: 5px !important;
}
.OrderManagementFilter-subCategory .multiSelectContainer li:hover{
    background-color: unset !important;
}

.OrderManagementFilter-subCategory .multiSelectContainer li.groupHeading {
    color: #61A60E !important;
    font-size: 20px;
    font-weight: 600 !important;
    padding: 5px 15px;
    pointer-events: none;
    text-transform: capitalize !important;
}

.OrderManagementFilter-subCategory .multiSelectContainer ul{
    background-color: #fff !important;
}

.OrderManagementFilter-subCategory img.icon_cancel.closeIcon {
    background-color: #61A60E;
    color: green;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
.OrderManagementFilter-subCategory .notFound{
font-size: 20px;
font-weight: 400;
color: #464B55;
}