@media screen and (max-width: 767px) {
    .loginResetScreen {
        height: 100vh;
        justify-content: center;
        align-items: center;
        position: absolute;
    }

    .loginResetScreen .logIn-firstTime {
        flex-direction: column;
    }

    .loginResetScreen button.logIn-firstTimeBtn {
        position: absolute;
        bottom: 20px !important;
        left: 3% !important;
        width: 94% !important;
    }
}