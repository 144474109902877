@import "../index.css";
* {
    box-sizing: border-box;
    font-family: 'DB Helvethaica X 55 Regular';
  }
  .promotion-card .carousel-control-next-icon, 
  .promotion-card .carousel-control-prev-icon{
    display: none !important;
  }
.new-items-heading {
  font-weight: 500;
  font-size: 56px;
  font-family: "DB Helvethaica X 65 Med"!important;
  color: #464B55;
}
  .newitem-wraper{
    padding-left: 65px;
    padding-right: 65px;
  }
  
  .new-item-parent {
    
    background: #FFFFFF;
  }
  
  .promohead {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .promohead-lt {
    flex: 50%;
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .promohead-lt img {
    width: 50px;
    margin-right: 20px;
  }
  
  .promohead-lt h4 {
    font-size: 56px;
    font-weight: 500;
    font-family: "DB Helvethaica X 65 Med"!important;
    color: #464B55;
  }
  
  .promohead-rt {
    flex: 50%;
    text-align: right;
    height: 100%;
  }
  
  .promohead-rt a,
  .promohead-rt a:hover,
  .promohead-rt a:visited,
  .promohead-rt a:focus {
    color: #61A60E;
    font-size: 24px;
    text-decoration: none;
    border-bottom: 1px solid #61A60E;
  }
  
  .promotions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* height: 100%; */
    position: relative;
    flex-direction: row;
  }
  
  .promo {
    /* flex: 23% 1; */
    /* flex-shrink: 0; */
    flex: 0 0 200px;
    border-radius: 15px;
    background: #FFFFFF;
    /* height: auto; */
    position: relative;
    /* height: auto !important; */
  }
  

  .promo-img {
    position: relative;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer !important;
  }
  
  .promo-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .promo-img-badge1 {
    position: absolute;
    top: 5px;
    left: 0;
  }
  
  .promo-img-badge1 img {
    width: 50px;
  }
  
  .promo-price {
    display: flex;
    min-height: 24%;
  }
  
  .promo-price-lt {
    flex: 50%;
    text-align: left;
    height: 100%;
  }
  
  .promo-price-rt {
    flex: 50%;
    text-align: right;
    height: 100%;
  }
  
  .promo-price-lt p {
    font-size: 18px;
    color: #464b55;
    font-weight: 600;
    font-family: 'DB Helvethaica X 55 Regular'!important;
  }
  .promo-price-lt span {
    font-size: 18px;
    color: #464b55;
    font-weight: 500;
    font-family: "DB Helvethaica X 65 Med";
    cursor: pointer !important;
    line-height: 1;
  }
  
  .promo-price-rt h4 {
    font-size: 20px;
    font-weight: 700;
    color: #ef3145;
    line-height: 1;
  }
  
  .promo-price-rt h5 {
    font-size: 16px;
    font-weight: 400;
    color: #989ca6;
    line-height: 1;
  }
  
  .promo-desc {
    margin-bottom: 10px;
  }
  
  .promo-desc select {
    font-size: 18px;
    background-color: #f5f6f7;
    border-radius: 32px;
    padding: 6px 12px 6px 16px;
    height: 36px;
    color: #464b55;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url(../../public/DownArrow.svg);
    background-repeat: no-repeat;
    background-position: 96% center;
    background-size: 22px;
    padding-right: 40px;
    width: 100%;
    border: none;
    outline: none;
  }
  
  .promo-btn {
    display: flex;
  }
  
  .promo-btn-lt {
    flex: 5%;
  }
  
  .promo-btn-rt {
    flex: 95%;
  }
  
  .promo-btn-wishlist {
    outline: none;
    border: none;
    background-color: #f5f6f7;
    text-align: center;
    font-size: 13px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }

  .promo-btn-wishlist-active {
    outline: none;
    border: none;
    background-color: #EF3145;
    text-align: center;
    font-size: 13px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .promo-btn-wishlist img {
    width: 16px;
    height: 16px;
  }
  .promo-btn-wishlist-active img {
    width: 16px;
    height: 16px;
  }
  
  .promo-btn-addtocart {
    outline: none;
    border: none;
    color: #fff;
    background-color: #61a60e;
    text-align: center;
    font-size: 20px;
    padding: 2px 0px;
    border-radius: 20px;
    width: 80%;
    margin: auto;
  
  }
  
  .promo-btn-addtocart img {
    margin-right: 10px;
    width: 18px;
    height: 16px;
    margin-bottom: 3px;
  }
  .promo-btn-right, .add-to-cart-container {
    width: 100%;
    margin-left: 1%;
}
.promo-btn-addtocart{
  height: 36px;
}
  
  @media screen and (max-width: 1100px) {
    .promo {
      flex: 23%;
    }
  }
  
  @media screen and (max-width: 991px) {
    .promo {
      flex: 49%;
    }
  }
  
  @media screen and (max-width: 576px) {
    .promo-btn-addtocart img{
      margin-right: 3px;
    }
    .promo {
      /* flex: 100%; */
        flex: 45% 0;
        padding: 5px;
        margin-right: auto;
    }
    .promo:last-child {
      display: none;
    }
    .add-to-chart-count-promotion-card{
      position: unset !important;
      top: 0 !important;
      left: 0 !important;
    }
    .promo-btn-addtocart-add-minus{
      height: 33px;
    }
    .promo-btn-wishlist img {
      width: 12px;
      height: 12px;
    }
    .promo-btn-wishlist-active img {
      width: 15px;
      height: 15px;
    }
    .promo:last-child{
      display: none;
    }
    .promo-price-rt {
      flex: 100%;
      text-align: right;
      height: 100%;
    }
    .promo-price-rt {
      flex: 100%;
      text-align: unset;
      height: unset;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .promo-price {
      flex-direction: column;
  }
  .promo-price-rt h4 {
    font-size: 14px;
    font-weight: 700;
    color: #ef3145;
    line-height: unset;
}
.promo-price-rt h5 {
  font-size: 16px;
  font-weight: 400;
  color: #989ca6;
  line-height: unset;
}
.arror-image-style {
  margin-left: 6px;
  margin-bottom: 5px;
  font-size: 11px;
  width: 8px;
}
  }
  
  .second-section {
    margin-top: 10px;
  }
  
  .newitem-card .carousel-indicators {
    display: flex;
    flex-direction: row;
    gap: 10px;
    bottom: -13%;
    left: 5%;
  }
  
  .newitem-card .carousel-indicators button.active {
    background: #61a60e;
    border: none;
    height: 10px;
    width: 22px;
    border-radius: 17px;
  }
  
  .newitem-card .carousel-indicators button {
    border: none;
    background: #D3D6DA;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    gap: 10px;
  }