/* Desktop */
.padZero{
    padding: 0 !important;
}
.position-relative{
    position: relative;
}
.cursor-pointer{
    cursor: pointer;
}
.text-lowerCase{
    text-transform: lowercase;
}
.text-upperCase{
    text-transform: uppercase;
}
.text-capitalize{
    text-transform: capitalize;
}
.backBtn{
    font-size: 20px;
    outline: none;
    border: none;
    color: #464B55;
    background-color: transparent;
    text-align: left;
    padding: 0;
    background-image: url(../../public/images/BackBtn.svg);
    background-size: 7px;
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 15px; 
}
.shopByBrand-slider-section .sbb-carousel .gray-line-shop-brand {
    left: 150%!important;
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
    .bg-menu ul li {
        padding: 0 10px 10px;
    }
    .my-cart-product-content-heading-product-name {
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .my-cart-left-container-padding {
        padding-left: 40px!important;
    }
    .main-table-section {
        padding: 112px 46px 100px 0px;
    }
    .search-bar-desktop-main-container .search-bar-desktop-custom-container {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 0px 0px 16px 16px;
        padding: 22px 80px 24px 80px;
        position: absolute;
        top: 0px;
        overflow-y: auto;
    }
    .shopByBrand-slider-section .sbb-carousel .gray-line-shop-brand {
        left: 125%!important;
    }
    .my-order-main-container .my-order-main-right-container {
        padding: 0px 50px 0px 50px!important;
    }
    .my-order-details-main-container .my-order-details-inner-container {
        padding: 48px 50px 40px 50px;
    }
    .search-bar-desktop-main-container .search-bar-desktop-custom-container {
        padding: 22px 80px 24px 80px;
    }
}
@media screen and (max-width: 1200px){
    .gray-line-shop-brand{
        left: 100%!important;
     }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .my-order-main-container .my-order-main-right-container {
        padding: 0px 50px 0px 50px!important;
    }
    .all-product-add-to-cart-button .all-products-add-minus-cursor {
        cursor: pointer!important;
        width: 28px!important;
        height: 28px!important;
    }
    .css-zw3mfo-MuiModal-root-MuiDialog-root {
        z-index: 99999 !important;
        right: 0px !important;
        top: 0px !important;
    }
    .main-container-scroller-design {
        display: block;
    }
    .top-banner-section .all-product-text {
        font-size: 22px;
        top: 38%;
    }
    .product-category-card-image {
        width: 145px!important;
        height: 145px!important;
        object-fit: contain!important;
    }
    .added-to-card-button {
        margin-left: 10px;
    }
    .all-product-add-to-cart-button .all-products-add-minus-cursor {
        cursor: pointer!important;
        width: 20px;
        height: 20px;
    }
    .add-minus-button {
        padding: 4px 12px 4px 4px;
        margin-left: 10px;
    }
}

/* Tablet landscape */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main-container-scroller-design {
        display: none;
    }
    /* .added-to-my-card {
        display: none!important;
    } */
    .product-category-card-image {
        width: 175px!important;
        height: 175px!important;
        object-fit: cover;
    }

    .top-banner-section .all-product-text {
        font-size: 22px;
        top: 38%;
    }
    .category-slider-mobile-view {
        display: none;
    }
    .category-slider-desktop-view {
        display: block!important;
    }
    .category-carousel-inner-container-mobile .carousel-control-next {
        position: absolute!important;
        top: -304px!important;
        bottom: 0!important;
    }
    .category-carousel-inner-container-mobile .carousel-control-prev {
        position: absolute!important;
        top: -304px!important;
        left: 75%!important;
    }
}

/* Tablet */
@media screen and (min-width: 567px) and (max-width: 768px) {

    .main-container-scroller-design {
        display: none;
    }

    .stricky-top {
        display: none;
    }

    .top-banner-section .all-product-text {
        font-size: 22px;
        top: 38%;
    }
    .mobile-view-main-container {
        display: block!important;
        margin-top: 15px!important;
    }
    .open-modal-main-container {
        display: block;
    }
    .clear-text-heading {
        left: 262px !important;
        top: 10px !important;
    }
    .category-slider-mobile-view {
        display: none;
    }
    .category-slider-desktop-view {
        display: block!important;
    }
    .category-carousel-inner-container-mobile .carousel-control-next {
        position: absolute!important;
        top: -304px!important;
        bottom: 0!important;
    }
    .category-carousel-inner-container-mobile .carousel-control-prev {
        position: absolute!important;
        top: -304px!important;
        left: 75%!important;
    }
    
}
@media screen and (min-width: 991px) {
    .hidden-md{
        display: block;
    }
    .visible-md{
        display: none;
    }
}
@media screen and (max-width: 991px) {
    .hidden-md{
        display: none;
    }
    .visible-md{
        display: block;
    }
}
@media screen and (min-width: 767px) {
    .visible-sm{
        display: none !important;
    }
    .hidden-sm{
        display: block !important;
    }

}
@media screen and (max-width: 767px) {
    .visible-sm{
        display: block !important;
    }
    .hidden-sm{
        display: none !important;
    }
    .landing-page-top-banner-image {
        height: 100%!important;
    }
}
@media screen and (min-width: 567px) {
    .visible-xs{
        display: none !important;
    }
    .hidden-xs{
        display: block !important;
    }

}
/* Phone landscape & smaller */
@media screen and (max-width: 567px) {
    .visible-xs{
        display: block !important;
    }
    .hidden-xs{
        display: none !important;
    }

    .stricky-top {
        display: none;
    }

    .main-container-scroller-design {
        display: none;
    }

    .top-banner-section .all-product-text {
        font-size: 28px!important;
        top: 35%!important;
    }
    .all-product-inner-section {
        margin-top: 0px!important;
    }
    .all-product-heading{
        font-size: 24px!important;
    }
    .product-count-text {
        font-size: 16px!important;
    }
    .sort-by-container{
        display: none!important;
    }
    .all-prouduct-card-shadow{
        width: 100%!important;
    } 
    .product-category-card-image {
        width: 142px!important;
        height: 120px!important;
    }
    .all-prouduct-card-shadow {
        padding: 8px;
    }
    .pagination-main-container .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
        margin-top: 10px;
    }
    .mobile-view-main-container {
        display: block!important;
        margin-top: 15px!important;
    }
    .open-modal-main-container {
        display: block;
        overflow: auto;
        overflow-x: hidden;
    }
    .clear-text-heading {
        position: absolute;
        left: 265px !important;
        top: 10px !important;
    }
    .out-of-stock-main-container{
        min-height: 0px;
    }
    .inner-heading-accodion {
        margin-left: 4px!important;
        margin-top:2px!important;
        color:#464B55!important;
    }
    /* Landing-page */
    .header-container {
        overflow: hidden!important;
    }
    .gray-line-shop-brand{
        display: none!important;
    }
    .gray-line-shop-brand {
        display: none;
    }
    .sbb-carousel .sbb-carousel-item{
        padding: 5px!important;
    }
    .shopByBrand-slider-section .kVtMtA {
      left: -45px!important;
      display: block!important;
    }
    .shopByBrand-slider-section .bhRnqO{
        right: -45px!important;
        display: block!important;
    }
    .shopByBrand-slider-section .sbb-carousel {
        width: 60%!important;
    }
    .product-category-heading {
        font-size: 24px!important;
    }
    .promoparent {
        padding-top: 2px!important;
        padding-bottom: 22px!important;
        padding-left: 8px!important;
        padding-right: 8px!important;
        background: transparent !important;
        border-radius: 16px!important;
    }
    .promohead-lt img {
        width: 28px!important;
        margin-right: 5px!important;
    }
    .promohead-lt h4 {
        font-size: 24px!important;
        margin-top: 4px;
        margin-left: 4px;
    }
    .promo-img-badge {
        position: absolute!important;
        top: 12px!important;
        left: 0px!important;
    }
    .newitem-wraper {
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    .new-items-heading {
        font-size: 24px!important;
    }
    .promotion-card .carousel-indicators {
        bottom: -43px!important;
    }
    .promohead {
        margin-bottom: 15px!important;
        margin-top: 15px!important;
    }
    .promo-btn {
        margin-bottom: 30px!important;
        align-items: center!important;
    }
    .newitem-card .carousel-indicators {
        bottom: -43px!important
    } 
    .product-review-container {
        margin-top: 22px!important;
    }
    .pr-carousel-Head {
        display: flex!important;
        flex-wrap: wrap!important;
        align-items: left!important; 
        flex-direction: row!important; 
        padding: 0px!important;
    }
    .pr-carousel-Head h4 {
        font-size: 24px!important;
    }
    .pr-carousel-Head img {
        width: 24px!important;
        margin-right: 8px!important;
    }
    .pr-carousel-item {
        padding: 12px!important;
        background: #FFFFFF!important;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 8%)!important;
        width: 100%!important;
        background-color: #fff!important;
        margin-top: 14px!important;
        border-radius: 15px!important;
        margin: 10px 0px 10px 0px!important;
    }
    /* Lets'go section start from here */
    .shopByBrand-slider-section {
        position: relative!important;
        left: 0px!important;
        top: -37px!important;
    }
    .letsShop {
        padding: 8px!important; 
        border-radius: 15px!important;
    }
    .letShopheader h1 {
        font-size:  22px!important;
        text-align: center!important;
        margin-top: 37px!important;
        width: 100%!important;
        font-weight: 500!important;
        font-family: "DB Helvethaica X MEDIUM"!important;
    }
    .letshopText {
        font-size: 18px!important;
        font-family: "DB Helvethaica X AG"!important;
    }
    .letShopheader button {
      display: none!important;
    }
    .card-text:last-child {
        margin-bottom: 0;
        width: 100%;
    }
    .card-body {
        padding: 0px!important;
    } 
    .find-out-button-mobile-view {
        display: block!important;
        text-align: center!important;
        padding-top: 15px!important;
        padding-bottom: 15px!important;
    }
    .find-out-button-mobile-view button {
        width: 50%!important;
        background: #FFFFFF!important;
        border-radius: 80px!important;
        color: #61A60E!important;
        padding: 10px!important;
        border: none!important;
        margin-top: 32px!important;
        font-size: 20px;
        text-transform: inherit;
    }
    .find-out-more-mobile-view span {
        margin-left: 9px;
    }
    .letShopImage {
        margin-top: 28px!important
    }
    .clear-text-heading {
        font-size: 16px!important;
    }
    /* Lets'go section end */
    /* Category-section start from here */
    .category-slider-section {
        display: inline!important;
    }
    .category-slider-image-section .slider-card {
        margin-left: 2px!important;
    }
    .category-slider-image-section {
        display: inline-block!important;
    }
    .category-slider-image-section .carousel-item {
        display: flex!important;
        margin-top: 10px!important
    }
    .CatagoryComponent_group__7iqem {
        height: 65px!important;
        width: 65px!important;
    }
    .slider-card-heading a {
        font-weight: 500!important;
        font-size: 16px!important;
        line-height: 20px!important;
    }
    .main-container-hero-banner {
        margin-top: 10px!important
    }
    .product-counts-main-mobile {
        display: block;
    }
    .show-heading-mobile {
        display: none;
    }
    .inner-heading-accodion span {
        position: absolute!important;
        bottom: -2px!important;
        left: 34px!important;
    }
    /* Category-section end */
    /* Search-bar-section start from here */
    .css-1hju3x6-MuiPaper-root-MuiDialog-paper {
        margin: 0px!important
    }
    .css-1y6pnir {
        margin: 0px!important
    }
    .CancelSearchScreen {
        position: absolute;
        top: 15px;
        float: right;
        right: 14px;
    }
    .SearchBarContainer {
        width: 90%!important;
    }
    .product-lbl-name {
        font-size: 16px!important;
        margin-left: 4px!important
    }
    .promo-btn-addtocart {
        padding: 2px!important;
        /* height: 28px!important; */
        height: 32px !important;
    }
    .promo-btn-wishlist {
        /* height: 25px!important;
        width: 25px!important; */
        height: 33px!important;
        width: 33px!important;
    }
    .search-bar-autocomplete .css-1omv4fy-MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: 0px!important;
    }
    .search-bar-autocomplete .css-18hxdr3 .MuiOutlinedInput-root {
        padding: 0px!important;
    }
    .search-bar-autocomplete .css-1omv4fy-MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: 0px!important;
    }
    /* Search-bar-section end */
    /* my-cart-section start from here */
    .my-cart-mobile-view{
        display: none;
    }
    .my-cart-desktop-view{
        display: block!important;
    }
    .category-slider-mobile-view {
        display: none;
    }
    .category-slider-desktop-view {
        display: block!important;
    }
    .category-carousel-inner-container-mobile .carousel-control-next {
        position: absolute!important;
        top: -213px!important;
        bottom: 0!important;
    }
    .category-carousel-inner-container-mobile .carousel-control-prev {
        position: absolute!important;
        top: -218px!important;
        left: 72%!important;
    }
    .my-cart-main-container {
        padding-top: 0px!important;
        padding-bottom: 0px!important;
    }
    .my-cart-table-inner-padding {
        padding-top: 0px!important;
    }
    .my-cart-table-plus-minus-padding {
        padding-top: 0px!important
    }
    .my-card-table-all-product-content {
        margin-top: 2px!important;
    }
    .my-cart-summary-section {
        background: #fff!important;
        margin-top: 16px!important;
    }
    .my-cart-stricky-right-section {
        background: #F5F6F7;
        border-radius: 12px;
    }
    .my-cart-appy-button-container {
        margin-top: 8px!important;
    }
    .my-cart-dropdown .css-gcwvw8-MuiAutocomplete-root {
        width: 100%!important;
    }
    .my-cart-dropdown .css-bvz20t {
        width: 100%!important;
    }
    .my-cart-dropdown {
        overflow: hidden!important;
    }
    .my-cart-alert-image {
        position: absolute;
        right: 6px!important;
        top: 2px;
    }
    .my-cart-vertical-line {
        height: 70px!important;
    }
    .css-zw3mfo-MuiModal-root-MuiDialog-root {
        right: 0px!important;
        top: 0px!important;
    }
    .quantity-out-of-stock-container {
        padding-top: 4px!important;
    }
    .my-cart-product-content-heading {
        text-align: center!important;
        margin-left: 6px;
    }
    .my-cart-product-content-heading-product-name {
        text-align: left;
        width: 92%;
        margin-left: 6px;
    }
    /* my-cart-section end */
    .css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root {
        padding: 4px 16px!important;
    }
    .CancelSearchScreen {
        font-size: 20px;
        font-weight: 600;
    }
    .search-bar-product-image {
        height: 72px;
        width: 72px;
    }
    .search-bar-padding-container {
        padding-bottom: 20px;
    }
    .css-1hju3x6-MuiPaper-root-MuiDialog-paper {
        border-radius: 0px 0px 12px 12px!important;
    }
    .css-1y6pnir {
        border-radius: 0px 0px 12px 12px!important;
    }
    .stock-field-in {
        display: none;
    }
    .product-lbl {
        font-size: 12px!important;
    }
    .price-field {
        position: relative!important;
        top: 18px!important;
    }
    .css-1hju3x6-MuiPaper-root-MuiDialog-paper {
        position: absolute!important;
        top: 0px!important;
        margin: 0px!important;
    }
    .css-1y6pnir {
        position: absolute!important;
        top: 0px!important;
        margin: 0px!important;
    }
    .makeStyles-root-5 {
        min-height: auto!important;
    }
    .jss1 {
        min-height: auto!important;
    }
    .css-126xj0f {
        right: 0px!important;
    }
    .added-to-modal-left-section {
        text-align: left!important;
    }
    .added-to-card-cross-icon {
        text-align: left!important;
    }
    .added-to-card-continue-shopping::after {
        border: 1px solid #61a60e;
        bottom: 5%;
        font-weight: 500;
        height: 1px;
        left: 36%;
        position: absolute;
        width: 29%;
        content: "";
        display: block;
        background-color: #61a60e;
    }
    .add-to-chart-count-search-bar {
        top: 0px!important;
        left: 10px!important;
    }
    .add-to-chart-count {
        left: 20px!important;
    }
    .search-bar-add-minus {
        padding: 4px!important;
    }
    .my-cart-delete-image-mobile {
        display: none!important;
        top: 14px;
        position: absolute;
        right: 34px;
    }
    .css-uhb5lp {
        margin: 0px 5px 0px 5px!important;
    }
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        margin: 0px 5px 0px 5px!important;
    }
    .css-ohyacs {
        padding: 16px 0px!important;
    }
    .thank-you-purchase-modal {
        position: absolute!important;
        bottom: 0!important;
        width: 100%!important;
        height: auto!important;
        background: #FFFFFF!important;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08)!important;
        border-radius: 24px 24px 0px 0px!important;
        text-align: center!important;
        padding: 37px 13px 16px 13px!important;
        left: 0;
        top: auto!important;
    }
    .all-product-padding-card {
        padding: 10px!important;
    }
    .card-product-name {
        min-height: 39px!important;
        font-size: 16px!important;
    }
    .common-display-none-mobile {
        display: none!important;
    }
    .common-display-block-mobile {
        display: block!important;
    }
    .out-of-stock-main-container {
        min-height: 0px!important;
    }
    .add-minus-button {
        margin-left: 6px!important;
    }
    .added-to-card-button {
        margin-left: 8px!important;
        font-size: 16px!important;
        padding: 6px 8px!important;
    }
    .all-product-whishlist-card-image {
        margin-right: 0px;
    }
    .out-of-stock-headings {
        font-size: 16px!important;
    }
    .all-product-add-to-cart-button .all-products-add-minus-cursor {
        cursor: pointer!important;
        width: 26px!important;
        height: 26px!important;
        margin-right: 5px!important;
    }
    .thank-you-purchase-modal {
        padding: 20px 10px 40px 10px;
    }
    .thank-you-purchase-modal-button {
        margin-top: 10px;
    }
    .thank-you-purchase-modal-heading {
        margin-top: 15px;
        font-size: 26px;
    }
    .my-account-hamburger-main-container {
        display: none!important;
    }
    /* My order section start from here */
    .my-order-main-container .my-order-main-right-container {
        padding: 0px 10px 20px 10px;
    }
    .my-order-main-container .my-order-right-main-container .my-orders-right-main-heading {
        font-size: 32px;
    }
    .my-order-main-container .my-order-right-main-container {
        padding: 20px 0px 0px 12px;
    }
    .my-order-tab-accordion-custom-container {
        display: none;
    }
    .my-order-back-button-main-container {
        padding: 20px 20px 0px;
        align-items: center!important;
    }
    .my-order-back-button-main-container .my-order-back-button-image {
        width: 9px;
        height: 14px;
    }
    .my-order-back-button-main-container .my-order-back-button-heading {
        font-weight: 400;
        font-size: 20px;
        margin-left: 10.5px;
        color: #464B55;
        margin-top: 4px;
    }
    .my-order-main-container .my-order-main-right-container {
        background: #fff!important;
    }
    .my-order-display-block-mobile {
        display: block!important;
    }
    .my-order-tab-search-bar-input {
        background-color: #F5F6F7;
        color: #989CA6;
    }
    .my-order-search-bar-margin-bottom {
        margin-bottom: 20px;
    }
    /* My order section end */
    /* My order details section start from here */
    .my-order-details-main-container .my-order-details-inner-container {
        padding: 20px 20px 20px 20px;
    }
    .my-order-details-product-information-main-content {
        display: none!important;
    }
    .my-order-details-product-info-gray-line {
        display: none;
    }
    .my-order-details-product-information-section {
        padding: 16px 20px 16px 16px;
    }
    .my-order-details-product-information-data .my-order-details-product-information-heading {
        font-size: 16px;
        font-weight: 600;
    }
    .my-order-details-product-information-unit-price {
        font-size: 16px;
        float: right;
    }
    .my-order-details-product-information-quatity-value {
        font-weight: 500;
        font-size: 16px;
        color: #989CA6;
        float: right;
        position: absolute;
        right: 16px;
        top: 16px;
    }
    .my-order-details-section-min-height {
        min-height: 100%!important;
    }
    .my-order-details-section-min-height {
        padding: 16px 16px 16px 16px;
    }
    .my-order-details-payment-custom-section .my-order-details-payment-custom-heading-left {
        font-size: 16px;
        margin-top: 0px;
    }
    .my-order-details-payment-custom-section .my-order-details-payment-custom-heading-right {
        font-size: 16px;
        margin-top: 0px;
    }
    .my-order-details-payment-custom-section .my-order-details-payment-custom-total-heading {
        font-size: 20px;
        margin-top: 12px;
    }
    .my-order-details-payment-custom-section .my-order-details-payment-custom-amount-heading {
        font-size: 20px;
        margin-top: 12px;
    }
    .my-order-details-payment-inner-section .my-order-details-payment-heading {
        font-size: 20px;
    }
    .my-order-details-shipping-summary-section .my-order-details-shipping-summary-section-heading {
        font-size: 20px;
    }
    .my-order-details-shipping-summary-section .my-order-details-shipping-summary-left-heading {
        font-size: 16px;
    }
    .my-order-details-shipping-summary-section .my-order-details-shipping-summary-right-heading {
        font-size: 16px;
    }
    .my-order-details-product-information-inner-section .my-order-details-product-information-main-heading {
        font-size: 20px;
    }
    .my-order-details-cancellation-inner-section .my-order-details-cancellation-main-heading {
        font-size: 20px;
    }
    .my-order-details-cancellation-section {
        padding: 16px;
    }
    .my-order-details-cancelled-cancel-button {
        float: none;
    }
    .my-order-details-main-container .my-order-details-main-container-breadcrumb-heading {
        font-size: 16px;
    }
    .my-order-details-main-container .my-order-details-main-container-breadcrumb-image {
        width: 8px;
        height: 12px;
    }
    .my-order-details-inner-container .my-order-details-main-container-main-heading {
        font-size: 32px;
    }
    .my-order-details-reorder-button-inner-container {
        font-size: 16px;
        width: auto;
        position: absolute;
        right: 14px;
        padding: 4px 24px;
        top: 10px;
        height: auto;
    }
    .my-order-details-inner-container .my-order-details-status-order-number {
        font-size: 16px;
    }
    .my-order-details-inner-container .my-order-details-status-order-number {
        height: 12px;
        width: 12px;
    }
    .my-order-details-inner-container .my-order-details-status-calendar-image {
        height: 12px;
        width: 12px;
    }
    .my-order-details-inner-container .my-order-details-status-image {
        margin-top: 0px;
        margin-bottom: 6px;
    }
    .my-order-details-inner-container .my-order-details-status-order-number {
        margin-left: 4px;
        margin-right: 0px;
    }
    .my-order-details-inner-container .my-order-details-status-calendar-image {
        margin-bottom: 9px;
    } 
    .my-order-details-inner-container .my-order-details-status-order-date {
        margin-left: 4px;
        margin-right: 0px;
        margin-top: 0px;
    }
    /* order-placed order details status */
    .my-order-details-inner-container .my-order-details-order-placed-status-button {
        padding: 0px;
        width: auto;
        height: auto;
        background: #FEF7EB;
        border: 1px solid #F5B335;
        border-radius: 40px;
        text-align: center;
        margin-right: 16px;
    }
    .my-order-details-order-placed-status-button .my-order-details-order-placed-status-heading {
        font-weight: 500;
        font-size: 16px;
        color: #F5B335;
        margin-right: 3px;
    }
    /* comfirmed order details status */
    .my-order-details-order-confirmed-status-button {
        padding: 0px;
        width: auto;
        height: auto;
        background: #E9F8FB;
        border: 1px solid #23B5D3;
        border-radius: 40px;
        text-align: center;
        margin-right: 16px;
    }
    .my-order-details-order-confirmed-status-button .my-order-details-order-confirmed-status-heading {
        font-weight: 500;
        font-size: 16px;
        color: #23B5D3;
        margin-right: 3px;
    }
    /* completed order details status */
    .my-order-details-order-completed-status-button {
        padding: 0px;
        width: auto;
        height: auto;
        background:  #EFF6E7;
        border: 1px solid #61A60E;
        border-radius: 40px;
        text-align: center;
        margin-right: 16px;
        margin-bottom: 4px;
    }
    .my-order-details-order-completed-status-button .my-order-details-order-completed-status-heading {
        font-weight: 500;
        font-size: 16px;
        color: #61A60E;
        margin-right: 3px;
    }
    /* shipped order details status */
    .my-order-details-order-shipped-status-button {
        padding: 4px 12px;
        width: auto;
        height: 32px;
        background: #F3F2EB;
        border: 1px solid #8A803C;
        border-radius: 40px;
        text-align: center;
        margin-right: 16px;
    }
    
    .my-order-details-order-shipped-status-button .my-order-details-order-shipped-status-heading {
        font-weight: 500;
        font-size: 16px;
        color:  #8A803C;
        margin-right: 3px;
    }
    /* cancelled order details status */
    .my-order-details-order-cancelled-status-button {
        padding: 4px 12px;
        width: auto;
        height: 32px;
        background: #F5F6F7;
        border: 1px solid #989CA6;
        border-radius: 40px;
        text-align: center;
        margin-right: 16px;
    }
    .my-order-details-order-cancelled-status-button .my-order-details-order-cancelled-status-heading {
        font-weight: 500;
        font-size: 16px;
        color: #989CA6;
        margin-right: 3px;
    }
    .my-order-details-inner-container .my-order-details-status-order-date {
        font-size: 16px;
        margin-top: 2px;
        position: relative;
        top: 2px;
    }
    .my-order-details-inner-container .my-order-details-status-image {
        height: 12px;
        width: 12px;
    }
    .my-order-details-order-placed-status-image {
        width: 11px;
        height: 11px;
        margin-bottom: 2px;
    }
    .my-order-details-status-section-mobile {
        display: flex!important;
    }
    .my-order-details-inner-container .my-order-details-main-container-main-heading {
        margin-top: 0px;
    }
    .my-order-details-main-container {
        background-color: #fff;
    }
    .my-order-details-product-information-section {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    }
    .my-order-details-payment-section {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    }
    .my-order-details-shipping-summary-section {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    }
    /* Stepper-section css start from here */
    .my-order-details-stepper-section {
        padding: 18px;
        font-size: 18px;
        margin-top: 22px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    }
    .stepper-status-main-container .stepper-status-main-heading {
        font-size: 16px;
        font-weight: 600;
    }
    .my-order-display-block-mobile .css-1eouraq {
        width: 40px;
        height: 40px;
    }
    .my-order-display-block-mobile .css-1r9dhpa {
        width: 40px;
        height: 40px;
    }
    .my-order-display-block-mobile .css-bdw22m  {
        width: 40px;
        height: 40px;
    }
    .my-order-display-block-mobile .css-1r9dhpa {
        width: 40px;
        height: 40px;
    }
    .my-order-display-block-mobile .stepper-image-size {
        width: 12px;
        height: 16px;
    }
    .my-order-display-block-mobile .stepper-image-size-orderplace {
        width: 12px;
        height: 16px;
    }
    .my-order-display-block-mobile .stepper-image-size-orderconfirmed {
        width: 18px;
        height: 16px;
    }
    .my-order-display-block-mobile .stepper-image-size-orderstepper {
        width: 20px;
        height: 16px;
    }
    .my-order-display-block-mobile .stepper-image-size-ordercompleted {
        width: 16px;
        height: 16px;
    }
    .my-order-display-block-mobile .stepper-image-size-ordercancelled {
        width: 16px;
        height: 16px;
    }
    .stepper-status-main-container .stepper-status-inner-heading {
        top: 2px;
    }
    .my-order-display-block-mobile .css-1ggk197-MuiStepConnector-root {
        margin-left: 14px!important;
    }
    .my-order-display-block-mobile .css-1s1la6t {
        margin-left: 14px!important;
    }
    .my-order-display-block-mobile .css-r12ba4 {
        padding: 2px 0px;
    }
    .my-order-display-block-mobile .css-14sza3e-MuiStepLabel-root {
        padding: 2px 0px;
    }
    .my-order-display-block-mobile .css-8t49rw-MuiStepConnector-line {
        min-height: 20px;
    }
    /* Stepper-section end */
    .my-order-tab-menu .tabs-active-count {
        font-size: 16px;
    }
    .my-order-search-order-user-name-heading {
        font-size: 16px;
    }
    .my-order-search-order-user-image {
        height: 12px;
        width: 12px;
        margin-top: 4px;
    }
    .my-order-search-order-user-name-heading {
        margin-left: 6px;
    }
    .all-product-inner-section-heading {
        font-size: 32px;
    }
    .all-product-inner-section-inner-heading {
        font-size: 16px;
    }
    .no-result-found-main-container .no-result-found-image {
        height: 64px;
        width: 64px;
    }
    .my-order-details-stepper-section .css-knbt2k-MuiStepConnector-root{
        margin-left: 13px;
    }
    .my-order-details-stepper-section .css-1dhc1q3 {
        margin-left: 13px!important;
    }
    .my-order-details-articles-code {
        display: none;
    }
    .promohead-rt a {
        font-size: 16px!important;
    }
    /* My order details section end */
    .shopByBrand-slider-section .etbmwA {
        display: block;
    }
    .shopByBrand-slider-section .sbb-carousel {
        width: 248px!important;
    }
    .promo-btn-wishlist-active {
        height: 28px!important;
        width: 28px!important;
    }
    .my-cart-add-minus-background {
        width: 100%!important;
    }
    .price-banner-cross-icon {
        height: 14px!important;
        width: 14px!important;
        margin-top: 9px;
    }
    .my-cart-promo-input-mobile-view {
        width: 257px!important;
        height: 44px!important;
    }
    .my-cart-apply-disable-button-mobile-view {
        width: 100%!important;
        height: 44px!important;
    }
    .my-cart-promo-input-mobile-view {
        border-width: 1px 0px 1px 1px;
    }
    .clear-text-heading-mobile {
        right: 28px!important;
        text-align: end!important;
        margin-top: 3px;
    }
    .add-to-cart-modal-desktop-block {
        display: none!important;
    }
    .landing-page-top-banner-image {
        height: 100%!important;
    }
    .recommended-for-you-bg-bottom {
        margin-bottom: 24px!important;
    }
    .lets-betagro-background-image {
        background: url("../images/lets-betagro-cover-image-mobile.svg")!important;
        position: static!important;
        overflow: hidden!important;
        background-repeat: repeat!important;
        background-size: 100% 100%!important;
    }
    .letShopImage {
        height: 85px!important;
        width: 85px!important;
    }
    .thank-you-purchase-modal-image {
        height: 64px!important;
        width: 64px!important;
    }
    .thank-you-purchase-modal-button {
        font-size: 20px!important;
    }
    .thank-you-purchase-modal-paragraph {
        font-size: 16px!important;
    }
    .stepper-status-main-container .css-asnpbi {
        height: 40px!important;
        width: 40px!important;
    }
    .stepper-status-main-container .css-1rxvwiz {
        height: 40px!important;
        width: 40px!important;
    }
    .stepper-status-main-container .css-1bk97oi {
        height: 40px!important;
        width: 40px!important;
    }
    .common-header-margin-fixed {
        padding-top: 70px;
    }
    .thank-you-purchase-modal-paragraph .modal-order-number-id {
        font-size: 24px!important;
    }
    .product-review-desktop-main-container {
        display: none!important;
    }
    .product-review-mobile-main-container {
        display: block!important;
        margin-top: 20px!important
    }
    .product-review-container-mobile-screen {
        padding: 0!important;
    }
    .dbd-company-logo {
        left: 2px;
    }
    .footer-terms {
        padding-bottom: 0px;
    }
    .my-cart-left-container-padding {
        padding-left: 0px!important;
    }
    .add-email-address-icon-main-container {
        padding-top: 0px!important;
    }
    .out-of-stock-container {
        padding: 1px 6px!important;
    }
}