.thank-you-purchase-modal-inner-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 999;
    position: fixed;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    opacity: 1 !important;
    background-color: rgba(24, 27, 33, 0.4) !important;
    height: 100%;
}

.thank-you-purchase-modal {
    width: 576px;
    height: auto;
    left: calc(50% - 576px/2);
    top: calc(50% - 444px/2);
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    text-align: center;
    padding: 48px;
}

.thank-you-purchase-modal-image {
    width: 88px;
    height: 88px;
}

.thank-you-purchase-modal-heading {
    color: #464B55;
    font-weight: 500;
    font-size: 48px;
    margin-top: 24px;
    font-family: "DB Helvethaica X 65 Med"!important;
}

.thank-you-purchase-modal-paragraph {
    font-weight: 400;
    font-size: 32px;
    color: #464B55;
    margin-top: 8px;
    font-family: "DB Helvethaica X 55 Regular"!important;
}
.thank-you-purchase-modal-paragraph .modal-order-number-id {
    font-weight: bolder;
    font-size: 32px;
    color: #464B55;
    margin-top: 8px;
}
.thank-you-purchase-modal-main-heading {
    font-weight: 400;
    font-size: 32px;
    color: #464B55;
    margin-top: 8px;
}

.thank-you-purchase-modal-inner-paragraph {
    font-size: 20px;
    font-weight: 400;
    color: #464B55;
    margin-top: 16px;
    font-family: "DB Helvethaica X 55 Regular"!important;
}

.thank-you-purchase-modal-button {
    outline: none;
    background-color: #61a60e;
    color: #fff;
    font-size: 24px;
    width: 100%;
    border-radius: 80px;
    justify-content: center;
    padding: 10px 24px;
    border: none;
    margin-top: 24px;
    text-align: center;
    cursor: pointer;
    font-family: "DB Helvethaica X REGULAR"!important;
}