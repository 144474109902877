.inputStyle {
  /* display: flex;
  justify-content: center;
  width: 3rem !important;
  height: 4rem !important;
  margin: 0 5px !important;
  font-size: 2rem !important;
  border-radius: 8px !important;
  border: 2px solid #EDEFF2 !important;
  background-color: #EDEFF2 !important; */
}

.otp-form-container-outer{
  caret-color: #61A60E;

}

.otpInputStyle {
  background-color: #EFF6E7 !important;
  border: 2px solid #EFF6E7 !important;
}

.otpInputStyleForError {
  background-color: #FFFFFF !important;
}

.inputStyle:focus {
  /* border: 0px solid #61A60E !important; */
  border: 2px solid #61A60E !important;
  background-color: #FFFFFF !important;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.08) !important;
  outline: none !important;
}

/* @media only screen and (max-width: 470px) {
  .margin-top--small .inputStyle {
    width: 2rem !important;
    height: 2rem;
    margin: 0 5px;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 470px) {
  .margin-top--small .otpInputStyle {
    width: 2rem !important;
    height: 2rem;
    margin: 0 5px;
    font-size: 1rem;
  }
} */

.error {
  border:  1.5px solid #EF3145 !important;
  background-color: #fff !important;
}
.otp-form-container-outer{
  display: flex;
}
.otp-form-container {
  width: 580px;
  background: #FFFFFF;
  box-shadow: 0px 4px 80px rgb(0 0 0 / 8%);
  border-radius: 15px;
  margin: 60px auto;
  padding: 30px;
}

.otp-form-container-headLogo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
  margin-bottom: 30px;
}

.otp-form-container-headLogo img {
  width: 220px;
}

.otp-form-container h2 {
  font-family: "DB Helvethaica X 65 Med" !important;
  font-size: 56px;
  font-weight: 500;
  color: #464B55;
}

.otp-form-container label {
  font-family: "DB Helvethaica X 55 Regular" !important;
  font-size: 20px;
  color: #464B55;
    font-weight: 400;
    margin: 0px 0px 45px 0px;
}

.pass-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 16px;
  gap: 12px;
  background-color: #F5F6F7;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  /* min-height:"70px !important"; */
}

.otp-form-container-form label{
  font-size: 16px;
  color: #464B55;
}

.otp-form-container-form input{
  text-align: center;
}

.title-lbl {
  color: #464B55;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.fade {
  opacity: 1;
  background-color: #fff;
}

.alert-error-lbl {
  font-family: "DB Helvethaica X 55 Regular" !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EF3145;
  background-color: "#fff";
}

.otp-title {
  font-weight: 600;
  font-size: 24px;
}

.not-recieve-otp {
  text-align: center;
}

.not-recieved-text {
  text-align: center;
  margin-top: 15px;
  font-size: 20px;
}

.resend-text {
  color: #61A60E;
  margin-left: 2px;
  border-bottom: 1px solid #61A60E;
  cursor: pointer;
}

.enterOtp_btn {
  margin: 20px 0;
}

.enterOtp_btn button,
.enterOtp_btn button:hover,
.enterOtp_btn button:visited {
  font-family: "DB Helvethaica X 55 Regular" !important;
  width: 100%;
  background-color: #61A60E !important;
  height: 56px;
  border-radius: 30px !important;
  margin-top: 25px;
  text-transform: none !important;
  font-size: 24px;
  color: #fff !important;
  outline: none;
  border: 1px solid #61A60E;
  cursor: pointer;
}

.enterOtp_btn button:disabled {
  background: #EDEFF2;
  color: #989CA6;
  border: 1px solid #EDEFF2;
}
.otp-form-otpSection{
}
.otp-form-otpSection div{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.otp-form-container .alert-danger {
  background-color: transparent;
  border: none;
}
.otp-form-container .alert-heading{
color:#EF3145;
display: flex;
align-items: center;
justify-content: center;

}
.otp-form-container .alert-heading svg{
  width: 13px;
  height: 13px;
  margin-right: 3px;
}
@media screen and (max-width: 991px) {
  .otp-form-container {
    margin: 40px auto;
  }

  .otp-form-container h2 {
    font-size: 50px;

  }
  .otp-form-container-headLogo{
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .otp-form-container-headLogo{
    display: none;
  }
  .otp-form-container {
    width: 100%;
    margin: 0;
    padding: 20px 20px 10px;
    box-shadow: none;
    border-radius: unset;
  }

  .otp-form-container h2 {
    font-size: 40px;

  }
  .otp-form-container-headLogo{
    margin-bottom: 0px;
  }
  .enterOtp_btn button,
.enterOtp_btn button:hover,
.enterOtp_btn button:visited {
  height: 48px;
  font-size: 20px;
}


.otp-form-container label {
  margin: 0px 0px 30px 0px;
}
.enterOtp_btn button, .enterOtp_btn button:hover, .enterOtp_btn button:visited{
  margin-top: 10px;
}

}