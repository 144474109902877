/* (A) HIDE CHECKBOX */
.togCheck { display: none; }

/* (B) HIDE CONTENT BY DEFAULT */
.togContent {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: max-height 1s;
}

/* (C) SHOW CONTENT WHEN CHECKED */
.togCheck:checked + .togContent {
  max-height: 100vh; /* any insanely large number if a lot of contents */
  opacity: 1;
  visibility: visible;
}

/* (X) COSMETICS - DOES NOT MATTER */
/* (X1) ENTIRE DEMO */


/* (X2) TOGGLE BUTTON */
.togButton {
  display: block;
  width: 10%;
  padding: 10px;
  color: #fff;
  background:#fff;
  cursor: pointer;
}

/* (X3) CONTENT */
.togContent { background: #fff; }
.togContent p {
  padding: 50px 10px;
  margin: 0;
}

.icon-wrapper:hover {
  padding: 20px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(8, 234, 38);
  background-color: #FFFFFF;
  align-items: center;
  image-rendering: crisp-edges;
  max-height: 10px;
  max-width: 300px;
  align-content: center;
}



.icon-wrapper {
  padding: 20px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(231, 239, 232);
  background-color: #FFFFFF;
  align-items: center;
  image-rendering: crisp-edges;
  max-height: 10px;
  max-width: 300px;
  align-content: center;
}





