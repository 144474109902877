@import "../index.css";
* {
    box-sizing: border-box;
    font-family: 'DB Helvethaica X 55 Regular';
}

.product-section-dropdown{
  width: 40%!important;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08)!important;
  height: 100%!important;
  padding: 6px!important;
}
.promowraper{
  padding: 32px;  
}
.common-margin{
  margin-left: 10px;
  margin-right: 10px
}

.promo-price-lt p:hover{
  color: #61A60E
}
.promo-price-lt span:hover{
  color: #61A60E
}

.promoparent {
  padding-top: 52px;
  padding-bottom: 52px;
  padding-left: 65px;
  padding-right: 65px;
  background: #F5F6F7;
  border-radius: 16px;
}
.promotion-card .carousel-control-next-icon, 
.promotion-card .carousel-control-prev-icon{
  display: none !important;
}
.promohead {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.promohead-lt {
  flex: 50%;
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.promohead-lt img {
  width: 50px;
  margin-right: 20px;
}

.promohead-lt h4 {
  font-size: 56px;
  font-weight: 500;
  font-family: "DB Helvethaica X 65 Med"!important;
  color: #464B55;
}

.promohead-rt {
  flex: 50%;
  text-align: right;
  height: 100%;
}

.promohead-rt a,
.promohead-rt a:hover,
.promohead-rt a:visited,
.promohead-rt a:focus {
  color: #61A60E;
  font-size: 24px;
  text-decoration: none;
  border-bottom: 1px solid #61A60E;
}

.promotions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* height: 100%; */
  position: relative;
  flex-direction: row;
}

.promo {
  /* flex: 23% 1; */
  /* flex-shrink: 0; */
  flex: 0 0 200px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #ddd;
  margin: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-top: 8px;
  background: #FFFFFF;
  /* height: auto; */
  position: relative;
  /* height: auto !important; */
}

.promo-img {
  position: relative;
  text-align: center;
  margin-bottom: 10px;
  height: 150px;
  width: 100%;
  cursor: pointer !important;
}

.promo-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.promo-img-badge {
  position: absolute;
  top: 5px;
  left: -2px;
}

.promo-img-badge img {
  width: 90px;
}

.promo-price {
  display: flex;
  margin-bottom: 10px;
  
}

.promo-price-lt {
  text-align: left;
  height: 20%;
}

.promo-price-rt {
  flex: 50%;
  text-align: right;
  height: 100%;
}

.promo-price-lt p {
  font-size: 18px;
  color: #464b55;
  font-weight: 600;
  font-family: 'DB Helvethaica X 55 Regular'!important;
}
.promo-price-lt span {
  font-size: 18px;
  font-weight: 500;
  font-family: "DB Helvethaica X 65 Med";
  cursor: pointer !important;
  line-height: 1;
  color: #464B55;
}

.promo-price-rt h4 {
  font-size: 14px;
  font-weight: 700;
  color: #ef3145;
}

.promo-price-rt h5 {
  font-size: 16px;
  font-weight: 400;
  color: #989ca6;
  line-height: 1;
}

.promo-desc {
  margin-bottom: 10px;
}

.promo-desc select {
  font-size: 18px;
  background-color: #f5f6f7;
  border-radius: 32px;
  height: 36px;
  padding: 6px 12px 6px 16px;
  color: #464b55;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url(../../public/DownArrow.svg);
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: 22px;
  padding-right: 40px;
  width: 100%;
  border: none;
  outline: none;
}

.promo-btn {
  display: flex;
}

.promo-btn-lt {
  flex: 5%;
}

.promo-btn-rt {
  flex: 95%;
}

.promo-btn-wishlist {
  outline: none;
  border: none;
  background-color: #f5f6f7;
  text-align: center;
  font-size: 13px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.promo-btn-wishlist img {
  width: 16px;
  height: 16px;
}

/* .promo-btn-addtocart {
  outline: none;
  border: none;
  color: #fff;
  background-color: #61a60e;
  text-align: center;
  font-size: 13px;
  padding: 8px 18px!important;
  border-radius: 20px;
  width: 100%;
  margin: auto;

} */

.add-minus-icon{
  width: 26px !important;
}

.promo-btn-addtocart img {
  margin-right: 10px;
  width: 18px;
  height: 16px;
  margin-bottom: 3px;
}
.promo-btn-right, .add-to-cart-container {
  width: 80%;
  margin-left: 1%;
}
.promo-btn-addtocart{
  height: 36px;
}

@media screen and (max-width: 1100px) {
  .promo {
    flex: 23%;
  }
}

@media screen and (max-width: 991px) {
  .promo {
    flex: 49%;
    /* flex: 30%; */
  }
}

@media screen and (max-width: 576px) {
  .promo-btn-addtocart img{
    margin-right: 3px;
  }
  .add-minus-icon{
    width: 23px !important;
  }
.promo {
  /* flex: 100%; */
    flex: 45% 1;
    padding: 5px;
    margin: 10px 2%;
}
.promo:last-child{
  display: none;
}
.promo-btn-wishlist img {
  width: 12px;
  height: 12px;
}
.promo-price-rt {
  flex: 100%;
  text-align: right;
  height: 100%;
}
.promo-price-rt {
  flex: 100%;
  text-align: unset;
  height: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.promo-price {
  display: flex;
  min-height: 24%;
  flex-direction: column;
}
.promo-price-rt h4 {
  font-size: 14px;
  font-weight: 700;
  color: #ef3145;
  line-height: unset;
}
.promo-price-rt h5 {
  font-size: 16px;
  font-weight: 400;
  color: #989ca6;
  line-height: unset;
}
}

.second-section {
  margin-top: 10px;
}

.promotion-card .carousel-indicators {
  display: flex;
  flex-direction: row;
  gap: 10px;
  bottom: -5%;
  left: 5%;
}
.promoparent .carousel-indicators button.active {
  background: #61a60e;
  border: none;
  height: 10px;
  width: 22px;
  border-radius: 17px;
}

.promoparent .carousel-indicators button {
  border: none;
  background: #D3D6DA;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  gap: 10px;
}
.promotion-main-container{
  margin-top: 38px;
  margin-bottom: 38px;
}
.arror-image-style {
  margin-left: 8px;
  margin-bottom: 10px;
  font-size: 16px;
}
.promotion-card-style{
  display: inline;
}
.promo-btn-addtocart-add-minus {
  outline: none;
  border: none;
  color: #fff;
  background-color: #61a60e;
  text-align: center;
  font-size: 13px;
  padding: 5px 0px;
  border-radius: 20px;
  width: 100%;
  margin: auto;
  height: 36px;
}
.add-to-chart-count-promotion-card {
  font-size: 20px;
  font-weight: 500;
  margin-top: 0px;
  /* position: absolute!important; */
  top: 0px!important;
  left: 25px!important;
}
.promotion-main-container .carousel img {
  pointer-events:auto!important;
}
.newitem-wraper .carousel img {
  pointer-events:auto!important;
}
