@import "../index.css";
* {
    box-sizing: border-box;
    font-family: 'DB Helvethaica X 55 Regular';
  }
.shopByBrand-slider-section{
    position: absolute;
    bottom: -150px;
    width: 100%;
}
.homepageHeroBanner .carousel-control-next {
  display: none!important;
}
.homepageHeroBanner .carousel-control-prev {
  display: none!important;
}
.landing-page-top-banner-image {
  height: 608px!important;
  margin: auto;
}