@import "../index.css";
* {
    box-sizing: border-box;
    font-family: 'DB Helvethaica X 55 Regular';
}
.label-card-font {
    font-family: 'DB Helvethaica X';
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;

    /* N60 */

    color: #464B55;
}

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 44px;

    position: absolute;
    width: 725px;
    height: 298px;
    left: 120px;
    top: 70px;
}

.hero-slide-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    width: 12px;
    height: 72px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.hero-slide-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 669px;
    height: 298px;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.label-text-bring {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: 669px;
    height: 114px;



    flex: none;
    order: 0;
    flex-grow: 0; */
}

.label-store-add {
    width: 669px;
    /* height: 144px; */

    /* Desktop/Display */

    font-family: 'DB Helvethaica X';
    /* font-style: italic; */
    font-weight: 500;
    font-size: 40px;
    /* line-height: 72px; */
    /* or 90% */

    display: flex;
    align-items: center;

    /* N60 */

    color: #464B55;

}

.label-store-discount {
    /* width: 286px; */
    /* height: 34px; */
    font-family: 'DB Helvethaica X';
    /* font-style: normal; */
    font-weight: 400;
    font-size: 16px;
    /* line-height: 34px; */
    display: flex;
    align-items: center;
    color: #464B55;

}

.show-now-btn {
    width: 209px;
}

/* banner section */


.banner-add-section {
    position: relative;
    width: 1100px;
    height: 120px;
    left: 75px;
    top: -61px;
    z-index: 999;
    box-shadow: 0px 0px 5px #464B55;
    border-radius: 100px;

}
.shopByBrand-slider-section{
    position: relative;
    left: 0px;
    top: -61px;
}
.CatagoryComponent_maskGroup__s9N7J {
    cursor: pointer!important;
}
.CatagoryComponent_maskGroup__lsT+s {
    cursor: pointer!important;
}

.abc {
    box-shadow: 5px 5px 5px #464B55;
}



.desktop-brand-btn3 {
    position: absolute;
    left: 66.67%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #FFFFFF;
    border-radius: 100px 0px 0px 100px;
}

.desktop-brand-btn4 {
    position: absolute;
    left: 33.33%;
    right: 33.33%;
    top: 0%;
    bottom: 0%;
    background: #FFFFFF;
}

.desktop-brand-btn5 {
    position: absolute;
    left: 0%;
    right: 66.67%;
    top: 0%;
    bottom: 0%;
    background: #FFFFFF;
    border-radius: 0px 100px 100px 0px;
}

.borderRadius {
    border-radius: 100px !important;
}

/* .homepageHeroBanner .carousel-control-prev,
.homepageHeroBanner .carousel-control-next {
    top: 503px !important;
    width: 6% !important;
} */

.homepageHeroBanner .carousel-indicators .active {
    background: #61A60E;
    border: none;
    width: 10px;
    height: 25px;
    border-radius: 17px;
}

.homepageHeroBanner .carousel-indicators button {
    border: none;
    background: #D3D6DA;
    border-radius: 100%;
    width: 14px;
    height: 14px;
}

.homepageHeroBanner {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.homepageHeroBanner .carousel-indicators {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 50px;
    left: -110px;
    z-index: 1 !important;
}

.pl-cat {
    /* padding-left: 111px; */
}
.slider-main-container{
    position: relative;
    background-color: white;
}
.categorybanner .carousel-control-prev {
    position: 'absolute'!important;
    left: 88%;
    top: -271px;
}

.slider-card-heading{
    text-align: center;
    margin-top: 12px;
    white-space: normal;
}
.slider-card-heading a{
    font-weight: 500 !important;
    font-size: 24px;
    color: #464B55!important;
    line-height: 32px;
    font-family: "DB Helvethaica X 65 Med"!important;
}
.categorybanner .carousel-control-next {
    position: 'absolute'!important;
    left: 92%;
    top: -271px;
}
.slider-card{
    margin-left: 44px!important;
}


.custom-label2 a, .custom-label2 a:hover, .custom-label2 a:focus, .custom-label2 a:visited{
color: #464B55;;
}

.homepageHeroBanner.carousel.slide .carousel-control-prev,
.homepageHeroBanner.carousel.slide .carousel-control-next{
    display: none;
}
.product-category-heading {
    font-size: 56px;
    font-weight: 500;
    color: #464B55;
    font-family: "DB Helvethaica X 65 Med"!important;
}

@media (min-width: 576px) {
    .categorybanner .col-sm-2 {
        flex: 0 0 16.666667%;
        max-width: 12.666667% !important;
    }
}
.category-carousel-inner-container-mobile {
    margin-top: 21px!important;
}
.slider-card-heading-min-height {
    min-height: 60px!important;
}
.category-slider-desktop-view {
    display: none;
}
.category-carousel-main-button-container-mobile .CatagoryComponent_maskGroup__lsT\+s {
    height: 72px!important;
    width: 72px!important;
    cursor: pointer!important;
}
.CatagoryComponent_maskGroup__lsT\+s {
    cursor: pointer!important;
}
.CatagoryComponent_group__tTfIK {
    cursor: pointer!important;
}
.category-carousel-main-button-container-mobile .CatagoryComponent_group__tTfIK {
    height: 72px!important;
    width: 72px!important;
}
.category-slider-image-section .carousel-item {
    min-height: 185px;
}