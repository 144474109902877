.myAccountHeadWishlist{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.myAccountWishListCard-product{
    display: flex;
    align-items: center;
}
.myAccountWishListCard-product1{flex:10%}
.myAccountWishListCard-product2{flex:20%}
.myAccountWishListCard-product3{flex:70%}
.myAccountWishlist-EmptyWishlistImg {
    width: 60px !important;
}

.myAccountWishlist-continueShop153pingBtn {
    padding: 10px 35px;
    color: #fff;
    background-color: #61A60E;
    border-radius: 25px;
    outline: none;
    border: 1px solid #61A60E;
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
}

.myAccountWishlist-EmptyWishlist {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 470px;
}

.myAccountWishListCard-wishlistBtn {
    display: flex;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid#EF3145;
    background-color: #EF3145;
    outline: none;
    margin-right: 10px !important;
    justify-content: center;
}

.myAccountWishListCard-product {
    display: flex;
    align-items: center;
}

.myAccountWishListCard-productImg {
    width: 72px;
    height: 72px;
    border: 1px solid #D3D6DA;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer !important;
}

.myAccountWishListCard-productImg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer !important;
}

.myAccountWishListCard-product p {
    color: #989CA6;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 1;
}

.myAccountWishListCard-product h3 {
    font-weight: 600;
    font-size: 20px;
    color: #464B55;
   padding-right: 10px;
   cursor: pointer;
   margin-bottom: 0;
   width: 100%;
}
.myAccountWishListCard-product h3:hover{
    color: #61A60E;
}

.myAccountWishListCard-productText{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: start;
}

.myAccountWishListCard {
    margin: 15px 0;
}

.myAccountWishListCard h4 {
    font-size: 16px;
    padding: 2px 8px;
    background: #EFF6E7;
    border-radius: 26px;
    color: #61A60E;
    text-align: center;
    display: unset;
    margin: auto;
}
.myAccountWishListCard h5 {
    font-size: 16px;
    padding: 2px 8px;
    background: #F5F6F7;
    border-radius: 26px;
    color: #989CA6;
    text-align: center;
    display: unset;
    margin: auto;
}

.myAccountWishListCard h6 {
    font-weight: 700;
    font-size: 20px;
    color: #464B55;
}

.myAccountWishListCard-AddToCartBtn {
    outline: none;
    border: 1px solid #61A60E;
    background: #61A60E;
    border-radius: 32px;
    padding: 0px 15px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    height: 36px;
    display: flex;
    align-items: center;
}

.myAccountWishListCard-AddToCartBtn img {
    width: 15px;
    margin-right: 5px;
}

.myAccountWishListCard-outofStock {
    font-size: 20px;
    font-weight: 400;
    border: 1px solid #EDEFF2;
    border-radius: 32px;
    padding: 0px 0px;
    color: #989CA6;
    background: #EDEFF2;
    height: 36px;
    min-width: 140px;
}

.myAccountWishListCard-outofStock img{
    width: 15px;
    margin-right: 5px;
}

.myAccountCardWishListHeight{
    /* max-height: 470px; */
    height: 470px;
    overflow-y: auto;
    overflow-x: hidden;
}
.myAccountWishListCard-productText-xs{
    display: none;
}
.myAccountWishlist-continueShoppingBtn{
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    outline: none;
    border: none;
    height: 44px;
background: #61A60E;
padding: 5px 25px;
border-radius: 30px;
}

@media screen and (max-width: 767px) {
    .myAccountHeadWishlist{
        display: unset;
    }
    .myAccountWishlist-EmptyWishlist {
        padding: 40px 0 180px;
        justify-content: flex-start;
        height: unset;
    }
    .myAccountCardWishListHeight{
        overflow-y: unset;
        overflow-x: hidden;
        height: unset;
    }
    .myAccountWishListCard-product p {
        font-size: 12px;
        line-height: 1;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    
    .myAccountWishListCard-product h3 {
        font-size: 18px !important;
        width: 100%;
        margin-bottom: 0;
        min-height: unset !important;
        line-height: 1;
        margin-bottom: 5px;
    }
    .myAccountWishListCard h6{
        font-size: 20px;
        margin-bottom: 0;
        line-height: unset;
    }
    .myAccountWishListCard-AddToCartBtn {
        font-size: 16px;
        height: 32px;
    }
    
    .myAccountWishListCard-AddToCartBtn img {
        width: 12px;
        margin-right: 3px;
    }
    
    .myAccountWishListCard-outofStock {
        font-size: 16px;
        height: 32px;
    }
    .myAccountWishListCard-outofStock img{
        width: 12px;
        margin-right: 5px;
    }
    .myAccountWishListCard-productText{
        width: 100%;
    }
    .myAccountWishListCard-productText-xs{
        width: 100%;
        display: flex;
        align-items: end;
        justify-content: space-between;
    }
    .myAccountWishlist-continueShoppingBtn {
        height: 40px;
        background: #61A60E;
        padding: 5px 25px;
    }
}

@media screen and (max-width: 576px) {
    .myAccountHeadWishlist{
        display: unset;
    }
}