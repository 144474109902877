.orderSummary {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
}

.orderSummaryConfirmBtn {
    font-size: 20px;
    font-weight: 400;
    padding: 10px;
    width: 100%;
    background: #61A60E;
    border-radius: 80px;
    outline: none;
    border: 1px solid #61A60E;
    color: #fff;
    margin-top: 25px;
}
.saveDraftDisabledBtn {
    font-size: 20px;
    font-weight: 400;
    padding: 10px;
    width: 100%;
    background: #EDEFF2;
    border-radius: 80px;
    outline: none;
    border: 1px solid #EDEFF2;
    color: #989CA6;
    margin-top: 25px;
}

.orderSummaryConfirmBtnDisabled {
    font-size: 20px;
    font-weight: 400;
    padding: 10px;
    width: 100%;
    border-radius: 80px;
    outline: none;
    border: 1px solid #EDEFF2;
    color: #989CA6;
    margin-top: 25px;
    background: #EDEFF2;
}

.orderSummarySaveDraftBtn {
    font-size: 20px;
    font-weight: 400;
    padding: 0;
    background: transparent;
    outline: none;
    border: none;
    color: #61A60E;
    margin-top: 15px;
    padding-bottom: 0px;
    border-bottom: 1px solid #61A60E;
}

.orderSummary h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 20px;
    color: #464B55;
    margin: 10px 0 30px;
}

.orderSummaryProduct {
    width: 100%;
    max-height: 265px;
    overflow-y: auto;
}
.orderSummaryProductCard {

    width: 100%;
    display: flex !important;
    align-items: center;
    margin-bottom: 20px;
}

.orderSummaryProductCardImg {
    width: 100%;
    border: 1px solid #D3D6DA;
    border-radius: 8px;
    padding: 3px;
    width: 72px;
    height: 72px;
    position: relative;
    cursor: pointer !important;
}

.orderSummaryProductCardImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer !important;
}

.orderSummaryProductCardBadge{
    position: absolute;
    top: 5px;
    left: 20px;
    z-index: 1;
}
.orderSummaryProductCardBadge-Img1{
    width: 35px;
}
.orderSummaryProductCardBadge-Img2{
    width: 25px;
}

.orderSummaryProduct h4 {
    color: #464B55;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    height: unset;
    margin-bottom: 0;
    min-height: unset;
    width: 80%;
    margin-left: 12px!important;
    cursor: pointer;
}
.orderSummaryProduct h4:hover {
    color: #61A60E;
}

.orderSummaryProduct p {
    font-weight: 700;
    font-size: 24px;
    color: #464B55;
    margin-bottom: 0;
    white-space: nowrap;
    margin-top: 6px;
}

.orderSummaryProduct p span {
    font-weight: 400;
    font-size: 20px;
    /* line-height: 18px; */
    color: #989CA6;
    display: block;
    float: right;
}

.orderSummaryProduct button {
    color: #989CA6;
    font-size: 18px;
    font-weight: 400;
    margin: 20px auto;
    outline: none;
    border: none;
    padding: 0;
    width: 90px;
    background-color: transparent;
}

.orderSummaryProductPrice {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
}

.orderSummaryProductPriceSubtotal {
    width: 100%;
    background: #EFF6E7;
    border-radius: 12px 12px 0px 0px;
    padding: 20px 20px 10px;
    --mask: radial-gradient(12px at 12px 100%,#0000 98%,#000) -12px;
    -webkit-mask: var(--mask);
    mask: var(--mask);
}

.orderSummaryProductPriceSubtotal p {
    color: #464B55;
    line-height: 26px;
    font-size: 20px;
    font-weight: 400;
}

.orderSummaryProductPriceSubtotal h4 {
    color: #464B55;
    line-height: 26px;
    font-size: 20px;
    font-weight: 600;
    text-align: right;
}

.orderSummaryProductPriceTotal {
    padding: 30px 20px 20px;
    width: 100%;
    background: #EFF6E7;
    border-radius: 0px 0px 12px 12px;
    border-top: 1.5px dashed #D3D6DA;
        --mask: radial-gradient(12px at 12px 0,#0000 98%,#000) -12px;
        -webkit-mask: var(--mask);
                mask: var(--mask);
}

.orderSummaryProductPriceTotal p {
    color: #464B55;
    line-height: 20px;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 0;
}

.orderSummaryProductPriceTotal h4 {
    color: #61A60E;
    line-height: 20px;
    font-size: 24px;
    font-weight: 600;
    text-align: right;
}

.orderSummaryViewMore {
    color: #989CA6;
    background: transparent;
    font-size: 18px;
    font-weight: 400;
    outline: none;
    border: none;
    margin-bottom: 20px;
}
.orderSummaryViewMore img{
    width: 10px;
    margin-left: 10px;
}
@media screen and (max-width: 1160px) {
    .orderSummaryProduct {
        max-height: 260px;
    }
    
}
@media screen and (max-width: 991px) {
    .orderSummary h3 {
        font-size: 26px;
    }

    .orderSummary {
        padding: 20px;
    }

    .orderSummaryProductPriceSubtotal p {
        line-height: 20px;
        font-size: 16px;
    }

    .orderSummaryProductPriceSubtotal h4 {
        line-height: 20px;
        font-size: 16px;
    }

    .orderSummaryProductPriceTotal p {
        line-height: 20px;
        font-size: 16px;
    }

    .orderSummaryProductPriceTotal h4 {
        line-height: 20px;
        font-size: 16px;
    }

    .orderSummaryConfirmBtn {
        font-size: 18px;
        padding: 7px;
    }

    .orderSummaryConfirmBtnDisabled {
        font-size: 18px;
        padding: 7px;
    }

    .orderSummarySaveDraftBtn {
        font-size: 18px;
    }
    .orderSummaryProduct {
        max-height: 215px;
    }
    .orderSummaryProductCardImg {
        border-radius: 7px;
        padding: 2px;
        width: 55px;
        height: 55px;
    }
    .orderSummaryProduct h4 {
        font-size: 16px;
    }
    .orderSummaryProduct p {
        font-size: 17px;
        margin-top: 0px;
    }

}

@media screen and (max-width: 767px) {
    .orderSummary {
        padding: 15px 20px 20px;
        border-radius: unset;
    }

    .orderSummaryProduct {
        display: none;
    }

    .orderSummaryProductPriceSubtotal {
        border-radius: 8px 8px 0px 0px;
        padding: 15px 20px 3px;
    }

    .orderSummaryProductPriceTotal {
        padding: 12px 20px 10px;
        border-radius: 0px 0px 8px 8px;
    }

    .orderSummaryConfirmBtn {
        font-size: 18px;
        padding: 5px;
        margin-top: 15px;
    }

    .orderSummaryProductPriceSubtotal p {
        font-size: 18px;
    }

    .orderSummaryProductPriceSubtotal h4 {
        font-size: 18px;
    }

    .orderSummaryProductPriceTotal p {
        font-size: 18px;
    }

    .orderSummaryProductPriceTotal h4 {
        font-size: 18px;
    }

    .orderSummaryConfirmBtn {
        font-size: 18px;
        padding: 8px;
        margin-top: 12px;
    }

    .orderSummaryConfirmBtnDisabled {
        font-size: 18px;
        padding: 8px;
        margin-top: 12px;
    }

    .orderSummarySaveDraftBtn {
        font-size: 18px;
        margin-top: 12px;
    }
    .orderSummaryConfirmBtnDisabled {
        font-size: 20px;
        padding: 5px;
    }
}

@media screen and (max-width: 374px) {
    .orderSummaryProductPriceSubtotal p {
        font-size: 16px;
    }
    .orderSummaryProductPriceSubtotal h4 {
        font-size: 16px;
    }
    .orderSummaryProductPriceTotal p {
        font-size: 16px;
    }
    .orderSummaryProductPriceTotal h4 {
        font-size: 16px;
    }
    .orderSummaryConfirmBtnDisabled {
        font-size: 17px;
    }
}