.OrderManagementFilter {
    width: 100%;
    background-color: #fff;
}
.OrderManagementFilter .rdrDefinedRangesWrapper {
    display: none;
}

.OrderManagementFilter label {
    color: #989CA6;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
}

.p-20 {
    padding: 25px !important;
}

.OrderManagementFilter-order {
    width: 100%;
    color: #464B55 !important;
}

.OrderManagementFilter-order .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    background: #F5F6F7;
    border-radius: 40px 0px 0px 40px !important;
    height: 44px !important;
    padding: 0 16px !important;
    display: flex;
    align-items: center;
    color: #464B55 !important;
}

.OrderManagementFilter-order .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-size: 20px;
}

.OrderManagementFilter-order .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    display: flex;
flex-direction: row;
align-items: flex-start;
padding: 10px 16px;
gap: 8px;
background: #F5F6F7;
border-radius: 0px 40px 40px 0px;
height: 45px !important;
}

.OrderManagementFilter-order .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: #464B55;
}

.OrderManagementFilter-order .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
}
.OrderManagementFilter-orderBtn .view-by-order-active-button{
    padding: 6px 16px;
    background: #EFF6E7;
    border: 1px solid #61A60E;
    outline: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #61A60E;
}
.OrderManagementFilter-orderBtn .view-by-order-disable-button{
    padding: 6px 16px;
    background: #FFFFFF;
    border: 1px solid #D3D6DA;
    outline: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #BFC2C9;
}

/* .OrderManagementFilter-orderBtn {
    align-items: center;
    justify-content: flex-end;
} */

/* .OrderManagementFilter-orderBtn button:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 8px 0px 0px 8px;
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    background-color: #fff;
    width: 135px;
} */

/* .OrderManagementFilter-orderBtn button:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 8px;
    background: #EFF6E7;
    border-radius: 0px 8px 8px 0px;
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    background: #F5F6F7;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: #BFC2C9;
    width: 135px;
} */

/* .OrderManagementFilter-orderBtn button:focus {
    color: #61A60E;
    border: 1px solid #61A60E;
    font-weight: 600;
} */

.OrderManagementFilter-category .css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root {
    color: #61A60E !important;
    font-size: 20px;
}

.OrderManagementFilter-category .css-1qqsdnr-MuiAutocomplete-root .MuiAutocomplete-tag {
    margin: 3px;
    max-width: calc(100% - 6px);
    background: #EFF6E7 !important;
    color: #61A60A !important;
    border-radius: 6px !important;
}

.OrderManagementFilter-category span.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
    margin: 3px;
    display: flex;
flex-direction: row;
align-items: center;
padding: 2px 8px;
gap: 4px;
background: #EFF6E7;
border-radius: 6px;
font-size: 20px;
}

.OrderManagementFilter-category .css-6od3lo-MuiChip-label {
    color: #61A60A;
}

.OrderManagementFilter-category .css-1q79v3g-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    color: #61A60A;
}

.OrderManagementFilter-datepicker {
    width: 100%;
}

.OrderManagementFilter-select {
    width: 100%;
}

.OrderManagementFilter-datepicker {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


.OrderManagementFilter-datepicker-date .react-datepicker-wrapper {
    flex: 49%;
}

.OrderManagementFilter-datepicker-date .react-datepicker__input-container input.react-datepicker-ignore-onclickoutside {
    outline: none;
    border: none;

}

.OrderManagementFilter-datepicker-date input[type="text"] {
    outline: none;
    border: none;
}

.OrderManagementFilter-datepicker-date {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    position: relative;
}

.OrderManagementFilter-datepicker-date .rdrDateDisplayWrapper{
    display: none;
}
.OrderManagementFilter-datepicker-date .rdrMonthAndYearWrapper{
    justify-content: unset !important;
}

.OrderManagementFilter-datepicker-dateButton {
    outline: none;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #464B55;
    border: 1px solid#989CA6;
    border-radius: 8px;
    padding: 5px 10px;
    width: 100%;
    background-color: #fff;
}
.OrderManagementFilter-datepicker-date button.OrderManagementFilter-datepicker-dateButton:focus{
    border: 2px solid #61A60E;;
}
.OrderManagementFilter-datepicker-date .rdrMonth {
    width: 100%;
    padding: 0;
    font-size: 20px;
}
.OrderManagementFilter-datepicker-date .rdrDay.rdrDayToday{
    color: #61A60E !important;
    border: none !important;
    background-color: transparent !important;
}
.OrderManagementFilter-datepicker-date .rdrDay.rdrDayToday:hover{
    color: #61A60E !important;
    border: none !important;
    background-color: #EFF6E7 !important;
    border-radius: 50%;
}
.OrderManagementFilter-datepicker-date .rdrDayToday .rdrDayNumber span:after{
    background-color: transparent !important;
}
.OrderManagementFilter-datepicker-date .rdrDateRangePickerWrapper{
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 8%);
    border-radius: 12px;
    box-sizing: border-box !important;
    padding: 5px;
}
.OrderManagementFilter-datepicker-date .rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    margin: 0;
    background: transparent;
}
.OrderManagementFilter-datepicker-date .rdrMonthAndYearPickers select:hover {
    background-color: transparent;
}

.abc {
    position: absolute;
    top: 30px;
    left: 30px;

}
.OrderManagementFilter-datepicker-date .rdrDay{
    height: 30px;
}
.OrderManagementFilter-datepicker-date .rdrDayNumber span:hover{
    color: #464B55;
}
button:active.rdrDay.rdrDayToday{
background-color: #61A60A !important;
}

.OrderManagementFilter-orderType {
    font-size: 20px;
    border-radius: 40px 0px 0px 40px;
    color: #989CA6;
    background-color: #F5F6F7;
}
.OrderManagementFilter-orderType .css-11y9vmj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 10px 15px;
}
 .OrderManagementFilter-orderType .css-qiwgdb{
    border-radius: 40px 0px 0px 40px;
}
.marginTop30{
    margin-top: 30px !important;
}
.marginTop20{
    margin-top: 20px !important;
}
/* Search bar css */
.OrderManagementFilter-orderType .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none!important;
}
.order-management-autocomplete .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
}
.order-management-autocomplete .css-i4bv87-MuiSvgIcon-root {
    display: none;
}
.OrderManagementFilter-order .css-qxty6i-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
}
.order-management-autocomplete .css-1fcnh6e-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 8px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #989CA6;
}
.search-by-order-number-input{
    padding: 10px 16px  10px 48px;    
    width: 95%;
    height: 44px;
    background: #F5F6F7;
    border-radius: 0px 40px 40px 0px;
    outline: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #989CA6;
    border-left: 1px solid;
    position: relative;
}
.search-by-order-number-input::placeholder {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #989CA6;
}
.search-by-order-number-input-icon {
    height: 16px;
    width: 16px;
    position: absolute;
    left: 18px;
    top: 14px;
}
.OrderManagementFilter-orderType .css-igs3ac {
    border: none!important;
}
.OrderManagementFilter .css-qiwgdb.css-qiwgdb.css-qiwgdb {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    display: flex!important;
    align-items: center!important;
    color: #464B55!important;
}