.banner-wraper{
  padding: 32px;
  padding: 20px;
  box-shadow: 5px;
}

.herobanner2-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-2xs);
  background-color: var(--color-white);
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
  width: 1376px;
  height: 520px;
}

.concept-cooking-with-raw-beef-icon {
  position: absolute;
  top: 0;
  left: 152px;
  border-radius: 0 var(--br-2xs) var(--br-2xs) 0;
  width: 1224px;
  height: 520px;
  object-fit: cover;
}

.frame-child,
.frame-item {
  position: relative;
  height: 12px;
  flex-shrink: 0;
}

.frame-child {
  border-radius: var(--br-xs);
  background-color: var(--color-green);
  width: 24px;
  transform: rotate(90deg);
  transform-origin: 0 0;
}

.frame-item {
  width: 12px;
}

.rectangle-parent {
  position: absolute;
  height: 23.08%;
  width: 0.87%;
  top: 92.31%;
  right: 53.49%;
  bottom: -15.38%;
  left: 45.64%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  transform: rotate(-90deg);
  transform-origin: 0 0;
}

.vector-icon,
.vector-icon1 {
  position: absolute;
  height: 18.14%;
  width: 11.31%;
  top: -0.82%;
  right: 40.37%;
  bottom: 82.69%;
  left: 48.32%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vector-icon1 {
  height: 92.27%;
  width: 100%;
  top: 7.73%;
  right: 0;
  bottom: 0;
  left: 0;
}

.up-to {
  width: 151px;
  height: 39px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 45.2383px;
  line-height: 39px;
  /* identical to box height, or 86% */

  text-align: center;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.div {
  width: 149px;
  height: 67px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 66.6647px;
  line-height: 67px;
  /* identical to box height, or 100% */

  text-align: center;

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.off {
  /* OFF */


  width: 153px;
  height: 67px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 71.6645px;
  line-height: 67px;
  /* identical to box height, or 93% */

  text-align: center;

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.up-to-parent {
  position: absolute;
  top: 171.16px;
  left: 38.33px;
  width: 153.33px;
  height: 172.94px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.vector-icon2,
.vector-icon3,
.vector-icon4 {
  position: absolute;
  height: 59.84%;
  width: 80.2%;
  top: 34.44%;
  right: 9.9%;
  bottom: 5.72%;
  left: 9.9%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vector-icon3,
.vector-icon4 {
  height: 20.99%;
  width: 80.21%;
  top: 13.45%;
  right: 9.89%;
  bottom: 65.56%;
}

.vector-icon4 {
  height: 18.13%;
  width: 11.54%;
  top: -0.81%;
  right: 47.04%;
  bottom: 82.68%;
  left: 41.42%;
}

.vectoriconbanner {
  /* Vector */


  position: absolute;
  left: 5.31%;
  right: 81.32%;
  top: 3.39%;
  bottom: 93.54%;

  background: #FFFFFF;
}

.group {
  position: absolute;
  height: 76.59%;
  width: 16.67%;
  top: 7.45%;
  right: 79.67%;
  bottom: 15.96%;
  left: 3.66%;
  box-shadow: 15.292357444763184px 15.292357444763184px 21.86px rgba(0, 0, 0, 0.1);
}

.herobanner2 {
  position: relative;
  width: 1376px;
  height: 520px;
  flex-shrink: 0;
}

.herobanner2-parent {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-4xl);
  color: var(--color-white);
  font-family: var(--font-montserrat);
}

.banner-promotion{
  box-shadow: 0px 0px 5px #464B55;
  
}

.banner-promotion .carousel-indicators {
  display: flex;
  flex-direction: row;
  gap: 10px;
  bottom: 4%;
  left: 9%;
  align-items: center;
}

.banner-promotion .carousel-indicators .active {
  background: #61a60e;
  border: none;
  height: 10px;
  width: 25px;
  border-radius: 17px;
}

.banner-promotion .carousel-indicators button {
  border: none;
  background: #61a60e;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  gap: 10px;
}