.common-margin-top-16 {
    margin-top: 16px !important;
}

.create-order-main-container .create-order-inner-container {
    padding: 130px 60px 80px 60px;
}

.create-order-inner-container .create-order-main-container-breadcrumb-heading {
    font-weight: 500;
    font-size: 20px;
    color: #989CA6;
}

.create-order-inner-container .create-order-main-container-breadcrumb-image {
    height: 16px;
    width: 10px;
    margin-right: 11px;
    margin-left: 11px;
    margin-top: 5px;
}

.create-order-common-box-shadow {
    padding: 24px 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
}

.create-order-box-margin {
    margin-top: 32px !important;
}

.create-order-top-heading-image {
    width: 16px;
    height: 16px;
    margin-top: 6px;
}

.create-order-box-shadow-top-heading {
    font-weight: 600;
    font-size: 24px;
    color: #464B55;
    margin-left: 11px;
}

.create-order-top-heading-cart-shopping-image {
    width: 18px;
    height: 16px;
    margin-top: 6px;
}

.create-order-top-heading-image-payment {
    width: 20px;
    height: 16px;
    margin-top: 6px;
}

.product-info-inner-header-background {
    background: #F5F6F7;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 12px;
}

.product-info-inner-header-background .product-info-inner-header-main-heading {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
}

.product-info-common-margin {
    margin-top: 32px;
    margin-bottom: 12px;
}

.create-order-product-info-image-border {
    width: 64px;
    height: 64px;
    border: 1px solid #D3D6DA;
    border-radius: 8px;
}

.create-order-product-info-left-images {
    height: 62px;
    width: 62px;
    padding: 2px;
    object-fit: contain;
}

.create-order-product-info-image-border .create-order-product-info-image {
    padding: 2px;
    height: 68px;
    width: 68px;
    object-fit: contain;
}

.create-order-product-custom-heading {
    font-weight: 500;
    font-size: 16px;
    color: #464B55;
    margin-left: 6px;
}

.create-order-produt-info-alignment {
    align-items: center !important;
}

.create-order-product-info-article-code {
    font-weight: 400;
    font-size: 16px;
    color: #989CA6;
}

.create-order-product-info-article-code-inner {
    font-weight: 400;
    font-size: 16px;
    color: #464B55;
}

.create-order-product-info-unit-price {
    font-weight: 700;
    font-size: 20px;
    color: #464B55;
}

.create-order-product-info-cross-image {
    top: 4px;
    width: 12px;
    height: 12px;
    margin: 2px 6px 0px 6px;
    cursor: pointer;
    float: right;
    position: absolute;
    right: 8px;
}

.create-order-info-section-gray-line {
    border: 1px solid #D3D6DA;
    width: 100%;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}

.create-order-total-amount-main-container .create-order-offer-input-main-container {
    margin-top: 24px;
    cursor: not-allowed;
}

.create-order-total-amount-main-container .create-order-offer-input-text {
    padding: 10px 16px;
    background: #F5F6F7;
    border-radius: 8px 0px 0px 8px;
    width: 305px;
    height: 44px;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
}

.create-order-total-amount-main-container .create-order-offer-apply-button-main-container {
    background: #edeff2;
    color: #989ca6;
    padding: 8px 20px;
    border-radius: 0px 8px 8px 0px;
    width: 75px;
    height: 44px;
    cursor: not-allowed;
}

.create-order-total-amount-main-container .create-order-offer-apply-button-text {
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    cursor: not-allowed;
}

.create-order-note-option-main-container {
    margin-top: 24px !important;
}

.create-order-note-option-main-container .create-order-note-option-heading {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
}

.create-order-note-option-main-container .create-order-note-textarea {
    margin-top: 8px;
    padding: 12px 16px;
    background: #F5F6F7;
    border-radius: 8px;
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    width: 100%;
    height: 72px;
    outline: none;
    border: none;
}

.create-order-note-option-main-container .create-order-note-textarea-counts {
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    margin-top: 8px;
    float: right;
}

.create-order-left-main-heading {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
}

.create-order-right-main-heading {
    font-weight: 700;
    font-size: 20px;
    color: #464B55;
}

.create-order-total-right-amount-main-container {
    margin-top: 24px !important;
}

.create-order-total-amount-gray-line {
    border: 1px solid #D3D6DA;
    width: 100%;
}

/* Search-bar-section start from here */
.product-info-search-bar-main-container {
    margin-top: 16px;
    margin-bottom: 32px;
}

.product-info-search-bar-main-container .product-info-search-bar-input {
    background: #F5F6F7;
    border-radius: 40px;
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    width: 100%;
    padding: 8px 42px;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    position: relative;
    outline: none;
    border: none;
}
.product-info-search-bar-main-container .product-info-search-bar-input:focus {
    border: 2px solid #61A60E;
}

.product-info-search-bar-main-container .product-info-search-bar-image {
    position: absolute;
    height: 14px;
    width: 14px;
    left: 18px;
    top: 32px;
    cursor: pointer;
}

.product-info-search-bar-dropdown-main-container {
    position: absolute;
    width: 96%;
    height: auto;
    z-index: 9;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    top: -20px;
}

.product-info-search-bar-input-main-container {
    position: relative;
}

.product-info-search-cross-image {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 22px;
    top: 32px;
    cursor: pointer;
}

.product-info-search-bar-product-image-border {
    width: 72px;
    height: 72px;
    border: 1px solid #D3D6DA;
    border-radius: 8px;
}

.product-info-search-bar-product-image-border .product-info-search-bar-product-image {
    padding: 2px;
    height: 68px;
    width: 68px;
    object-fit: contain;
}

.product-info-search-bar-product-product-name {
    font-weight: 400;
    font-size: 16px;
    color: #989CA6;
}

.product-info-search-bar-product-product-name {
    font-size: 16px;
    line-height: 20px;
    color: #989CA6;
}

.product-info-search-bar-product-article-code {
    font-weight: 400;
    font-size: 16px;
    color: #989CA6;
}

.product-info-search-bar-product-heading-main-container {
    margin-left: 8px;
}

.product-info-search-bar-information-main-container {
    padding: 20px 10px 20px 10px;
    overflow-x: hidden;
    max-height: 490px;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.product-info-search-bar-information-main-container {
    scrollbar-width: auto;
    scrollbar-color: #D3D6DA #ffffff;
}

/* Chrome, Edge, and Safari */
.product-info-search-bar-information-main-container::-webkit-scrollbar {
    width: 10px;
    scrollbar-width: auto;
    scrollbar-color: #D3D6DA #ffffff;
}

.product-info-search-bar-information-main-container::-webkit-scrollbar-track {
    background: #ffffff;
}

.product-info-search-bar-information-main-container::-webkit-scrollbar-thumb {
    background-color: #D3D6DA;
    border-radius: 20px;
    border: 3px solid #ffffff;
}

.product-info-search-bar-in-stock-border {
    background: #EFF6E7;
    border-radius: 26px;
    align-items: center;
    padding: 2px 8px;
    text-align: center;
}

.product-info-search-bar-in-stock-heading {
    font-weight: 500;
    font-size: 16px;
    color: #61A60E;
    text-align: center;
    margin-top: 4px;
}

.product-info-search-bar-out-stock-border {
    background: #F5F6F7;
    border-radius: 26px;
    align-items: center;
    padding: 2px 8px;
    text-align: center;
}

.product-info-search-bar-out-stock-heading {
    font-weight: 500;
    font-size: 16px;
    color: #989CA6;
    text-align: center;
    margin-top: 4px;
}

.product-info-search-bar-price-heading {
    font-weight: 700;
    font-size: 20px;
    color: #464B55;
}

.product-info-search-bar-gray-line {
    border: 1px solid #D3D6DA;
    width: 95% !important;
    margin-top: 12px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-bottom: 12px !important;
}

.search-bar-desktop-add-to-cart-button-main-containers {
    background: #61A60E;
    border-radius: 40px;
    width: 100%;
    padding: 4px;
}

.product-info-search-bar-add-to-cart-main-container {
    background: #61A60E;
    border-radius: 32px;
    padding: 6px 8px;
    width: 100%;
}

.product-info-search-bar-add-to-cart-main-container .product-info-search-bar-add-to-cart-heading {
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
}

.search-bar-desktop-add-buttons {
    height: 32px;
    width: 32px;
    cursor: pointer;
}

.search-bar-desktop-minus-buttons {
    height: 32px;
    width: 32px;
    cursor: pointer;
}

.search-bar-desktop-headings {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 3px;
}

.product-info-search-bar-select-heading {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
}
/* order-details-section start from here */
.create-order-order-details-heading {
    font-weight: 500;
    font-size: 48px;
    color: #464B55;
    margin-top: 16px;
    margin-bottom: 8px;
}
.create-order-details-status-main-container .my-order-details-status-image {
    height: 16px;
    width: 16px;
    margin-bottom: 8px;
}
.create-order-details-status-main-container .my-order-details-status-order-number {
    font-weight: 500;
    font-size: 24px;
    color: #464B55;
    margin-left: 11px;
    margin-right: 19px;
}
.create-order-details-status-main-container .my-order-details-status-calendar-image {
    height: 16px;
    width: 16px;
    margin-bottom: 6px;
}
.create-order-details-status-main-container .my-order-details-status-order-date {
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    margin-left: 11px;
    margin-top: 2px;
}
.create-order-details-status-main-container .my-order-details-order-placed-status-button {
    padding: 4px 12px;
    border: 1px solid #BFC2C9;
    border-radius: 40px;
    background: #F5F6F7;
    margin-left: 16px;
    cursor: pointer;
}
.create-order-details-status-main-container .my-order-details-order-placed-status-heading {
    font-weight: 500;
    font-size: 20px;
    color: #989CA6;
}
.create-order-details-status-main-container .my-order-details-order-placed-status-image {
    width: 14px;
    height: 14px;
}
/* order-details-section end */
/* add-product-section start from here */
.create-order-add-product-main-container {
    margin-top: 32px;
    margin-bottom: 24px;
    text-align: center;
    justify-content: center;
}
.create-order-add-product-main-container .create-order-add-product-image {
    width: 40px;
    height: 40px;
}
.create-order-add-product-main-container .create-order-add-product-heading {
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    margin-top: 12px;
}
/* add-product-section end */
.customer-info-search-bar-dropdown-main-container {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 9;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    top: -20px;
}

.customer-info-search-bar-information-main-container {
    padding: 10px 0px 10px 0px;
    overflow: hidden;
}
.customer-info-search-bar-dropdown-main-container .customer-info-search-bar-information-main-heading {
    font-weight: 400;
    font-size: 20px;
    color: #989CA6;
    background-color: #FFFFFF;
    padding: 4px 16px;
}
.customer-info-search-bar-dropdown-main-container .customer-info-search-bar-information-main-heading:hover {
    background-color: #F5F6F7;
}
.customer-information-common-gray-line {
    width: 100%;
    border: 1px solid #D3D6DA;
    margin-bottom: 24px;
}