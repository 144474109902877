.bg-HeaderMobPages {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
}

.bg-HeaderMobPages button {
    font-size: 20px;
    outline: none;
    border: none;
    color: #464B55;
    background-color: transparent;
    text-align: left;
    padding: 0;
    background-image: url(./Assets/SVG/ArrowLeft.svg);
    background-size: 7px;
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 20px;
    margin-bottom: 10px;
}

.bg-HeaderMobPages button img {
    width: 7px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-right: 15px;
}

.bg-HeaderMobPages h4 {
    margin: 10px 0;
}

.bg-HeaderMobPages h4 a,
.bg-HeaderMobPages h4 a:hover,
.bg-HeaderMobPages h4 a:focus,
.bg-HeaderMobPages h4 a:visited {
    font-size: 32px;
    line-height: 34px;
    color: #464B55;
}

.bg-HeaderMobPages ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
}

.bg-HeaderMobPages ul li a {
    display: flex;
    align-items: center;
    width: 100%;
    color: #464B55;
    font-size: 20px;
    line-height: 40px;
    background-image: url(./Assets/SVG/ArrowRight.svg);
    background-size: 7px;
    background-position: 95% center;
    background-repeat: no-repeat;
    text-transform: capitalize;
}

.bg-HeaderMobPages ul li a span {
    background: #EFF6E7;
    padding: 5px;
    color: #61A60E;
    font-size: 16px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600!important;
    margin-left: 7px;
}

.bg-HeaderMobPages ul li a img {
    font-size: 16px;
    margin-right: 15px;
}

.bg-HeaderMobPages ul li a:last-child {
    /* line-height: 0; */
}
