.new-order-list-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 20px 0px;
}

.new-order-list-header .new-order-list-header-betagro-logo {
    height: 100%;
    width: 40%;
    margin-left: 31px;
}
.create-order-list-header-betagro-logo {
    height: 100%;
    width: 8%;
    margin-left: 31px;
    cursor: pointer;
}

.new-order-list-navbar-right .header-bell-icon {
    height: 18px;
    width: 18px;
    margin-top: 18px;
}

.new-order-menu {
    padding: 86px 31px 36px 31px!important;
    height: 100vh;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    background: #FFFFFF !important;
    position: fixed;
    top: 0px;
    width: 270px;
    overflow: auto;
}
/* ===== Scrollbar CSS START ===== */
  /* Firefox */
  .new-order-menu {
    scrollbar-width: auto;
    scrollbar-color: #D3D6DA #ffffff;
}

  /* Chrome, Edge, and Safari */
.new-order-menu::-webkit-scrollbar {
    width: 8px;
    scrollbar-width: auto;
    scrollbar-color: #D3D6DA #ffffff;
}

.new-order-menu::-webkit-scrollbar-track {
    background: #ffffff;
}

.new-order-menu::-webkit-scrollbar-thumb {
    background-color: #D3D6DA;
    border-radius: 20px;
    border: 3px solid #ffffff;
}
/* ===== Scrollbar CSS END ===== */

.overflow-hidden {
    overflow: hidden;
}

.main-table-section {
    padding: 112px 64px 100px 0px;
}

.main-table-section .order-list-section-main-heading {
    font-weight: 500;
    font-size: 56px;
    line-height: 60px;
    margin-top: 40px;
    margin-bottom: 30px;
}

.mt-25 {
    margin-top: 22px!important;
}
.table-container-border {
    padding: 14px 30px 10px 30px;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
}

.table-active-tabs{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.table-active-tabs ul {
    list-style-type: none;
    display: flex;
    padding: 0px;
}

.table-active-tabs li a {
    text-decoration: none;
    color: #464B55;
    font-size: 20px;
    padding-right: 0px;
    line-height: 60px;
    font-weight: 400;
}
.table-active-tabs .tabs-active-count {
    padding: 0px 6px;
    background: #EFF6E7;
    border-radius: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #61A60E;
    margin-left: 2px;
}
.main-table-section .table-tabs-gray-line {
    width: 100%;  
    height: 0px;
    border: 1px solid #D3D6DA;
}
.new-order-list-table-main-container .new-order-list-table-order-counts {
    font-weight: 500;
    font-size: 40px;
    line-height: 42px;
    color: #464B55;
}
.new-order-list-table-main-container .table-selected-counts {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #989CA6;
    margin-left: 16px;
    margin-top: 4px;
}
.new-order-list-table-main-container .table-order-counts {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 21px;
}
.common-table-material .css-15wwp11-MuiTableHead-root  {
    background: #F5F6F7!important;
    border-radius: 8px!important;
}
.to-comfirm-table-main-container .css-15wwp11-MuiTableHead-root {
    background: #F5F6F7!important;
    border-radius: 8px!important;
}
.to-comfirm-table-main-container .css-1a4b21o {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
}
.to-comfirm-table-main-container .css-1bigob2 {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
    border-bottom: none!important;
}
.to-comfirm-table-main-container .css-q34dxg {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
    text-transform: capitalize!important;
}
.to-comfirm-table-main-container .css-1wbz3t9 {
    background: #F5F6F7!important;
    border-radius: 8px!important;
}
.to-comfirm-table-main-container .css-hbtnrh-MuiTableCell-root {
    border-bottom: none!important;
}
.to-comfirm-table-main-container .css-17yrl66 {
    border-bottom: none!important;
}
.common-table-material .css-1ndpvdd-MuiTableCell-root  {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
    border-bottom: none!important;
}
.to-comfirm-table-main-container .css-10dfkli-MuiTableCell-root {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
    border-bottom: none!important;
    text-align: center!important;
}
.to-comfirm-table-main-container .css-1h6kq28 {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
    border-bottom: none!important;
    text-align: center!important;
}
.to-comfirm-table-main-container .css-1sgp6db {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
    border-bottom: none!important;
    text-align: center!important;
}
.to-comfirm-table-main-container .css-1azl6jz-MuiTableCell-root {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
    border-bottom: none!important;
    text-align: center!important;
}
.to-comfirm-table-main-container  .css-11w94w9-MuiTableCell-root {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
}
.to-comfirm-table-main-container .css-1i36psw {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
}
.to-comfirm-table-main-container .css-177gid-MuiTableCell-root {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
    text-align: center!important;
}
.to-comfirm-table-main-container .css-1q1u3t4-MuiTableRow-root.Mui-selected {
    background: #F5F6F7!important;
}
.to-comfirm-table-main-container .css-1gqug66.Mui-selected {
    background: #F5F6F7!important;
}
.common-table-material .css-1ygcj2i-MuiTableCell-root {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
    border-bottom: none!important;
}
.common-table-material .css-1ex1afd-MuiTableCell-root {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;
    white-space: nowrap!important;
    text-transform: capitalize!important;
}
.common-table-material .css-1yhpg23-MuiTableCell-root {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #464B55!important;   
    white-space: nowrap!important;
}
.common-table-material .table-status-list-image {
    width: 4px;
    height: 16px;
    cursor: pointer;
}
.common-table-material .table-created-employer {
    height: 12px;
    width: 12px;
    margin-right: 8px;
    margin-bottom: 4px;
}
.common-table-material .order-table-confirm {
    padding: 0px 8px;
    background: rgba(245, 179, 53, 0.2);
    border-radius: 26px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #F5B335;
}
.common-table-material .order-table-to-confirmed {
    padding: 0px 8px;
    background: #E9F8FB;
    border-radius: 26px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #23B5D3;
}
.common-table-material .order-table-shipped {
    padding: 0px 8px;
    background: #F3F2EB;
    border-radius: 26px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #8A803C;
}
.common-table-material .order-table-completed {
    padding: 0px 8px;
    background: #EFF6E7;
    border-radius: 26px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color:  #61A60E;
}
.common-table-material .order-table-draft {
    padding: 0px 12px;
    background: #F5F6F7;
    border-radius: 26px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color:  #989CA6;
    text-transform: capitalize;
}
.common-table-material .order-table-cancellation {
    padding: 0px 8px;
    background: #FEEEF0;
    border-radius: 26px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color:   #EF3145;
}
.common-table-material .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none!important;
}
.common-table-material .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #61A60E!important;
}
.to-comfirm-table-main-container .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #61A60E!important;
}
.to-comfirm-table-main-container .css-zun73v.Mui-checked {
    color: #61A60E!important;
}
.table-dropdownbox {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0px;
    bottom: 0px;
}
.table-dropdown-menu {
    position: absolute;
    min-height: 110px;
    padding: 0px;
    bottom: 8px;
    right: 12px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    transition: all 0.5s ease 0s;
}
.table-dropdown-menu ul {
    list-style-type: none;
}
.table-dropdown-menu ul li a {
    width: 100%;
    color: black;
    font-size: 14px;
    line-height: 14px;
    padding: 5px 5px;
    white-space: nowrap;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    overflow: hidden;
    text-decoration: none;
    background-color: transparent;
    transition: all 0.5s ease 0s;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.table-dropdown-menu ul li a:hover {
    text-decoration: none;
    background-color: #464B55;
    transition: all 0.5s ease 0s;
}
.show-toggle-menu {
    display: block;
}
.table-dropdown-content {
    display: none;
}
.common-table-material .css-hbtnrh-MuiTableCell-root {
    border-bottom: none;
}
.order-table-pagination-main-container {
    padding-top: 30px;
    padding-bottom: 28px;
}
.order-table-pagination-count {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #989CA6;
}
.order-table-pagination-main-container .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #61A60E!important;
    font-size: 18px!important;
    font-weight: 500!important;
    color: white!important;
}
.order-table-pagination-main-container .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
    color: 'white'!important;
    font-size: 18px!important;
}
.order-table-pagination-main-container .css-2gftvx.Mui-selected  {
    background-color: #61A60E !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: white !important;
}
.order-table-pagination-main-container .css-2gftvx {
    color: 'white' !important;
    font-size: 18px !important;
}
.order-table-pagination-main-container .css-1p5q5e5-MuiStack-root {
    float: right!important;
    position: absolute;
    right: 12px;
}
.order-table-pagination-main-container .css-ikzlcq {
    float: right!important;
    position: absolute;
    right: 12px;
}
.order-table-pagination-inner-container .order-table-order-per-page {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #989CA6;
    margin-left: 24px;
}
.order-table-sorting-icon {
    height: 12px;
    width: 8px;
    margin-left: 8px;
}
.tabs-active-green-color {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #61A60E;
}
.new-order-list-navbar-right .order-table-header-line {
    width: 0px;
    height: 32px;
    border: 1px solid #D3D6DA;
    margin-left: 21px;
    margin-right: 20px;
    margin-top: 12px;
}
.new-order-list-navbar-right .order-table-user-button {
    background-color: #EFF6E7;;
    border: none;
    border-radius: 90px;
    font-weight: 300;
    font-size: 28px;
    text-align: center;
    color: #464B55;
    outline: none;
    width: 36px;
    height: 36px;
    margin-top: 10px;
}
.new-order-list-navbar-right .order-table-user-button span {
    position: relative;
    bottom: 3px;
}
.new-order-list-navbar-right .order-table-customer-name {
    font-weight: 600;
    font-size: 20px;
    color: #464B55;
    padding-left: 8px;
    padding-right: 11px;
    margin-top: 16px;
}
.new-order-list-navbar-right .order-table-header-arrow-icon {
    width: 17px;
    height: 9px;
    margin-top: 26px;
}
/* menu-section start from here */
.new-order-menu-inner-container {
    position: sticky!important;
    top: 4px!important;
}
.new-order-menu-inner-container .order-menu-main-heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;;
    padding-left: 13px;
}
.new-order-menu-inner-container .order-menu-main-heading-active {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    padding-left: 13px;
}
.left-menu-accordion-padding .accordion-item:first-of-type .accordion-button {
    padding: 12px 16px;
    width: 100%;
    height: 48px;
    background: #fff;
    border-radius: 8px;
}
.active-accordion-color .accordion-item:first-of-type .accordion-button {
    background: #61A60E!important;
}
.new-order-menu-inner-container-active .accordion-item:first-of-type .accordion-button {
    padding: 12px 16px;
    width: 100%;
    height: 48px;
    background: #61A60E;
    border-radius: 8px;
}
.left-menu-accordion-padding .accordion-item:first-of-type .accordion-button .accordion-button::after {
    width: 14px;
    height: 16px;
    color: #61A60E;
}
/* .left-menu-accordion-padding .order-management-shopping-icon {
    width: 14px;
    height: 16px;
} */
.left-menu-accordion-padding .accordion-item {
    border: none!important;
}
.left-menu-accordion-padding .accordion-body {
    text-align: center;
    padding:0px;
}
.left-menu-accordion-padding .order-management-inner-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    padding-left: 48px;
    margin-top: 8px;
    height: 48px;
}
.left-menu-accordion-padding .order-management-inner-heading:hover{
    background: #F5F6F7;
    border-radius: 8px;
}
.order-management-inner-heading-active {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    padding: 12px 16px;
    text-align: left;
    padding-left: 46px;
    background: #F5F6F7;
    border-radius: 8px;
    margin-top: 8px;
    height: 48px;
}

.left-accordion-menu-icon .accordion-button:not(.collapsed)::after {
    background-image: url('../images/chevron-up-black.png')!important;
    transform: none!important;
}
.left-accordion-menu-icon .accordion-button::after {
    background-image: url('../images/chevron-up-black.png')!important;
    transform: rotate(-180deg)!important;
}
.left-accordion-menu-icon-active .accordion-button:not(.collapsed)::after {
    background-image: url('../images/chevron-up.png')!important;
    transform: none!important;
}
.left-accordion-menu-icon-active .accordion-button::after {
    background-image: url('../images/chevron-up.png')!important;
    transform: rotate(-180deg)!important;
}
.left-menu-accordion-padding .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius:8px!important;
    border-bottom-left-radius: 8px!important;
}
.common-table-material .css-11xur9t-MuiPaper-root-MuiTableContainer-root
/* Firefox */
.common-table-material .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    scrollbar-width: auto;
    scrollbar-color: #D3D6DA #ffffff;
}

/* Chrome, Edge, and Safari */
.common-table-material .css-11xur9t-MuiPaper-root-MuiTableContainer-root::-webkit-scrollbar {
    width: 4px;
    scrollbar-width: auto;
    scrollbar-color: #D3D6DA #ffffff;
}

.common-table-material .css-11xur9t-MuiPaper-root-MuiTableContainer-root::-webkit-scrollbar-track {
    background: #ffffff;
}

.common-table-material .css-11xur9t-MuiPaper-root-MuiTableContainer-root::-webkit-scrollbar-thumb {
    background-color: #D3D6DA;
    border-radius: 20px;
    border: 3px solid #ffffff;
}
.common-gray-background {
    background: #F5F5F5;
}
/* menu-section end */
/* tabs-section start from here */
.table-active-tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #61A60E!important;
    text-transform: capitalize;
    padding: 0px 26px 0px 0px!important;
}
.table-active-tabs .css-1q2h7u5.Mui-selected {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    color: #61A60E!important;
    text-transform: capitalize;
    padding: 0px 26px 0px 0px!important;
}
.table-active-tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    text-transform: capitalize;
    padding: 0px 32px 0px 0px!important;
}
.table-active-tabs .css-1q2h7u5 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    text-transform: capitalize;
    padding: 0px 32px 0px 0px!important;
}
.table-active-tabs .css-1aquho2-MuiTabs-indicator {
    background-color: #61A60E;
    height: 4px;
    padding-right: 26px;
    background-clip: content-box;
}
.table-active-tabs .css-ttwr4n {
    background-color: #61A60E;
    height: 4px;
    padding-right: 26px;
    background-clip: content-box;
}
.table-active-tabs .css-19kzrtu {
    padding: 0px;
}
/* tabs-section end */
.new-order-menu-inner-container .humburger-menu-setting-icon {
    margin-top: 4px;
}
.new-order-menu-inner-container .actions-main-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    margin-left: 14px;
}
.new-order-menu-inner-container .actions-main-heading-active {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    margin-left: 14px;
}
.setting-action-icon-margin {
    margin-top: 20px!important;
    padding: 12px 16px!important;
    height: 48px;
}
.setting-action-icon-active {
    margin-top: 20px!important;
    padding:12px 16px!important;
    height: 48px!important;
    background-color: #61A60E;
    color: #fff;
    border-radius: 8px;
}
.setting-action-icon-margin:hover {
    background: #F5F6F7;
    border-radius: 8px;
    height: 48px;
}
.humbuger-menu-setting-action {
  padding-top: 416px!important;
}
.to-comfirm-table-main-container .css-zun73v.MuiCheckbox-indeterminate {
    color: #61A60E;
}
.left-menu-accordion-padding {
    padding-left: 0px!important;
    padding-right: 0px!important;
}
.left-menu-accordion-padding .accordion-button:not(.collapsed) {
 box-shadow: none!important;
}
.left-menu-accordion-padding .accordion-button:not(.collapsed):hover {
    background-color: #F5F6F7;
}
.table-empty-data-main-container {
    text-align: center;
    margin-top: 79px;
    margin-bottom: 84px;
}
.table-empty-data-main-container .table-empty-data-image {
    height: 64px;
    width: 64px;
}
.table-empty-data-main-container .table-empty-data-heading {
    font-weight: 400;
    font-size: 20px;
    align-items: center;
    color: #989CA6;
    margin-top: 12px;
}
.hover-green-color{
    margin-top: 11px;
    position: absolute;
    left: 8px;
    width: 4px;
    height: 32px;
    background: #61A60E;
    border-radius: 20px;
}
.mt-20 {
    margin-top: 20px!important;
}
.actions-main-heading:hover{
    cursor: pointer;
}
.table-check-icon {
    height: 16px;
    width: 16px;
}
.table-file-import {
    height: 16px;
    width: 16px;
    margin-left: 7px;
    margin-top: 0px;
    float: right;
    margin-top: 4px;
}
/* action css */
/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    background: #FFFFFF!important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08)!important;
    border-radius: 12px!important;
    left: 1130px!important;
} */
/* .css-177ic5c {
    background: #FFFFFF!important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08)!important;
    border-radius: 12px!important;
    left: 1130px!important;
} */
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    align-items: center!important;
    color: #989CA6!important;
}
.css-1km1ehz {
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 24px!important;
    align-items: center!important;
    color: #989CA6!important;
}
.coming-soon-inner-container {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.coming-soon-main-heading {
    font-weight: 500;
    font-size: 60px;
    color: #989CA6;
}
.order-table-pagination-inner-container .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    width: 50%!important;
    height: 88%!important;
    top: -17px!important;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04)!important;
    border-radius: 8px!important;
    border: 1.5px solid #D3D6DA;
}
.order-table-pagination-inner-container .css-igs3ac {
    width: 50%!important;
    height: 88%!important;
    top: -17px!important;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04)!important;
    border-radius: 8px!important;
    border: 1.5px solid #D3D6DA;
}
.order-table-pagination-inner-container .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: none!important;
    outline: none!important;
}

.order-table-pagination-inner-container .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    margin: 0px!important;
    position: relative!important;
    bottom: 16px!important;
    font-size: 20px!important;
    color: #464B55!important;
}
.order-table-pagination-inner-container .css-qiwgdb.MuiSelect-select {
    margin: 0px!important;
    position: relative!important;
    bottom: 16px!important;
    font-size: 20px!important;
    color: #464B55!important;
}
.order-table-pagination-inner-container .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    position: absolute!important;
    right: 64px!important;
    top: 0px!important;
}
.order-table-pagination-inner-container .css-1636szt {
    position: absolute!important;
    right: 64px!important;
    top: 0px!important;
}
.cursor-pointer-none {
    cursor:not-allowed!important;
    z-index: 99;
}
.export-button-main-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 50px;
    padding: 10px 24px;
    border: 1px solid #61A60E;
    outline: none;
    margin-top: 34px;
    float: right;
}
.export-button-main-container .export-button-heading {
    font-weight: 400;
    font-size: 20px;
    align-items: center;
    color: #61A60E;
}
.to-full-button-main-container {
    background: #61A60E;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 50px;
    padding: 10px 24px;
    width: 120px;
    border: 1px solid #61A60E;
    outline: none;
    margin-top: 34px;
}
.to-full-button-main-container .to-full-button-heading {
    font-weight: 400;
    font-size: 20px;
    align-items: center;
    color: #fff;
}
.to-full-button-main-container-deactivated {
    background: #EDEFF2;;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 50px;
    padding: 10px 24px;
    min-width: 120px;
    border: 1px solid #EDEFF2;;
    outline: none;
    margin-top: 34px;
    float: right;
}
.to-full-button-main-container-deactivated .to-full-button-heading-deactivated {
    font-weight: 400;
    font-size: 20px;
    align-items: center;
    color:  #989CA6;
}
.mark-as-skipped-button-main-container {
    background: #61A60E;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 50px;
    padding: 10px 0px;
    width: 100%;
    border: 1px solid #61A60E;
    outline: none;
    margin-top: 34px;
    position: relative;
    right: 8px;
}
.mark-as-skipped-button-main-container .mark-as-skipped-button-heading {
    font-weight: 400;
    font-size: 20px;
    align-items: center;
    color: #fff;
}
.mark-as-skipped-button-main-container-disable {
    background: #EDEFF2;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 50px;
    padding: 10px 0px;
    width: 100%;
    border: 1px solid #EDEFF2;
    outline: none;
    margin-top: 34px;
    position: relative;
    right: 8px;
    cursor: not-allowed;
}
.mark-as-skipped-button-main-container-disable .mark-as-skipped-button-heading-disable {
    font-weight: 400;
    font-size: 20px;
    align-items: center;
    color: #989CA6;
    cursor: not-allowed;
}
.export-button-main-container .export-button-image {
    width: 18px;
    margin-right: 5px;
    height: 16px;
    margin-bottom: 8px;
}
.to-confirm-button-main-container {
    background:  #61A60E;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 50px;
    padding: 6px 24px 6px 24px;
    border: 1px solid #61A60E;
    outline: none;
    margin-top: 34px;
    float: right;
}
.to-confirm-button-main-container-diactivated {
    background:  #d9dcd6;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 50px;
    padding: 6px 24px 6px 24px;
    border: 1px solid #161715;
    outline: none;
    margin-top: 34px;
    float: right;
}
.to-confirm-button-main-container .to-confirm-button-heading {
    font-weight: 400;
    font-size: 20px;
    align-items: center;
    color: #fff;
}
.new-order-menu-inner-container a:hover {
    text-decoration: none!important;
}
.new-order-menu-inner-container a:focus {
    text-decoration: none!important;
}
.common-table-overflow-ellipsis {
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.to-confirmed-table-selected-counts {
    margin-left: 12px;
}
.order-table-pagination-inner-container .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 120px!important;
}
.order-table-pagination-inner-container .css-fvipm8 {
    width: 120px!important;
}