/* @media (min-width:1300px) and (max-width:1460px) {
.header-container{
    max-width: 1270px !important;
}
} */

/* @media screen and (min-width: 1240px) {
    .bg-menu ul li ul.bg-menu-submenu ul {
    height: 230px;
    }
} */
#overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(24, 27, 33, 0.4);
    z-index: 4;
}

header {
    box-sizing: border-box;
    background-color: #fff;
    /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08); */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    z-index: 99;
    position: relative;
}

.logIn-Btn {
    font-family: "DB Helvethaica X 55 Regular" !important;
    font-size: 19px;
    font-weight: 400;
    outline: none;
    border: none;
    padding: 5px 30px;
    background: #61A60E;
    border-radius: 30px;
    color: #fff;
    background-image: url(./Assets/SVG/DownArrowWhite.svg);
    background-repeat: no-repeat;
    background-position: 75% center;
    background-size: 7px;
    padding-right: 40px;
}

.bg-header {
    padding: 20px;
}

.bg-header-icon {
    position: relative;
}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")!important;
}
.bg-header-right li span {
    font-family: "DB Helvethaica X 55 Regular" !important;
    font-size: 22px;
    font-weight: 400;
    color: #464B55;
}

span.item-count {
    background-color: #EF3145;
    width: 20px !important;
    height: 20px !important;
    font-style: normal;
    border-radius: 20px;
    font-size: 15px !important;
    line-height: 16px;
    color: #FFFFFF !important;
    padding: 2px;
    position: absolute;
    left: 52.5%;
    right: 2.5%;
    top: -40%;
    bottom: 52.5%;
}

.bg-header {
    background-color: #fff;
    box-shadow: none;
    z-index: 8;
    position: relative;
}

.bg-header-left {
    width: 30%;
}

.bg-header-right {
    flex: 70%;
}

.bg-header-right ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: none;
    margin-bottom: 0;
}

.bg-header-right .dropdown-menu {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -1px 40px rgb(0 0 0 / 8%);
    min-width: 10rem;
    padding: 10px 20px;
    margin: 0.125rem 0 0;
    text-align: left;
    background-clip: unset;
    border: none;
    border-radius: 10px;
}

.bg-header-right ul li {
    padding: 0 12px;
    cursor: pointer;
}

/* .bg-header-right ul li:hover{
    opacity: .5;
} */

.bg-header-right ul li a,
.bg-header-right ul li a:hover,
.bg-header-right ul li a:active,
.bg-header-right ul li a:visited,
.bg-header-right ul li a:focus {
    font-family: "DB Helvethaica X 55 Regular" !important;
    color: #989CA6;
    background-color: #fff;
    text-decoration: none;
    font-size: 20px;
    background-color: #fff;
    outline: none;
    font-weight: 400;
}

.bg-header-right ul li a img {
    width: 20px;
}

.bg-header-right ul li a .submenu-icon {
    margin-right: 10px;
}

.bg-header-right ul li a .active-count {
    background-color: #EF3145;
    width: 20px;
    height: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.bg-header-right form input {
    background: #F5F6F7;
    padding: 5px;
    border-radius: 40px;
    position: relative;
    font-size: 19px;
    background-image: url(./Assets/SVG/SearchIcon.svg);
    background-repeat: no-repeat;
    background-position: 10% center;
    background-size: 18px;
    width: 100%;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding-left: 25%;
}

.bg-header .dropdown-menu {
    background-color: #fff;
    /* z-index: 1; */
}

.bg-header-right ul li .dropdown .btn-primary {
    background-color: transparent;
    border: unset;
    border-radius: unset;
}

.bg-header-right ul li .dropdown .dropdown-toggle::after {
    display: none;
}

.bg-header-right ul li .dropdown .btn-primary,
.bg-header-right ul li .dropdown .btn-primary:focus,
.bg-header-right ul li .dropdown .btn-primary.focus {
    color: #464B55;
    background-color: unset !important;
    border-color: unset !important;
    box-shadow: unset !important;
    padding: 0 !important;
    outline: none !important;
}

.bg-Header-dropdown .dropdown-menu a.dropdown-item {
    padding: unset !important;
    margin: 7px 0;
    z-index: 10;
}

.bg-Header-dropdown .dropdown-menu .dropdown-item a,
.bg-Header-dropdown .dropdown-menu .dropdown-item a:hover,
.bg-Header-dropdown .dropdown-menu .dropdown-item a:visited,
.bg-Header-dropdown .dropdown-menu .dropdown-item a:focus,
.bg-Header-dropdown .dropdown-menu .dropdown-item a:active {
    font-family: "DB Helvethaica X 55 Regular";
    font-size: 20px;
    font-weight: 400;
    color: #989CA6;
    background-color: #fff;
    display: flex;
    align-items: center;
    margin: -10px 0;
}

.bg-Header-dropdown .dropdown-menu .dropdown-item:focus {
    color: #989CA6 !important;
    background-color: #fff !important;
}

.bg-Header-dropdown {
    background-color: #fff;
    z-index: 1;
}

.bg-menu {
    padding: 10px 0 0px;
    background-color: #fff;
    z-index: 6;
    position: relative;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.bg-menu ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    /* padding-left: 0 !important; */
    margin-bottom: 0 !important;
    padding-bottom: 5px;
}

.bg-menu ul li {
    box-sizing: border-box;
    list-style: none;
    padding: 0 14px 10px;
}

.bg-menu ul li:hover a {
    color: #61A60E;
    border-bottom: 4px solid #61A60E;
    margin-bottom: 10px;
}

.bg-menu ul li a {
    color: #464B55!important;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    padding-bottom: 10px;
}
.bg-menu ul li a:hover{
    color: #61A60E!important;
}

.bg-menu ul li ul.bg-menu-submenu {
    display: none;
}

.bg-menu ul li ul.bg-menu-submenu {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    background-color: #fff;
    transition: 0.6s;
}

.bg-menu ul li ul.bg-menu-submenu {
    width: 100%;
    height: 295px;
    z-index: 1;
    border-radius: 0 0 15px 15px;
    padding: 4px 90px;
    /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08); */
}

/* .bg-menu ul li ul.bg-menu-submenu:before{
    background-color: green;
    width: 5px;
    height: 2px;
    position: absolute;
    top : 0;
    left: 20px;
    } */
.bg-menu-submenu-list,
.bg-menu-submenu-img {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.bg-menu-submenu-img img {
    width: 100%;
    border-radius: 15px;
}

.bg-menu-submenu-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.bg-menu ul li ul.bg-menu-submenu h4 {
    margin: 5px 0 10px;
}

.bg-menu ul li ul.bg-menu-submenu h4 a {
    font-family: "DB Helvethaica X 65 Med";
    color: #464B55;
    text-align: left !important;
    margin: 20px 0;
    font-size: 32px;
    font-weight: 500;
    text-decoration: none;
    border-bottom: none !important;
}

.bg-menu ul li ul.bg-menu-submenu ul {
    display: flex;
    flex-flow: column wrap;
    height: 210px;
    justify-content: start !important;
    align-items: unset !important;
    padding-left: 0 !important;
}

.bg-menu ul li ul.bg-menu-submenu li {
    margin: -2px 0;
    text-align: left;
    padding: unset !important;
}

.bg-menu ul li ul.bg-menu-submenu ul li a {
    font-family: "DB Helvethaica X 55 Regular";
    color: #989CA6;
    font-size: 17px;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    text-transform: capitalize;
    border-bottom: none !important;
}

.bg-menu ul li ul.bg-menu-submenu ul li:hover a {
    color: #989CA6;
    border-bottom: none !important;
}

.default-store {
    font-family: "DB Helvethaica X MEDIUM" !important;
    font-size: 17px;
    color: #61A60E;
    font-weight: 500;
    margin-left: 5px;
    margin-bottom: 0;
}

.store-loc-link {
    position: relative;
}

.store-location-details {
    /* display: none; */
    position: absolute;
    top: 65px;
    right: 15px;
    z-index: 111;
}

/* .store-loc-link:hover+.store-location-details {
    display: block;
    opacity: 1 !important;
} */
.css-1eovgeu {
    position: fixed !important;
    z-index: 999999 !important;
    inset: 0px !important;
}

.css-3j4hy8-MuiModal-root-MuiDialog-root {
    position: fixed !important;
    right: 0 !important;
    bottom: 0 !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 99999999 !important;
}

.visible-sm {
    display: none !important;
}

.hidden-sm {
    display: block !important;
}

.visible-md {
    display: none !important;
}

.hidden-md {
    display: block !important;
}

.location-button {
    border: none;
    background: #fff;

}

.location-store-main-container {
    display: inline !important;
}

@media screen and (min-width: 1300px){
    .bg-menu ul li ul.bg-menu-submenu {
        height: 340px;
    }
}

@media screen and (max-width: 991px) {
    .visible-md {
        display: block !important;
    }

    .hidden-md {
        display: none !important;
    }

    .displayNoneMD {
        display: none !important;
    }

    .bg-header {
        padding: 20px 20px 0;
    }

    .bg-menu {
        padding: 0px 0 20px;
    }

    .bg-header-left {
        width: 40%;
    }

    .bg-header-right {
        width: 60%;
    }

    .bg-header-left a svg {
        display: block;
        width: 100%;
    }

    .bg-Header-dropdown button svg {
        width: 13px;
        display: block;
    }

    .bg-header-icon a svg {
        width: 15px;
        display: block;
    }

    .bg-header-right ul li {
        padding: 0 15px;
        cursor: pointer;
    }

    .bg-header-right li span {
        font-size: 15px;
    }

    .location-button svg {
        width: 16px;
        height: 16px;
    }

    span.item-count {
        font-size: 14px !important;
    }

    .bg-menu ul {
        padding: 0;
    }

    .bg-menu ul li {
        padding: 7px;
    }

    .bg-menu ul li a {
        font-size: 15px;
    }

    .bg-menu ul li ul.bg-menu-submenu ul li a {
        font-size: 15px;
        line-height: 1.5;
    }

    .bg-menu ul li ul.bg-menu-submenu h4 a {
        margin: 10px 0;
        font-size: 20px;
    }

    .bg-menu ul li ul.bg-menu-submenu {
        height: 270px;
        padding: 20px;
    }

    .bg-menu ul li ul.bg-menu-submenu ul {
        height: 210px;
    }

    .searchMobImg {
        width: 15px;
        margin-right: 2px;
    }

    .default-store {
        font-size: 16px;
    }

    .logIn-Btn {
        font-size: 15px;
        padding: 5px 20px;
        background-position: 83% center;
        background-size: 6px;
        padding-right: 35px;
    }
}

@media screen and (max-width: 767px) {
    .visible-sm {
        display: block !important;
    }

    .hidden-sm {
        display: none !important;
    }

}

@media screen and (max-width: 576px) {
    .header-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .bg-header {
        flex: 80%;
        padding: 10px 0 10px 10px;
        z-index: unset;
    }

    .bg-header-left a svg {
        display: block;
        width: 100%;
    }

    .bg-header-right ul li {
        padding: 0 5px;
    }
    .bg-header-left {
        width: unset;
    }

    .bg-header-right{
        width: unset;
    }

    .bg-menu-mob {
        flex: 5%;
        padding: 0 10px 0 0;
    }

    .bg-menu-mob .navbar {
        box-shadow: none;
        padding: 0;
        margin: 0;
    }

    .bg-menu-mob-body .offcanvas-body {
        overflow: unset;
        padding: 0 15px;
    }

    .userIconxs img {
        width: 30px;
    }

    .bg-menu-mob-body .nav-item,
    .bg-menu-mob-body .navbar-nav .nav-link{
        font-size: 24px;
        color: #464B55;
        text-transform: capitalize;
        background-image: url(./Assets/SVG/LeftArrowBlack.svg);
        background-repeat: no-repeat;
        background-position: 95% center;
        background-size: 7px;
    }
    .bg-menu-mob-body .navbar-nav .nav-link{
        padding: 0 20px;
    }
    
    .bg-menu-mob-body .navbar-nav .nav-link:first-child{
        background-image: none;
        padding-top: 20px;
    }
    /* .bg-menu-mob-body .nav-item[aria-expanded="false"]::after,
    .bg-menu-mob-body .navbar-nav .nav-link[aria-expanded="false"]::after {
        content: "";
        background-image: url(./Assets/SVG/LeftArrowBlack.svg);
        background-repeat: no-repeat;
        background-position: 95% center;
        background-size: 7px;
        transition: transform .2s ease-in-out;
    }

    .bg-menu-mob-body .nav-item[aria-expanded="true"]::after,
    .bg-menu-mob-body .navbar-nav .nav-link[aria-expanded="true"]::after {
        content: "";
        background-image: url(./Assets/SVG/LeftArrowBlack.svg);
        background-repeat: no-repeat;
        background-position: 95% center;
        background-size: 7px;
        transition: transform .2s ease-in-out;
       transform:  rotate(90deg)
    } */

    .bg-menu-mob-body .dropdown-menu {
        border: none;
        box-shadow: none;
    }

    .bg-menu-mob-body .dropdown-item,
    .bg-menu-mob-body .dropdown-item a,
    .bg-menu-mob-body .dropdown-item a:focus,
    .bg-menu-mob-body .dropdown-item a:visited,
    .bg-menu-mob-body .dropdown-item a:target {
        display: block;
        width: 100%;
        clear: both;
        font-weight: 400;
        font-size: 22px;
        color: #464B55;
        background-color: transparent;
        border: 0;
        padding: 0;
    }

    .bg-menu-mob-body .dropdown-toggle::after {
        border: none !important;
    }

    .bg-menu-mob-body .offcanvas-header {
        padding: 20px;
        font-size: 12px;
        color: #464B55;
    }

    .navLink {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 16px;
        color: #464B55;
    }

    .navLink-lt {
        flex: 50%;
        display: flex;
        justify-content: flex-start;
    }
    .navLink-lt p{
        color: #464B55;
        font-size: 24px;
        font-weight: 400;
    }

    .navLink-rt {
        flex: 50%;
        display: flex;
        justify-content: flex-end;
    }

    .navLink-rt span {
        padding: 5px 10px;
        background-color: #f5f6f7;
        border-radius: 25px;
        font-size: 20px;
        color: #464B55;
    }
    .header-userNameMob{
        display: flex;
    align-items: flex-start;
    }
    .header-userNameMob img{
        width: 40px;
        margin-right: 10px;
        margin-top: 7px;
    }
    .header-userNameMob h4{
        color: #464B55;
    font-size: 24px;
    text-transform: capitalize;
    margin-bottom: 0;
    }
    .header-userNameMob p{
        display: flex;
        align-items: center;
        justify-content: start;
    }
    .header-userNameMob p span{
        color: #989CA6;
        font-size: 17px;
        text-transform: uppercase;
        font-weight: 400;
    }
    .header-userNameMob p img{
        width: 12px;
        margin-right: 5px;
        margin-top: unset;
    }

}