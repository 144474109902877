.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  background: #fcfcfc;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 1px 2px 10px -1px rgba(18, 204, 58, 0.3);
}

.carousel .slick-prev {
  left: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.carousel .slick-next {
  right: 0;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.custom-indicator {
  bottom: -55px;
}

.custom-indicator li {
  width: 50px;
  height: 50px;
  filter: grayscale(100%);
}

.custom-indicator li.slick-active {
  filter: grayscale(0%);
}

.multi__image:hover {
  transform: scale(1.1);
  transition: 500ms;
}

.heart {
  font-size: 20px;
  color: rgb(182, 173, 173);
  margin-top: 7px;
  width: 70px;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
}

.heart:hover {
  color: rgb(192, 39, 39);
}

.heart.active {
  color: rgb(192, 39, 39);
}


.card {
  position: relative;
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.card-front,
.card-back {
  position: absolute;
  font-size: 28pt;
  line-height: 100px;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background: #e6e7ed;
  transition: transform .6s cubic-bezier(0.4, 0.0, 0.2, 1);
  backface-visibility: hidden;
}

.card-back {
  background: #f0ebda;
  transform: rotateY(180deg);
  user-select: none;
}

.card:hover .card-front {
  transform: rotateY(180deg);
}

.card:hover .card-back {
  transform: rotateY(0) rotateZ(0);
}

.flip-z .card-back {
transform: rotateY(180deg) rotateZ(50deg);
}

.flip-z:hover .card-front {
  transform: rotateY(180deg) rotateZ(50deg);
}
