@import "../index.css";

.product-review-container {
    box-sizing: border-box;
    font-family: 'DB Helvethaica X 55 Regular';
}

.pr-carousel-item:hover {
    cursor: pointer;
    /* transform: scale(1.2); */
}

.product-review-container {
    margin: 50px 0 0;
}

.pr-carousel-container{
    margin: 30px 0 90px;
}

.pr-carousel-container .carousel-control-next-icon,
.pr-carousel-container .carousel-control-prev-icon{
display: none;
}
.pr-carousel-container .carousel-indicators {
    display: flex;
    flex-direction: row;
    gap: 10px;
    bottom: -11%;
    left: 5%;
    z-index: unset;
    align-items: center;
}

.pr-carousel-container .carousel-indicators button.active {
    background: #61a60e;
    border: none;
    height: 10px;
    width: 22px;
    border-radius: 17px;
}

.pr-carousel-container .carousel-indicators button {
    border: none;
    background: #D3D6DA;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    gap: 10px;
}

.pr-carousel-Head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    padding: 0 50px;
}

/* .pr-carousel .kVtMtA{
    left: 36px!important;
} */

.pr-carousel .sc-bdVaJa.etbmwA,
.pr-carousel .sc-bdVaJa.etbmwA {
    /* display: none !important; */
}

.pr-carousel-Head h4 {
    color: #464B55;
    font-size: 56px;
    margin-top: 9px;
    font-weight: 500;
    font-family: "DB Helvethaica X 65 Med"!important;
}

.pr-carousel-Head img {
    width: 40px;
    margin-right: 20px;
}

.pr-carousel-container .carousel-item{
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.pr-carousel-item {
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    flex: 30%;
    background-color: #fff;
    /* margin: 37px; */
    border-radius: 15px;
    margin: 1%;
}
.startImg{
    width: 10px !important;
    margin-right: 3px;
}

.pr-carousel-item-img {
    margin-bottom: 20px;
}

.pr-carousel-item-img img {
    width: 100%;
    border-radius: 15px;
}

.pr-carousel-item-rating {
    margin-bottom: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pr-carousel-item-rating .star-icon {
    color: #464B55;
    margin-left: 3px;
}

.pr-carousel-item-rating .star-icon-checked {
    color: #F5B335;
    margin-left: 3px;
}

.pr-carousel-item-rating img {
    width: 20px;
}

.rating-count,
.total-rating {
    color: #F5B335;
    font-size: 20px;
    margin-bottom: 0;
    padding-bottom: 0;
}

.pr-carousel-item-text {
    margin-top: 15px;
}

.pr-carousel-item-text h4 {
    color: #181B21;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 2px;
}

.pr-carousel-item-text p {
    color: #989CA6;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}

.pr-carousel-item-profile {
    display: flex;
    margin-top: 20px;
}

.pr-carousel-item-profile-lt,
.pr-carousel-item-profile-rt {
    flex: 10%;
}

.pr-carousel-item-profile-rt {
    flex: 90%;
    padding-left: 20px;
}

.pr-carousel-item-profile-lt img {
    width: 40px;
    height: 40px;
    border: 1px solid #eee;
    border-radius: 50%;
    background-color: #D9D9D9;
}

.pr-carousel-item-profile-rt h4 {
    color: #464B55;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
}

.pr-carousel-item-profile-rt p {
    font-size: 16px;
    color: #989CA6;
    font-weight: 400;

}

.product-reviwing-paragraph {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 16px !important;
}


@media screen and (max-width: 991px){
    .product-review-container {
        margin: 25px 0 0;
    }    
    .pr-carousel-item {
        flex: 47%;
        margin: 1%;
    }
    .pr-carousel-container .carousel-indicators {
        bottom: -12%;
    }
    .pr-carousel-Head{
        padding: 0 10px;
    }
    .pr-carousel-Head h4 {
        font-size: 36px;
    }
    .pr-carousel-container {
        margin: 20px 0 60px;
    }
}
@media screen and (max-width: 768px){
    .pr-carousel-container .css-79ws1d-MuiModal-root{
        display: none;
    }
    .pr-carousel-container .carousel-indicators {
        bottom: -11%;
    }    
    .pr-carousel-Head{
        padding: 0;
    }
    .pr-carousel-Head h4 {
        font-size: 30px;
    }
    .pr-carousel-item {
        flex: 100%;
        margin: unset;
        padding: 10px;
    }
    .product-review-container {
        margin: 20px 0 0;
    }
    
    .pr-carousel-container{
        margin: 10px 0 60px;
    }
}
.product-review-container .carousel-fade .carousel-item {
    display: flex!important;
}
.product-review-image-main-shadow {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 12px 12px 16px;
    margin-left: 6px;
    margin-right: 6px;
}
.product-review-shadow-main-container {
    margin-bottom: 40px;
    margin-left:10px;
    margin-right: 10px;
}
.product-review-desktop-main-container {
    margin-top: 37px;
    margin-bottom: 37px;
}
.product-review-mobile-main-container {
    display: none;
}
.cursor-pointer {
    cursor: pointer!important;
}