.hide {
  display: none;
}

.headerFixed{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.headerFixed-margin{
  margin-top:141px !important;
}

.logIn-firstTime-outer {
  width: 100%;
  display: flex;
}

.loginheaderimage {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
}

.loginheaderimage img {
  width: 200px;
  /* margin-top: 30px; */
}

.logIn-firstTime {
  width: 580px;
  background: #FFFFFF;
  box-shadow: 0px 4px 80px rgb(0 0 0 / 8%);
  border-radius: 15px;
  margin: 60px auto;
  padding: 30px;
}

.logIn-firstTime h2 {
  font-family: "DB Helvethaica X 65 Med" !important;
  font-size: 56px;
  font-weight: 500;
  color: #464B55;
}

.logIn-firstTime h6 {
  font-family: "DB Helvethaica X 55 Regular" !important;
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0 18px;
}

.logIn-firstTimeBtn {
  font-family: "DB Helvethaica X 55 Regular" !important;
  width: 100%;
  outline: none;
  background-color: #61a60e !important;
  border: 2px solid #61a60e;
  height: 56px;
  border-radius: 30px;
  text-transform: none;
  font-size: 24px;
  color: #fff;
  margin-top: 30px;
}

.logIn-firstTime .form-group input[type=text],
.logIn-firstTime .form-group input[type=password] {
  font-family: "DB Helvethaica X 55 Regular" !important;
  height: 56px;
  border-radius: 6px;
  margin-bottom: 22px;
  background-color: #F5F6F7;
  border: 2px solid #F5F6F7;
  outline: none;
  box-shadow: none;
  padding: 10px 16px;
  font-size: 20px;
}

.logIn-firstTime .form-group input[type=text]:focus,
.logIn-firstTime .form-group input[type=password]:focus {
border: 2px solid #61A60E;
background-color: #fff;
}

.invalid-feedback.firstTimeLogin {
  font-family: "DB Helvethaica X 55 Regular" !important;
  font-size: 20px !important;
  color: #EF3145;
  margin: 25px 0 0px;
  line-height: unset;
  position: unset !important;
  line-height: 0;
  font-weight: 400;
}

.returnLogin {
  text-align: center;
  margin: 25px 0 15px;
}

.returnLogin a,
.returnLogin a:hover,
.returnLogin a:active,
.returnLogin a:visited {
  font-family: "DB Helvethaica X 55 Regular" !important;
  font-weight: 400;
  color: #61A60E;
  margin-top: 10px;
  text-transform: none;
  font-size: 24px !important;
  padding-bottom: 0px;
  background-color: transparent !important;
  border-bottom: 1px solid #61A60E;
  text-decoration: none;
}

input.form-control.custom-focus {
  border-color: #61A60E;
  box-shadow: 0 0 0 0.1rem #61A60E;
}

input.form-control.custom-focus1 {
  border-color: #61A60E;
  box-shadow: 0 0 0 0.1rem #61A60E;
}

.first-time-login-main {
  font-weight: 600;
  font-size: 26px;
}

.logIn-firstTime .btn-link:hover {
  text-decoration: none !important;
  color: #61A60E !important;
}
.login-radio-button-main-container .css-xti2a1-MuiButtonBase-root-MuiRadio-root:hover {
  background-color: transparent!important;
}
.login-radio-button-main-container .css-kmin1i:hover {
  background-color: transparent!important;
}
.login-radio-button-main-container .css-xti2a1-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #61A60E!important;
}
.login-radio-button-main-container .css-kmin1i.Mui-checked {
  color: #61A60E!important;
}
.login-radio-button-main-container .css-xti2a1-MuiButtonBase-root-MuiRadio-root {
  color: #BFC2C9;
}
.login-radio-button-main-container .css-kmin1i {
  color: #BFC2C9;
}

.logIn-firstTime-radioBtn label span.MuiTypography-root {
  font-family: "DB Helvethaica X 55 Regular" !important;
  color: #464B55;
  font-size: 20px;
  font-weight: 400;
}
.logIn-firstTime-radioBtn label span.MuiTypography-root::-moz-selection { 
  font-weight: 600;
}
.logIn-firstTime-radioBtn label span.MuiTypography-root::selection {
  font-weight: 600;
}
.logIn-firstTime.logInFirstTimeError .form-control.is-invalid {
  background-position: right 15px center;
  background-size: 16px;
}
.logIn-firstTime.logInFirstTimeError .invalid-feedback{
  margin: -15px 0 25px !important;
}

@media screen and (max-width: 991px) {
  .logIn-firstTime {
    margin: 40px auto;
  }

  .logIn-firstTime h2 {
    font-size: 50px;

  }
  .headerFixed-margin{
    margin-top: 95px !important;
  }
}

@media screen and (max-width: 767px) {
  .logIn-firstTime-outer{
    padding: 20px;
  }
  .logIn-firstTime {
    width: 100%;
    margin: 0;
    /* padding: 20px 15px 10px; */
    padding: 0;
    box-shadow: none;
    border-radius: none;
  }
  .logIn-firstTime h2 {
    font-size: 40px;
  }
  .loginheaderimage{
    display: none;
  }
  .logIn-firstTime .form-group input[type=text], .logIn-firstTime .form-group input[type=password]{
    height: 44px;
    padding: 10px;
  }
  .logIn-firstTimeBtn{
    height: 44px;
    margin: 0;
    margin-top: 10px;
    font-size: 20px;
  }
  .returnLogin a, .returnLogin a:hover, .returnLogin a:active, .returnLogin a:visited{
  font-size: 20px !important;

  }
  .returnLogin{
    margin: 15px 0;
  }
.logIn-firstTime.logInFirstTimeError .form-control.is-invalid {
  background-position: right 10px center;
  background-size: 14px;
}
}

@media screen and (max-width: 576px) {
  .headerFixed-margin{
    margin-top: 60px !important;
  }
}
