
.hide {
    display: none;
  }
   .password-form{
    /* padding-top: 24px; */
   }

   .logIn-firstTime .invalid-feedbackMsg {
    width: 100% !important;
    margin: -15px 0 !important;
    color: #dc3545;
    font-size: 20px!important;
    font-weight: 400;
   }

   input.form-control.custom-focus{
    border-color: #61A60E;
    box-shadow: 0 0 0 0.1rem #61A60E;
}

input.form-control.custom-focus1{
    border-color: #61A60E;
    box-shadow: 0 0 0 0.1rem #61A60E;
}
.form-control{
font-size: 14px;
}
.passwordIcon1{
  width: 16px;
  height: 16px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.passwordIcon2{
  width: 16px;
  height: 16px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.confirmpassword{
font-size: 14px;
}
.show-confirm-password-margin{
  padding-top: 24px!important;
  font-size: 14px;
}

.logIn-firstTime .invalid-feedback {
  width: 100%;
  font-size: 0.875em;
  color: #dc3545;
  position: unset !important;
  margin: 10px 0 25px !important;
  line-height: 24px;
}

.logIn-firstTime .form-control.is-invalid {
  background-position: right 40px center;
  background-size: 16px;
}

/* color: #dc3545; */

@media screen and (max-width: 767px){
  .passwordIcon1{
    width: 14px;
    height: 14px;
    top: 14px;
    right: 14px;
  }
  .passwordIcon2 {
    width: 14px;
    height: 14px;
    top: 14px;
    right: 14px;
}
.logIn-firstTime .form-control.is-invalid {
  background-position: right 35px center;
  background-size: 14px;
}

}