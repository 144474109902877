.mb-20{
    margin-bottom: 20px;
}
.myAccountCard label {
    font-size: 20px;
    color: #464B55;
    font-weight: 500;
}

.myAccountCard input[type="password"], .myAccountCard input[type="text"] {
    width: 100%;
    outline: none;
    background: #F5F6F7;
    border-radius: 8px;
    height: 48px;
    padding: 12px 16px;
    font-size: 20px !important;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 2px solid #F5F6F7;
    /* margin-bottom: 15px; */
    caret-color: #61A60E;
}

.myAccountCard input[type="password"]:focus, 
.myAccountCard input[type="text"]:focus{
background-color: #fff;
border: 2px solid #61A60E;
}

.myAccountCard input[type="password"] img {
    width: 16px;
    opacity: .8;
}

/* .myAccountCard input[type="password"]:focus, 
.myAccountCard input[type="text"]:focus{
    background-color: #fff;
    border: 2px solid #61A60E;
} */

.myAccountCard input[type="password"]:focus img {
    width: 16px;
    opacity: unset;
}

.myAccountCard-changePasswordBtn {
    padding: 5px 25px;
    width: 160px;
    background: #61A60E;
    border-radius: 25px;
    color: #fff;
    font-weight: 400;
    font-size: 20px;
    outline: none;
    border: 1px solid #61A60E;
    margin: 30px 0 20px;
    height: 48px;
    cursor: pointer;
}
.myAccountCard-changePasswordBtnDsbld {
    padding: 5px 25px;
    min-width: 160px;
    background: #EDEFF2;
    border-radius: 25px;
    color: #989CA6;;
    font-weight: 400;
    font-size: 20px;
    outline: none;
    border: 1px solid #EDEFF2;
    margin: 30px 0 20px;
    height: 44px;
    cursor: pointer;
}

p.myAccountCard-ChangePasswordInstruction {
    font-size: 20px;
    font-weight: 400;
    color: #989CA6;
    word-wrap: break-word;
    margin: 10px 0 15px !important;
}

p.myAccountCard-ChangePasswordWarning {
    font-size: 20px;
    font-weight: 400;
    /* color: #989CA6; */
    word-wrap: break-word;
    color: #EF3145 !important;
    margin: 10px 0 15px !important;
    
}
.myAccountCard-ChangePasswordInput-ErrorBorder{
    border: 2px solid #EF3145 !important;
    background-color: #fff !important;
}

.myAccountCard-ChangePasswordInput {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
}

.myAccountCard-ChangePassword-eyeIcon {
    position: absolute;
    right: 19px;
    top: 53px;
    width: 16px !important;
    cursor: pointer;
}

.myAccountCard-ChangePassword-crosseyeIcon{
    position: absolute;
    right: 17px;
    top: 53px;
    width: 16px !important;
    cursor: pointer;
}

.myAccountCard-ChangePassword-warningIcon {
    position: absolute;
    right: 42px;
    top: 52px;
    cursor: pointer;
}


.myAccountChangePasswordModal .modal-body{
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.myAccountChangePasswordModal .modal-content{
    border-radius: 16px;
}

.myAccountChangePasswordModal img {
    width: 90px;
    margin-bottom: 20px;
}

.myAccountChangePasswordModal h4 {
    font-weight: 500;
    font-size: 48px;
    color: #464B55;
}

.myAccountChangePasswordModal p{
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
}

.fade.myAccountChangePasswordModal {
    opacity: 1 !important;
    background-color: rgba(24, 27, 33, 0.4) !important;
    transition: none !important;
}


.myAccountChangePasswordModal .modal.fade .modal-dialog {
    transition: none;
    width: 100%;
}

.myAccountChangePasswordModal .modal-dialog {
    margin: 0;
    position: absolute;
    bottom: 0;
    border: none;
    right: 0;
    left: 0;
}

.myAccount-headerFixed{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
}


.myAccountChangePasswordModal .modal.show .modal-dialog {
    transform: none;
    width: 100%;
}

@media (min-width: 576px) {
    .myAccountChangePasswordModal .modal-dialog {
        max-width: 570px !important;
        margin: auto;
    }
}

@media screen and (max-width: 767px){
    .myAccountCard-changePasswordBtn{
        width: 100%;
        padding: 0 25px;
        margin: 30px 0 10px;
    }
    .myAccountCard-changePasswordBtnDsbld{
        width: 100%;
        padding: 0 25px;
        margin: 30px 0 10px;
    }
    .myAccountChangePasswordModal .modal-content{
        border-radius: 16px 16px 0 0;
    }
    .myAccountChangePasswordModal .modal-dialog-centered {
        align-items: end;
    }
    .myAccountChangePasswordModal img {
        width: 60px;
        margin-bottom: 20px;
    }
    
    .myAccountChangePasswordModal h4 {
        font-size: 42px;
    }
    
    .myAccountChangePasswordModal p{
        font-size: 20px;
    }
    .myAccountCard input[type="password"], .myAccountCard input[type="text"]{
        padding: 12px 14px;
        height: 44px;
    }
    .new-address-input-field-height {
        height: 68px!important;
    }
    p.myAccountCard-ChangePasswordInstruction {
        margin: 5px 0 0 !important;
    }
    p.myAccountCard-ChangePasswordWarning {
        margin: 5px 0 0 !important;
    }
    .myAccountCard-ChangePassword-crosseyeIcon {
        top: 60px;
    }
    .myAccountCard-ChangePassword-eyeIcon{
        top: 60px;
    }
    .mb-20{
        margin-bottom: 15px;
    }
    .myAccountCard-ChangePassword-warningIcon{
        top: 59px;
    }
    .myAccountCard-ChangePasswordInput {
        margin-bottom: 5px;
    }
}