  .panel-default>.panel-heading {
      color: #333;
      background-color: #fff;
      border-color: #e4e5e7;
      padding: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }

  .panel-default>.panel-heading a {
      display: block;
      padding: 10px 15px;
  }

  .panel-default>.panel-heading a:after {
      content: "";
      position: relative;
      top: 1px;
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: transform .25s linear;
      -webkit-transition: -webkit-transform .25s linear;
  }

  .panel-default>.panel-heading a[aria-expanded="true"] {
      background-color: #eee;
  }

  .panel-default>.panel-heading a[aria-expanded="true"]:after {
      content: "\2212";
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
  }

  .panel-default>.panel-heading a[aria-expanded="false"]:after {
      content: "\002b";
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
  }

  .padding-lt {
      padding-left: 40px;
  }

  .cstmrProdDeatils {
      font-family: "DB Helvethaica X 55 Regular" !important;
      background-color: #fff;
  }

  .cstmrProdDeatilsCarousel .carousel .thumbs-wrapper {
      margin: 15px 0 0;
      overflow: hidden;
  }

  .cstmrProdDeatilsCarousel .carousel .thumbs-wrapper ul {
      padding-left: 0 !important;
  }

  .cstmrProdDeatilsCarousel {
      width: 100%;
      /* display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; */
      background-color: #fff;
  }

  .cstmrProdDeatilsCarousel-Img {
      border: 1px solid #ddd;
      border-radius: 20px;
      position: relative;
      height: 420px;
      width: 100%;
  }

  .cstmrProdDeatilsCarousel-Img-Badge {
      position: absolute;
      top: 20px;
      left: 20px;
  }

  .cstmrProdDeatilsCarousel-Img-Badge img {
      width: 110px !important;
  }

  .cstmrProdDeatilsCarousel-Img img {
      width: 100% !important;
      height: 100%;
      object-fit: contain;
  }

  .cstmrProdDeatilsCarousel .control-dots {
      display: none !important;
  }

  .cstmrProdDeatilsCarousel .carousel-status {
      display: none !important;
  }

  .cstmrProdDeatilsCarousel .carousel.carousel-slider .control-arrow{
    background: none !important;
    opacity: unset !important;
    /* display: none; */
  }

  .cstmrProdDeatilsCarousel .carousel.carousel-slider:hover .cstmrProdDeatilsCarousel .carousel.carousel-slider .control-arrow {
      background: none !important;
      display: block;
  }

  .cstmrProdDeatilsCarousel .carousel .control-arrow:hover {
      opacity: unset;
  }

  .cstmrProdDeatilsCarousel .carousel .control-next.control-arrow:before {
      border: solid #BFC2C9;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 5px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
  }

  .cstmrProdDeatilsCarousel .carousel .control-prev.control-arrow:before {
      border: solid #BFC2C9;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 5px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
  }

  .cstmrProdDeatilsCarousel .carousel .thumb.selected,
  .cstmrProdDeatilsCarousel .carousel .thumb:hover {
      border: 2px solid #61A60E;
      border-radius: 10px;
  }

  .cstmrProdDeatilsCarousel .carousel .thumb {
      transition: border .15s ease-in;
      display: inline-block;
      margin-right: 6px;
      white-space: nowrap;
      overflow: hidden;
      border: 1px solid #D3D6DA;
      border-radius: 5px;
      height: 80px;
      width: 80px;
  }

  .cstmrProdDeatilsCarousel .carousel .thumb img {
      height: 100%;
      width: 100%;
      object-fit: contain;
  }

  .cstmrProdDeatilsText {
      width: 100%;
  }

  .cstmrProdDeatilsText-head h2 {
    font-family: "DB Helvethaica X 55 Regular" !important;
    font-size: 24px;
      color: #989CA6;
      margin: 0;
      font-weight: 400;
      line-height: 1.3;
  }

  .cstmrProdDeatilsText-head h3 {
    font-family: "DB Helvethaica X 65 Med" !important;
      font-size: 48px;
      color: #464B55;
      margin: 0;
      font-weight: 500;
      /* line-height: 1; */
      line-height: .9;
      text-transform: capitalize;
      margin-top: 8px;
  }

  .cstmrProdDeatilsText-headPrice {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;
  }

  .cstmrProdDeatilsText-headPrice h4 {
    font-family: "DB Helvethaica X 55 Regular" !important;
      font-size: 36px;
      margin: 16px 10px 0px 0px;
      font-weight: 700;
      color: #61A60E;
      line-height: 1.5;
  }

  .cstmrProdDeatilsText-headPrice h4 span {
    font-family: "DB Helvethaica X 55 Regular" !important;
      font-weight: 400;
      font-size: 26px;
      color: #464B55;
      text-transform: lowercase;
  }

  .cstmrProdDeatilsText-headPrice h5 {
      color: #989CA6;
      font-size: 30px;
      font-weight: 400;
      text-decoration: line-through;
      margin-right: 10px;
  }

  .cstmrProdDeatilsText-headPrice h6 {
      background-color: #FCD6DA;
      font-size: 12px;
      padding: 5px 10px;
      font-weight: 600;
      border: 1px solid #EFF6E7;
      color: #EF3145;
      border-radius: 12px;
      margin-right: 10px;
  }

  .cstmrProdDeatilsText-desc_u,
  .cstmrProdDeatilsText-desc {
      width: 100%;
  }

  .cstmrProdDeatilsText-desc_u h4,
  .cstmrProdDeatilsText-desc h4 {
    font-family: "DB Helvethaica X 55 Regular" !important;
      font-size: 24px;
      color: #464B55;
      margin: 0;
      font-weight: 600;
      line-height: 3;
  }

  .cstmrProdDeatilsText-desc_u button {
    font-family: "DB Helvethaica X 55 Regular" !important;
      min-width: 110px;
      font-size: 24px;
      padding: 5px 30px;
      border: 2px solid #F5F6F7;
      background-color: #F5F6F7;
      margin-bottom: 10px;
      border-radius: 30px;
      outline: none;
      margin-right: 10px;
  }
  .cstmrProdDeatilsText-desc button {
    font-family: "DB Helvethaica X 55 Regular" !important;
    min-width: 110px;
    font-size: 24px;
    padding: 5px 30px;
    border: 2px solid #F5F6F7;
    background-color: #F5F6F7;
    margin-bottom: 10px;
    border-radius: 30px;
    outline: none;
    margin-right: 5px;
    text-transform: capitalize;
}
  .text-tranform {
    text-transform: capitalize;
  }

  /* .cstmrProdDeatilsText-desc button:focus {
      font-weight: 600;
      border: 2px solid #61A60E;
      color: #61A60E;
      background-color: #EFF6E7;
  } */

  .cstmrProdDeatilsText-desc-focus {
    font-family: "DB Helvethaica X 55 Regular" !important;
      font-weight: 600 !important;
      border: 2px solid #61A60E !important;
      color: #61A60E !important;
      background-color: #EFF6E7 !important;
      /* text-transform: lowercase; */
      font-size: 24px;
  }

  .cstmrProdDeatilsTexts-desc-focus {
    font-family: "DB Helvethaica X 55 Regular" !important;
    font-weight: 600 !important;
    border: 2px solid #61A60E !important;
    color: #61A60E !important;
    background-color: #EFF6E7 !important;
    text-transform: capitalize;
}

  .cstmrProdDeatilsText-quantity {
      width: 100%;
  }

  .cstmrProdDeatilsText-quantity h4 {
    font-family: "DB Helvethaica X 55 Regular" !important;
      font-size: 24px;
      color: #464B55;
      margin: 0;
      font-weight: 600;
      line-height: 2.8;
  }

  .cstmrProdDeatilsText-quantity button {
      outline: none;
      border: none;
      border-radius: 25px;
      color: #464B55;
      background-color: #F5F6F7;
      font-size: 24px;
      padding: 0px;
      border-radius: 20px;
  }

  .cstmrProdDeatilsText-quantity button span:nth-child(1) {
      padding: 7px 10px;
      border-radius: 10px 0 0px 10px;
  }

  .cstmrProdDeatilsText-quantity button span:nth-child(2) {
      padding: 7px 35px;
  }

  .cstmrProdDeatilsText-quantity button span:nth-child(3) {
      padding: 7px 10px;
      border-radius: 0 10px 10px 0;
  }

  .cstmrProdDeatilsText-quantity-inStock {
    font-family: "DB Helvethaica X 55 Regular" !important;
      background-color: #EFF6E7;
      font-size: 20px;
      padding: 0px 16px;
      font-weight: 600;
      border: 1px solid #EFF6E7;
      color: #61A60E;
      border-radius: 30px;
      margin-left: 10px;
      height: 32px;
  }

  .cstmrProdDeatilsText-quantity-outOfStock {
    font-family: "DB Helvethaica X 55 Regular" !important;
    background-color: #F5F6F7;
    font-size: 20px;
    padding: 4px 16px;
    font-weight: 600;
    border: 1px solid #F5F6F7;
    color: #989CA6;
    border-radius: 30px;
    margin-left: 15px;
}

  .cstmrProdDeatilsText-btn {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      align-items: center;
      margin-top: 40px;
      height: 40px;
  }

  .cstmrProdDeatilsText-btn p {
      color: #61A60E;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 0;
      font-weight: 400;
  }

  .cstmrProdDeatilsText-btn p img {
      width: 15px;
      margin-right: 10px;
  }

  .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-addToCart {
    font-family: "DB Helvethaica X 55 Regular" !important;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    outline: none;
    font-size: 24px;
    font-weight: 400;
    padding: 10px 50px;
    border: 1px solid #61A60E;
    background-color: #61A60E;
    margin-bottom: 10px;
    border-radius: 35px;
    color: #fff;
  }

  .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-wishList {
    font-family: "DB Helvethaica X 55 Regular" !important;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    font-size: 24px;
    font-weight: 400;
    padding: 10px 20px;
    border: 1px solid #61A60E;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 35px;
    color: #61A60E;
  }

  /* .cstmrProdDeatilsText-wishList:hover {
      background-color: #61A60E !important;
      color: #fff !important;
  }

  .cstmrProdDeatilsText-wishListRed:hover {
      background-color: #EF3145 !important;
      color: #fff !important;
  } */

  .cir-prg-sty {
      padding-left: 50px !important;
      padding-right: 50px !important;
  }

  .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-wishListRed {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      outline: none;
      font-size: 24px;
      padding: 10px 20px;
      border: 1px solid#EF3145;
      background-color: #fff;
      margin-bottom: 10px;
      border-radius: 35px;
      color: #EF3145;

  }
  .product-category-details-gray-line {
    margin-top:40px;
  }

  .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-outOfStock {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      outline: none;
      font-size: 18px;
      padding: 10px 70px;
      border: 1px solid #EDEFF2;
      background-color: #EDEFF2;
      margin-bottom: 10px;
      border-radius: 25px;
      color: #989CA6;
  }

  .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-addToCart img,
  .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-wishList img,
  .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-wishListRed img {
      width: 18px;
      margin-right: 10px;
  }


  .cstmrProdDeatilsText-about {
      width: 100%;
      padding-bottom: 40px;
  }

  .cstmrProdDeatilsText-about .accordion-item {
      background-color: none !important;
      border: none !important;
  }

  .cstmrProdDeatilsText-about .accordion-item .accordion-button,
  .cstmrProdDeatilsText-about .accordion-button:not(.collapsed) {
    font-family: "DB Helvethaica X 55 Regular" !important;
    font-size: 24px !important;
    color: #464B55 !important;
    margin: 0 !important;
    font-weight: 600;
    box-shadow: none !important;
    line-height: 2.2;
    padding: 0 !important;
  }

  .cstmrProdDeatilsText-about .accordion-body {
      padding: 0 !important;
  }

  .cstmrProdDeatilsText-about .accordion-body p {
    font-family: "DB Helvethaica X 55 Regular" !important;
      font-size: 24px;
      color: #989CA6;
      font-weight: 400;
      margin: 0;
      line-height: 1.3;
  }

  .cstmrProdDeatilsText-about .accordion-body ul li {
    font-family: "DB Helvethaica X 55 Regular" !important;
      font-size: 24px;
      color: #989CA6;
      font-weight: 400;
      margin: 0;
  }

  .cstmrProdDeatilsText-addToCart:disabled,
  .cstmrProdDeatilsText-wishList:disabled {
      /* background-color: #464B55; */
      background: #EDEFF2;
      color: #989CA6;
  }

  @media screen and (min-width: 576px) {
      .display-none {
          display: none;
      }

      .cstmrProdDeatilsText-about .accordion-button::after {
          display: none !important;
      }

      .cstmrProdDeatilsText-about .accordion-collapse {
          display: block !important;
      }

      .cstmrProdDeatilsText-about .accordion-collapse {
          display: block !important;
      }

      .cstmrProdDeatilsText-about hr {
          display: none;
      }

      .cstmrProdDeatilsText-about hr:first-child {
          display: block;
      }

  }

  /* .sidenav {
    width: 100% !important;
    position: fixed !important;
    z-index: 1 !important;
    top: 190px !important;
    left: 0 !important;
    overflow-x: hidden !important;
    padding-top: 20px !important;
  }

  
  .main {
    margin-left: 50% !important;
  } */
  /* .sidenav {
    position: fixed !important;
  z-index: 1;
  top: 230px;
  left: 0;
  overflow-x: auto;
  height: 540px;
  }
  .main {
    margin-left: 50%;
  } */
  @media screen and (max-width: 1160px) {
      .cstmrProdDeatilsCarousel-Img {
          height: 350px;
      }

      .cstmrProdDeatilsText-about {
          padding-bottom: 30px;
      }
      .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-addToCart{
        padding: 10px 30px;
      }
  }

  @media screen and (min-width: 767px) {
    .cstmrProdDeatilsText-headPrice-count{
        display: none !important;
    }
  }

  @media screen and (max-width: 991px) {
      .hidden-md {
          display: none;
      }
      .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-addToCart{
        padding: 10px 70px;
      }


      /* .cstmrProdDeatilsText-head h3 {
          font-size: 26px;
          line-height: 1.3;
      }

      .cstmrProdDeatilsText-headPrice h4 {
          font-size: 26px;
          line-height: 1.3;
      }

      .cstmrProdDeatilsText-headPrice h4 span {
          font-size: 20px;
      }

      .cstmrProdDeatilsText-headPrice h5 {
          color: #989CA6;
          font-size: 22px;
      }

      .cstmrProdDeatilsText-desc_u button,
      .cstmrProdDeatilsText-desc button {
          font-size: 16px;
          padding: 5px 25px;
      }

      .cstmrProdDeatilsText-quantity button {
          font-size: 22px;
      }

      .cstmrProdDeatilsText-quantity button span:nth-child(2) {
          padding: 7px 30px;
      } */

      .cstmrProdDeatilsCarousel-Img {
          height: 300px;
      }

      /* .cstmrProdDeatilsText-about {
          padding-bottom: 20px;
      }

      .cstmrProdDeatilsText-btn {
          justify-content: space-around;
          margin-top: 30px;
          margin-bottom: 30px;
      }

      .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-addToCart,
      .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-wishListRed,
      .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-outOfStock,
      .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-wishList {
          font-size: 16px;
          padding: 10px;
          width: 160px;
      } */
  }

  @media screen and (max-width: 767px) {
    .cstmrProdDeatilsText{
        position: relative;
    }
    .cstmrProdDeatilsCarousel .carousel-status {
        display:  block !important;
    }
    .product-category-details-gray-line {
        margin-top: 10px;
    }
      .cstmrProdDeatilsCarousel {
          margin-bottom: 20px;
      }

      .cstmrProdDeatilsCarousel-Img {
          height: 250px;
      }

      .cstmrProdDeatilsCarousel .carousel .thumbs-wrapper {
          display: none;
      }
      .customBreadcrumb .breadcrumb li a {
        font-size: 16px;
    }
      .customBreadcrumb .breadcrumb {
          margin-bottom: 0 !important;
          padding: 0;
      }

      .cstmrProdDeatilsText-wishList img,
      .cstmrProdDeatilsText-wishListRed img {
          margin-right: unset !important;
      }

      .cstmrProdDeatilsText-btn {
          justify-content: space-around;
      }

      .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-wishList {
        /* order: 1; */
        padding: 15px;
        background-color: #F5F6F7;
        border: 1px solid #F5F6F7;
        border-radius: 50%;
        margin-bottom: unset;
        width: 44px;
        height: 44px;
        margin-right: 0;
      }

      .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-wishListRed {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
          /* order: 2; */
          padding: 15px;
          background-color: #EF3145;
          border: 1px solid #EF3145;
          margin-bottom: unset;
          width: 44px;
          height: 44px;
          border-radius: 50%;
      }

      .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-addToCart {
          /* order: 3; */
          width: 80%;
          margin: unset;
          padding: 10px;
          height: 44px;
      }

      .cstmrProdDeatilsText-btn .cstmrProdDeatilsText-outOfStock {
          /* order: 4; */
          width: 80%;
          height: 44px;
      }

      .width-20 {
          flex: 20%;
      }

      .width-80 {
          flex: 80%;
      }
      .cstmrProdDeatils .col-sm-12{
        padding: 0 !important;
    }
    .cstmrProdDeatils .col-sm-2{
        padding: 0 !important;
    }
    .add-sub-button{
        background-color: #61a60e;
        border: none;
        border-radius: 20px;
        color: #fff;
        font-size: 16px;
        outline: none;
        padding: 4px;
        text-align: center;
        width: 100%;
        margin-top: 2px;
        height: 44px;
    }
    h6.add-sub-button-count{
        text-align: center;
    margin-bottom: 0;
    font-size: 20px;
    margin-top: 4px;
    }
    .cstmrProdDeatilsText-desc_u h4, .cstmrProdDeatilsText-desc h4 {
        font-size: 20px;
        line-height: 2.5;
    }
    .cstmrProdDeatilsText-headPrice-count{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
    }
    .cstmrProdDeatilsText-headPrice h4 {
        font-size: 32px;
        line-height: 1.3;
        margin: 0;
    }
    .cstmrProdDeatilsText-quantity-outOfStock {
        padding: 0px 12px;
      }
      .cstmrProdDeatilsText-quantity-inStock{
        padding: 0px 12px;
        margin: 0;
      }
      .cstmrProdDeatilsText-desc button{
        font-size: 20px;
      }
      .cstmrProdDeatilsText-about .accordion-body ul li {
        font-size: 20px;
    }
    .cstmrProdDeatilsText-head h2{
        line-height: 1;
        font-size: 16px;
    }
  }

  @media screen and (max-width: 576px) {
    .product-category-details-gray-line {
        margin-top: 10px;
    }
    .cstmrProdDeatilsCarousel .carousel-status {
        color: #464B55;
        text-shadow: none;
        font-size: 16px;
        font-weight: 400;
        background: #f5f5f7;
        border-radius: 15px;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 50px;
        text-align: center;
    }

    .cstmrProdDeatils .col-12{
        padding: 0 !important;
    }
    .cstmrProdDeatils .col-2{
        padding: 0 !important;
    }
      .cstmrProdDeatils {
          padding: 10px 0 20px;
      }

      .cstmrProdDeatilsText-about {
          padding: 20px 0;
      }

      .cstmrProdDeatilsText-head h3 {
          font-size: 32px;
          line-height: 1.3;
      }

      .cstmrProdDeatilsText-headPrice h4 span {
          font-size: 24px;
      }
      .cstmrProdDeatilsText-about .accordion-body p {
        font-size: 20px;
        line-height: 1.2;
    }
      .cstmrProdDeatilsText-about .accordion-button::after,
      .cstmrProdDeatilsText-about .accordion-button:not(.collapsed)::after {
          color: #464B55;
      }

      .cstmrProdDeatilsText-about .accordion-item .accordion-button,
      .cstmrProdDeatilsText-about .accordion-button:not(.collapsed) {
          font-size: 20px !important;
          /* line-height: unset !important; */
      }


      .cstmrProdDeatilsText-about .accordion-button::after {
        background-size: 14px !important;
        background-image: url(Assets/SVG/DownArrowGrey.svg) !important;
        background-repeat: no-repeat;
        background-position: 95% center;
    }
    
    .bulkUploadCard-uploadFileHistory .accordion-button:not(.collapsed)::after {
        background-size: 14px !important;
        background-image: url(Assets/SVG/UpArrowGrey.svg) !important;
        background-repeat: no-repeat;
        background-position: 95% center;
    }
      .cstmrProdDeatilsText-quantityCount {
          display: flex;
          order: 5;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border: 1px solid #61A60E;
          background-color: #61A60E;
          font-size: 20px;
          width: 80%;
          padding: 4px 0;
          border-radius: 30px;
      }

      .cstmrProdDeatilsText-quantityCount span:nth-child(1) {
          padding: 5px 15px;
          background-color: #fff;
          color: #61A60E;
          border-radius: 50%;
          font-size: 20px;
      }

      .cstmrProdDeatilsText-quantityCount span:nth-child(2) {
          padding: 5px 50px;
          color: #fff;
          background-color: #61A60E;
          border-radius: 25px;
      }

      .cstmrProdDeatilsText-quantityCount span:nth-child(3) {
          padding: 5px 15px;
          background-color: #fff;
          color: #61A60E;
          border-radius: 50%;
          font-size: 20px;
      }

      .cstmrProdDeatilsText-desc_u button {
          font-size: 20px;
          padding: 5px 15px;
          text-transform: lowercase;
      }

      .promo-img-badge1 {
          position: absolute;
          top: 5px;
          left: 0;
      }

      .promo-img-badge1 img {
          width: 50px;
      }

      .cstmrProdDeatilsCarousel {
          margin-top: 15px;
      }
  }