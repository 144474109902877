/* (A) HIDE CHECKBOX */
.togCheck { display: none; }

/* (B) HIDE CONTENT BY DEFAULT */
.togContent {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: max-height 1s;
}

/* (C) SHOW CONTENT WHEN CHECKED */
.togCheck:checked + .togContent {
  max-height: 100vh; /* any insanely large number if a lot of contents */
  opacity: 1;
  visibility: visible;
}

/* (X) COSMETICS - DOES NOT MATTER */
/* (X1) ENTIRE DEMO */

/* (X2) TOGGLE BUTTON */
.togButton {
  display: block;
  width: 10%;
  padding: 10px;
  color: #fff;
  background:#fff;
  cursor: pointer;
}

/* (X3) CONTENT */
.togContent { background: #fff; }
.togContent p {
  padding: 50px 10px;
  margin: 0;
}



h1, p {
  font-family: Lato;
}
.btn-default {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  height: 28px;
  background: #eee;
  border: 1px solid rgb(23, 179, 67);
  border-radius: 2px;
}
.btn-default:hover,
.btn-default.active {
  color: #fff;
  background: rgb(19, 181, 54);
  border: 1px solid rgb(22, 25, 175)
}

.r-mr-gap-small {
  margin-right: 10px;
}

.r-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}





