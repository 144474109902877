.brightness {
    background-color: white;
    display: inline-block;
   
}
.brightness img:hover {
    opacity: .5;
    background-color: green;
    border: green;
}

