.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 0px !important;
    margin: 0px !important;
    width: 434px !important;
    height: auto !important;
}
.css-hz1bth-MuiDialog-container {
    height: unset!important;
}
.css-ekeie0 {
    height: unset!important;
}
.css-ohyacs {
    padding: 0px !important;
    margin: 0px !important;
    width: 434px !important;
    height: auto !important;
}
 
.added-to-my-card-inner-container {
    margin-top: 16px;
}

.add-to-card-heading {
    font-weight: 600;
    font-size: 24px;
    color: #464B55;
    margin-top: 6px;
}

.gray-line-added-to-card {
    border: 1px solid #D3D6DA;
    width: 100% !important;
    margin-top: 16px;
}

.added-to-card-image-container {
    margin-top: 24px;
}

.common-margin-added-to-card-model {
    margin-top: 4px;
    margin-bottom: 4px;
}

.added-my-card-modal-product-name {
    font-weight: 600;
    font-size: 20px;
    color: #464B55;
}

.added-my-card-quantity {
    color: #989CA6;
    font-size: 20px;
}

.added-to-card-modal-button {
    outline: none;
    background-color: #61a60e;
    color: #fff;
    font-size: 20px;
    width: 100%;
    border-radius: 32px;
    justify-content: center;
    padding: 10px 24px;
    border: none;
    margin-top: 24px;
    text-align: center;
    cursor: pointer;
}

.added-to-card-continue-shopping {
    text-align: center;
    color: #61A60E;
    font-size: 20px;
    font-weight: 500;
    margin-top: 16px;
    cursor: pointer!important;
    margin-bottom: 6px;
}

.added-to-card-cross-icon {
    cursor: pointer;
    text-align: center;
}
.add-to-cart-image-border {
    border: 1px solid #D3D6DA;
    border-radius: 12px;
    height: 72px;
    width: 72px;
    position: relative;
}
.add-to-cart-image-object {
    object-fit: contain;
    padding: 3px;
}
.added-to-card-continue-shopping::after {
    border: 1px solid #61a60e;
    bottom: 5%;
    font-weight: 500;
    height: 1px;
    left: 33%;
    position: absolute;
    width: 34%;
    content: "";
}
.added-to-modal-left-section {
    text-align: right;
}
.common-add-to-cart-modal-mobile-main-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 999;
    position: fixed;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    opacity: 1 !important;
    background-color: rgba(24, 27, 33, 0.4) !important;
    height: 100%;
}
.common-add-to-cart-modal-mobile-inner-container {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
    border-radius: 24px 24px 0px 0px;
    text-align: center;
    padding: 20px 13px 16px 16px;
    position: absolute;
    bottom: 0;
}
.common-add-to-cart-modal-mobile-main-container .common-add-to-cart-modal-gray-line {
    background: #EDEDED;
    border-radius: 100px;
    width: 48px;
    height: 5px;
    text-align: center;
    position: absolute;
    left: 48%;
    right: 50%;
    top: 6%;
}
.common-add-to-cart-modal-mobile-main-container .common-add-to-cart-modal-mobile-check-image {
    width: 16px;
    height: 16px;
    margin-bottom: 6px;
}
.common-add-to-cart-modal-mobile-main-container .common-add-to-cart-modal-mobile-add-to-cart-heading {
    font-weight: 500;
    font-size: 26px;
    color: #464B55;
    margin-left: 10px;
}
.common-add-to-cart-modal-mobile-main-container .common-add-to-cart-modal-gray-inner-line {
    border: 1px solid #D3D6DA;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
}
.common-add-to-cart-modal-mobile-main-container .common-add-to-cart-modal-product-border {
    width: 72px;
    height: 72px;
    border: 1px solid #D3D6DA;
    border-radius: 12px;
    position: relative;
}
.common-add-to-cart-modal-product-border .common-add-to-cart-image-badge {
    position: absolute;
    top: 0px;
    left: 6px;
}
.common-add-to-cart-modal-product-border .common-add-to-cart-promo-banner{
    height: 26px;
    width: 26px;
}

.common-add-to-cart-modal-mobile-main-container .common-add-to-cart-modal-product-image {
    width: 66px;
    height: 66px;
    padding: 2px;
    object-fit: cover;
}
.common-add-to-cart-modal-mobile-main-container .common-add-to-cart-modal-product-heading {
    font-weight: 500;
    font-size: 20px;
    color: #464B55;
    text-align: left;
}
.common-add-to-cart-modal-mobile-main-container .common-add-to-cart-modal-product-price {
    font-weight: 700;
    font-size: 20px;
    color: #464B55;
    text-align: end;
}
.common-add-to-cart-modal-mobile-main-container .common-add-to-cart-modal-product-quantity {
    font-weight: 500;
    font-size: 20px;
    color: #989CA6;
    text-align: end;
}
.common-add-to-cart-modal-view-cart-button {
    padding: 4px 24px;
    background: #61A60E;
    border-radius: 32px;
    margin-top: 16px;
    font-weight: 400;
font-size: 20px;
color: #FFFFFF;
}
.common-add-to-cart-modal-mobile-main-container .common-add-to-cart-modal-continue-shopping-heading {
    font-weight: 400;
    font-size: 20px;
    color: #61A60E;
    text-align: center;
    margin-top: 12px;
}
.add-to-cart-modal-desktop-block {
    display: block;
}
.common-add-to-cart-modal-continue-shopping-bottom-line {
    border: 1px solid #61A60E;
    width: 130px;
    margin-left: auto;
    margin-right: auto;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: 12px!important;
}
.css-uhb5lp {
    border-radius: 12px!important;
}
.add-to-cart-checkbox-right-image {
    width: 16px;
    height: 16px;
}
.add-to-cart-cross-image {
    width: 16px;
    height: 16px;
    float: right;
}
.common-margin-added-to-card-price-unit {
    font-weight: 700;
    font-size: 24px;
    color: #464B55;
}
.add-to-cart-view-button-margin-bottom {
    margin-bottom: 20px;
}
.add-product-card-image-badge {
    position: absolute;
    top: 8px;
    left: 8px;
}