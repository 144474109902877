.Store-loc{
    padding: 15px;
    width: 270px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    line-height: 1.5;
    cursor: default !important;
}

.Store-loc h4,
.Store-loc h4 a,
.Store-loc h4 a:hover,
.Store-loc h4 a:visited,
.Store-loc h4 a:active,
.Store-loc h4 a:focus,
.Store-loc h4 span,
.Store-loc h4 a span,
.Store-loc h4 a span:hover,
.Store-loc h4 a span:visited,
.Store-loc h4 a span:active,
.Store-loc h4 a span:focus{
    font-family: "DB Helvethaica X 55 Regular" !important;
    color: #464B55 !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    text-align: left !important;
    cursor: default;
}
.Store-loc h4 span{
    margin-right: 5px;
    width: 20px;
}
.Store-loc p{
    font-family: "DB Helvethaica X 55 Regular" !important;
    color:  #989CA6;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.3;
    text-align: left;
    margin-bottom: 0;
}
.Store-loc p span{ 
    margin-right: 10px;
    width: 15px;
}

@media screen and (max-width: 576px) {
    .Store-loc-mob{
        background-color: #F5F6F7;
        padding: 10px 20px 10px;
    }
    .Store-loc-mob h4{
        color: #61A60E;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0;
    }
    .Store-loc-mob h4 img{
        width: 14px;
        margin-right: 8px;
    }
    .Store-loc-mob p{
        margin-bottom: 0 !important;
        display: block;
        float: right;
    }
    .Store-loc-mob p a{
        color: #989CA6;;
        font-size: 20px;
        font-weight: 400;
    }
    .Store-loc-mob p a img{
        width: 14px;
        margin-right: 8px;
    }
    .Store-loc-mob p a{
        text-decoration: none !important;
    }
}