  .recommendedForYou-card .carousel-control-next-icon,
  .recommendedForYou-card .carousel-control-prev-icon {
    display: none !important;
  }

  .recommended-for-you-bg-bottom {
    margin-bottom: 64px;
  }

  .recommendedForYou-card .carousel-indicators button {
    background: #d3d6da;
    border: none;
    border-radius: 100%;
    height: 12px;
    width: 12px;
    margin: 3px;
  }

  .recommendedForYou-card .carousel-indicators button.active {
    background: #61a60e;
    border: none;
    border-radius: 20px;
    width: 25px;
    height: 12px;
    margin: 3px;
  }

  .recommendedForYou-card .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -50px;
    margin: auto;
  }

  .recommendedForYou-bg-outer{
    margin-bottom: 50px;
  }

  .recommendedForYou-bg {
    background-color: #F5F6F7;
    border-radius: 10px;
    padding: 0 80px;
  }

  .recommendedForYou-wraper {
    /* background-color: #F5F6F7; */
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .recommendedForYou-head {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .recommendedForYou-head-lt {
    flex: 50%;
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .recommendedForYou-head-lt img {
    width: 32px;
    margin-right: 20px;
  }

  .recommendedForYou-head-lt h1 {
    font-family: "DB Helvethaica X 65 Med" !important;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .recommendedForYou-head-rt {
    flex: 50%;
    text-align: right;
    height: 100%;
  }

  .recommendedForYou {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    flex-direction: row;
  }

  .recommendedForYou-item {
    flex: 0 0 200px;
    border-radius: 15px;
    background: #FFFFFF;
    position: relative;
    padding: 12px;
    height: fit-content;
  }

  .recommendedForYou-img {
    position: relative;
    margin-bottom: 10px;
    width: 180px;
    height: 180px;
    background: #F5F6F7;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .recommendedForYou-img img {
    width: 88px;
    height: 88px;
    object-fit: contain;
  }

  .recommendedForYou-img-badge {
    position: absolute;
    top: -25px;
    left: 5px;
  }

  .recommendedForYou-img-badge img {
    width: 100px;
  }

  .recommendedForYou-btn {
    display: flex;
    align-items: center;
  }

  .recommendedForYou-btn-lt {
    flex: 5%;
  }

  .recommendedForYou-btn-rt {
    flex: 95%;
  }

  .recommendedForYou-btn-lt button {
    outline: none;
    border: none;
    background-color: #f5f6f7;
    text-align: center;
    font-size: 13px;
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }

  .recommendedForYou-btn-lt img {
    width: 15px;
  }

  .recommendedForYou-btn-rt button {
    outline: none;
    border: none;
    color: #fff;
    background-color: #61a60e;
    text-align: center;
    font-size: 13px;
    padding: 10px;
    border-radius: 20px;
    width: 95%;
    margin: auto;
    height: 36px;
    margin-left: 5%;
  }

  .recommendedForYou-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .recommendedForYou-content p {
    background: #EDEFF2;
    border-radius: 20px;
    height: 16px;
    margin-bottom: 10px;
  }

  .recommendedForYou-content h6 {
    background: #F5F6F7;
    border-radius: 32px;
    border-radius: 20px;
    height: 36px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .recommendedForYou-content-100 {
    width: 100%;
  }

  .recommendedForYou-content-20 {
    width: 20%;
  }

  .recommendedForYou-content-30 {
    width: 30%;
  }

  .recommendedForYou-content-40 {
    width: 40%;
  }

  .recommendedForYou-content-50 {
    width: 60%;
  }

  .recommendedForYou-btn-rt button img {
    margin-right: 10px;
    width: 20px;
  }

  @media screen and (max-width: 991px) {
    .recommendedForYou-item {
      flex: 0 0 30%;
      margin-bottom: 20px;
    }
    .recommendedForYou-bg-outer{
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 767px) {
    .recommendedForYou-bg-outer{
      margin-bottom: 0px;
    }
    .recommendedForYou-item {
      flex: 0 0 45%;
      margin-bottom: 20px;
    }

    .recommendedForYou-img {
      width: 100%;
      height: 140px;
    }

    .recommendedForYou-btn-rt button {
      height: 28px;
    }

    .recommendedForYou-btn-lt button {
      height: 28px;
      width: 28px;
    }

    .recommendedForYou-btn-lt img {
      width: 12px;
    }

    .recommendedForYou-content h6 {
      height: 28px;
    }

    .recommendedForYou-content p {
      height: 12px;
      margin-bottom: 8px;
    }
    .recommendedForYou-img-badge {
      top: -15px;
      left: 5px;
  }
  .recommendedForYou-img-badge img{
    width: 60px !important;
  }
    .recommendedForYou-img img {
      width: 60px;
      height: 60px;
    }
    .recommendedForYou-head-lt h1 {
      font-size: 32px;
    }
    .container.recommendedForYou-bg{
padding: 0;
    }
    .recommendedForYou-bg{
      border-radius: 0;
    }
    .recommendedForYou-wraper {
      padding: 25px 10px 55px;
  }
  .recommendedForYou-head {
    margin-bottom: 20px;
}
.recommendedForYou-head-lt img {
  width: 24px;
  margin-right: 8px;
}
.container.recommendedForYou-bg{
  padding: 0;
}
.container-fluid.recommendedForYou-bg-outer{
  padding: 0;
}
.recommendedForYou-card .carousel-indicators {
  bottom: -25px;
}
  }