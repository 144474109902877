.customBreadcrumb {
    padding: 40px 0 10px;
    font-family: "DB Helvethaica X 55 Regular" !important;
    background-color: #fff;
}

.customBreadcrumb .breadcrumb {
    background-color: #fff !important;
}

.customBreadcrumb .breadcrumb li a {
    color: #989CA6 !important;
    font-size: 20px;
    text-transform: capitalize;
}
.customBreadcrumb li{
    display: flex;
    align-items: center;
}


.customBreadcrumb .breadcrumb-item + .breadcrumb-item::before{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px;
    content: url(./Assets/SVG/arrow.svg);
    margin-top: 6px;
}

@media screen and (max-width: 991px) {
    .customBreadcrumb {
        padding: 20px 0 0px;
    }
}

@media screen and (max-width: 576px){
.customBreadcrumb {
    padding: 15px 0 0px;
}
}