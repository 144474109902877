.myAccount {
    /* padding: 30px 90px; */
    padding: 30px 90px;
    background: #F5F5F5;
}

.myAccountHead h2 {
    font-weight: 500;
    font-size: 48px;
    color: #464B55;
    margin-top: 20px;
    margin-bottom: 50px;
    text-transform: capitalize;
}

.myAccountHead h4 {
    font-weight: 600;
    font-size: 24px;
    color: #464B55;
}

span.myAccountCard-borderBottom {
    display: flex;
    border: 0.5px solid #D3D6DA;
}

.myAccountCard {
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    padding: 25px 30px;
    margin-bottom: 20px;
    position: relative;
}

.myAccountCardHeight {
    min-height: 480px;
}

.myAccountCard h4 {
    display: flex;
    align-items: center;
}

.myAccountCard h4 span {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #464B55;
}

.myAccountCard h4 img {
    width: 13px;
    margin-right: 10px;
}

.myAccountCard p span {
    font-weight: 500;
    font-size: 20px;
    color: #989CA6;
    margin: 15px 0;
    text-transform: none;
}

.myAccountCard p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
    margin: 15px 0;
}

.myAccountCard h5 {
    display: flex;
    align-items: center;
}

.myAccountCard h5 span {
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: center;
    line-height: .7;
    color: #61A60E;
    margin: 0px 0;
    cursor: pointer;
    border-bottom: 1px solid #61A60E;
}

.add-email-address-icon {
    width: 14px;
    height: 16px;
}
.my-profile-address-image {
    width: 14px;
    height: 16px;
    margin-top: 6px;
}

.myAccountCard input[type="email"] {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    border: 2px solid #61A60E;
    color: #464B55;
    padding: 5px 12px;
    margin: 15px 0 0px;
    font-size: 20px;
    text-transform: lowercase;
    height: 48px;
    outline: none;
}

.myAccountCard-input-errorBorder{
    border: 2px solid #EF3145 !important;
}

.myAccountCard input[type="email"]:focus, .myAccountCard input[type="text"]:focus {
    outline: none;
    border: 2px solid #61A60E !important;
}

.myAccountCard button:first-child {
    margin-right: 8px;
}

.myAccountCard-save {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    min-width: 95px;
    border: 1px solid #61A60E;
    background-color: #61A60E;
    outline: none;
    border-radius: 30px;
    padding: 5px 25px;
    height: 44px;
    margin-top: 10px;
}
.myAccountCard-save-disabled{
    color: #989CA6;
    font-size: 20px;
    font-weight: 400;
    min-width: 95px;
    border: 1px solid #EDEFF2;
    background-color: #EDEFF2;
    outline: none;
    border-radius: 30px;
    padding: 5px 25px;
    height: 44px;
    margin-top: 10px;

}

.myAccountCard-cancel {
    color: #61A60E;
    font-size: 20px;
    font-weight: 400;
    min-width: 95px;
    border: 1px solid #61A60E;
    outline: none;
    border-radius: 30px;
    background-color: #fff;
    padding: 5px 25px;
    height: 44px;
    margin-top: 10px;
}

.myAccountCard-address {
    padding: 16px 20px;
    min-height: 60px;
    background: #FFFFFF;
    border: 1px solid #D3D6DA;
    border-radius: 8px;
    margin-bottom: 20px;
}
.myAccountCard-address:last-child{
    margin-bottom: 0;
}

.myAccountCard-address h5 {
    font-size: 20px;
    color: #464B55;
    /* text-transform: capitalize; */
    margin-bottom: 0;
}

.myAccountCard-address input[type="radio"] {
    margin: 0px 5px 0px 0px;
}

.myAccountCard-address label {
    color: #989CA6;
    font-size: 20px;
}

.myAccountCard-addressEdit {
    background: #FFFFFF;
    border: 2px solid #61A60E;
    border-radius: 8px;
    padding: 20px;
}

.myAccountCard-addressEdit label {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
    width: 100%;
}
.myAccountCard-addressEdit input[type="checkbox"]{
    accent-color: #61A60E;
}
.myAccountCard-addressEdit input[type="text"] {
    padding: 12px 16px;
    background: #F5F6F7;
    border-radius: 8px;
    color: #464B55;
    border: none;
    outline: none;
    width: 100%;
    /* margin-bottom: 10px; */
    height: 48px;
    font-size: 20px;
}

.myAccountCard-addressEdit textarea{
    padding: 12px 16px;
    background: #F5F6F7;
    border-radius: 8px;
    color: #464B55;
    border: none;
    outline: none;
    width: 100%;
    height: 70px;
    font-size: 20px;
}

.myAccountCard-addressEdit-delete {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #61A60E;
    color: #61A60E;
    outline: none;
    padding: 10px 16px;
    font-size: 20px;
    margin-right: 20px;
    border-radius: 24px;
    text-align: center;
    height: 44px;
    justify-content: space-around;
    width: 105px;
}

.my-profile-edit-pencil-icon {
    height: 16px;
    width: 16px;
}

.myAccountCard-addressEdit-delete img {
    width: 12px;
}

.myAccountCard-addressEdit-save {
    background-color: #61A60E;
    border: 1px solid #61A60E;
    color: #fff;
    outline: none;
    font-size: 20px;
    border-radius: 24px;
    /* padding: 7px 24px; */
    min-width: 160px;
    height: 44px;
    padding: 7px 5px;
}

.myAccountCard-addressEdit-btn{
    display: flex;
    align-items: center;
    justify-content: end;
}

.add-email-address-image {
    padding-top: 5px!important;
    padding-bottom: 10px !important;
}
.add-email-address-text{
    margin: 0 !important;
    margin-top: 8px !important;
}

.my-profile-autocomplete-main-container .MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0!important;
}
.my-profile-autocomplete-main-container .MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root {
    padding: 0!important
}
.my-profile-autocomplete-main-container .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-width: 0!important;
}
.my-profile-autocomplete-main-container .css-igs3ac {
    border-width: 0!important;
}
.my-profile-autocomplete-main-container .MuiAutocomplete-popper {
    font-size: 20px!important;
}
.my-profile-autocomplete-main-container .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator:hover {
    background-color: transparent!important;
}
.my-profile-autocomplete-main-container .css-uge3vf:hover {
    background-color: transparent!important;
}
.my-profile-autocomplete-main-container .css-113ntv0-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator:hover {
    background-color: transparent!important;
}
.my-profile-autocomplete-main-container .css-1agnrrp:hover {
    background-color: transparent!important;
}
.my-profile-note-counts {
    text-align: right;
    font-size: 20px;
    color: #989CA6;
}
.myAccountCard-address-image{
    display: flex;
    align-items: center;
    flex-direction: row;
}
p.myAccountCard-wrongemailWarning {
    font-size: 20px;
    font-weight: 400;
    word-wrap: break-word;
    color: #EF3145 !important;
    margin: 5px 0 0px !important;
    position: absolute;
}
.add-email-address-icon-main-container {
    padding-top: 10px!important;
}


@media screen and (max-width: 991px) {
    .myAccount {
        padding: 20px 30px;
    }

    .myAccountHead h2 {
        font-size: 36px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 767px) {
    .myAccountHead h2 {
        font-size: 32px;
        margin: 10px 0;
        font-weight: 600;
    }

    .myAccount {
        padding: 40px 0px 0px;
        background-color: #ffffff;
    }

    .myAccountCard {
        box-shadow: unset;
        border-radius: unset;
        padding: 0;
        margin-bottom: 10px;
        border-radius: unset;
    }
    
    .myAccountCard input[type="email"]{
        margin: 0px 0 17px;
        height: 44px;
    }

    .myAccountCard p{
        margin: 10px 0 0;
    }
    .myAccountCard p span{
        margin: 0;
    }

    .myAccountCardHeight {
        min-height: unset;
    }
    
    .myAccountCard-addressEdit-delete{
        width: 49%;
    }

    .myAccountCard-addressEdit-save{
        width: 49%;
    }
    .myAccountCard-addressEdit-delete{
        justify-content: center;
    }
    .myAccountCard-addressEdit-delete img {
        margin-right: 5px;
    }
    .myAccountCard-address{
        min-height: 56px;
    }
    .add-email-address-text{
        margin-top: -5px !important;
    }
    .myAccountCard-addressEdit-btn{
        margin-top: 20px;
    }
    .myAccountCard label{
        margin-top: 10px;
    }
    .myAccount-padxs{
        padding:20px 15px 20px;
    }
    .myAccount-mrgnxs{
        margin-bottom: 10px;
    }
    .add-email-address-image {
        padding-top: 0px!important;
        margin-bottom: -10px;
    }
    .myAccountCard-address-image{
        align-items: flex-start;
        padding-top: 8px;
    }
    .myAccountCard-address-image img{
        font-size: 12px;
        margin-top: 6px;
    }
    .my-profile-edit-pencil-icon {
        height: 14px;
        width: 14px;
    }
    .my-profile-billing-address-container {
        white-space: nowrap;
    }
    .myAccountCard-address-image p span{
        font-size: 16px;
    }
    p.myAccountCard-wrongemailWarning {
        position: unset !important;
        margin: -10px 0 12px !important;
    }
    h5.Addnewaddress-xs{
        margin-top: -5px !important;
        margin-bottom: 20px !important;
    }
    .add-email-address-icon-main-container {
        padding-top: 0px!important;
    }
}