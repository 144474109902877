.UserManagement {
    padding: 30px 0px;
    background: #F5F5F5;
}

.bulkUploadHead h2 {
    font-weight: 500;
    font-size: 48px;
    color: #464B55;
    margin-top: 0;
    margin-bottom: 30px;
}

.bulkUploadCard {
    padding: 25px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    margin-bottom: 20px;
}

.bulkUploadCard h3 {
    font-weight: 600;
    font-size: 24px;
    color: #464B55;
    margin: 0px 0 20px;
}

.bulkUploadCard-uploadFile {
    width: 100%;
    border: 1px dashed#989CA6;
    background: #FFFFFF;
    /* box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04); */
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-direction: column;
    margin-bottom: 20px;
}

.bulkUploadCard-uploadFile-img{
    width: 40px;
    margin: auto;
}
.bulkUploadCard-uploadFile p {
    font-size: 20px;
    border-bottom: 1px solid #61A60E;
    color: #61A60E;
    padding-bottom: 0px;
    margin: 15px auto 0;
    font-weight: 400;
}

.bulkUploadCard-uploadFileStatus {
    min-height: 64px;
    padding: 10px 20px;
    border-radius: 12px;
    margin-bottom: 15px;
}

.bulkUploadCard-uploadFileProgress-status {
    color: #989CA6;
    font-size: 16px;
}
.bulkUploadCard-uploadFileStatus-btn{
    outline: none;
    border: none;
    background-color: transparent;
}

.bulkUploadCard-uploadFileStatus img {
    width: 18px;
}

.bulkUploadCard-uploadFileStatus h4 {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
    width: 100%;
}

.bulkUploadCard-uploadFileStatus h4 img {
    width: 12px !important;
}

.bulkUploadCard-uploadFileSuccess {
    background: #EFF6E7;
}

.bulkUploadCard-uploadFileSuccess h4 span {
    color: #989CA6;
    width: 100%;
}

.bulkUploadCard-uploadFileProgress {
    background: #F5F6F7;
}

.bulkUploadCard-uploadFileFail {
    background: #FEEEF0;
}

.bulkUploadCard-uploadFileFail h4 span {
    color: #EF3145;
}

.bulkUploadCard-uploadBtn {
    padding: 5px 24px;
    width: 160px;
    height: 44px;
    color: #61A60E;
    background-color: #61A60E;
    border: 1px solid #61A60E;
    border-radius: 32px;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    color: #fff;
}

.bulkUploadCard-uploadBtnDownload {
    padding: 5px 24px;
    width: 160px;
    height: 44px;
    color: #61A60E;
    background-color: #61A60E;
    border: 1px solid #61A60E;
    border-radius: 32px;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    color: #fff;
    margin-right: 10px;
}

.bulkUploadCard-uploadBtnDsbld {
    padding: 5px 24px;
    width: 160px;
    height: 44px;
    color: #989CA6;
    background-color: #EDEFF2;
    border: 1px solid #EDEFF2;
    border-radius: 32px;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    text-align: center;
}

.bulkUploadCard-uploadFileHistory .accordion-item {
    border: none !important;
}

.bulkUploadCard-uploadFileHistory .accordion-body {
    padding: 0 !important;
}

.bulkUploadCard-uploadFileHistory .accordion-button {
    padding: 0 !important;
    position: relative;
}

.bulkUploadCard-uploadFileHistory .accordion-header {
    padding: 10px 0 0;
    width: 250px;
}

.bulkUploadCard-uploadFileHistory .accordion-button:not(.collapsed) {
    box-shadow: none !important;
}

.bulkUploadCard-uploadFileHistory .accordion-button::after {
    position: absolute;
    width: 16px !important;
    height: 16px !important;
    left: 150px;
    top: 10px;
    background-size: 16px;
    background-image: url(../SVG/DownArrowGrey.svg) !important;
}

.bulkUploadCard-uploadFileHistory .accordion-button:not(.collapsed)::after {
    background-image: url(../SVG/DownArrowGrey.svg) !important;
    color: green !important;
    transform: rotate(-180deg);
    top: 0px;
    left: 150px;
}

.bulkUploadCard-uploadFileHistoryHead {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.bulkUploadCard-uploadFileHistoryDesc h6 {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
}

.bulkUploadCard-uploadFileHistoryDesc-head {
    padding: 10px 15px;
    height: 48px;
    background: #F5F6F7;
    border-radius: 8px;
}

.bulkUploadCard-uploadFileHistoryHead h5 {
    font-size: 20px;
    color: #989CA6;
    margin: 0;
}

.bulkUploadCard-uploadFileHistoryDesc-body {
    padding: 10px;
    width: 99%;
    margin: 0 auto;
}

.bulkUploadCard-uploadFileHistoryDesc-body-in {
    border-bottom: 1px solid #D3D6DA;
    padding: 15px 0;
}

/* .bulkUploadCard-uploadFileHistoryDesc-body .row:last-child {
    border-bottom: none;
} */

.bulkUploadCard-uploadFileHistoryDesc-success {
    font-size: 16px !important;
    background-color: #EFF6E7;
    font-weight: 500 !important;
    color: #61A60E !important;
    padding: 0px 8px;
    text-align: center;
    border-radius: 26px;
    display: unset;
    width: 62px;
}

.bulkUploadCard-uploadFileHistoryDesc-fail {
    font-size: 16px !important;
    background-color: #FEEEF0;
    font-weight: 500 !important;
    color: #EF3145 !important;
    padding: 0px 8px;
    text-align: center;
    border-radius: 26px;
    display: unset;
    width: 62px;
}

.bulkUploadCard-uploadFileHistoryDesc-bodyEmpty {
    height: 192px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bulkUploadCard-uploadFileHistoryDesc-bodyEmpty img {
    width: 70px;
}

.bulkUploadCard-uploadFileHistoryDesc-bodyEmpty p {
    color: #989CA6;
    font-size: 20px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;
}

.bulkUploadCardRadio {
    border: 1px solid #D3D6DA;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
}

.bulkUploadCardRadio label {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #464B55;
}

.bulkUploadCardRadio-focus {
    border: 2px solid #61A60E !important;
}

.drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .label-file-upload.drag-activeproduct {
    background-color: #ffffff;
  }

  .label-file-upload.drag-activeprice {
    background-color: #ffffff;
  }

  .label-file-upload.drag-activespecialprice {
    background-color: #ffffff;
  }

  .label-file-upload.drag-activestock {
    background-color: #ffffff;
  }

  #label-file-upload {
    text-align: center;
  }

  /* #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
  } */
  
  .bulkUploadCardRadio input[type="radio"]{
    accent-color: #61A60E;
    margin-top: 0;
    cursor: pointer;
  }
  .gearImage{
    width: 10px;
    margin-top: -5px;
    margin-right: 3px;
  }