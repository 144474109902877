.inputStyle {
  /* width: 3rem !important;
  height: 3rem;
  margin: 0 5px;
  font-size: 2rem;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.3); */
  display: flex;
  justify-content: center;
  width: 52px !important;
  height: 64px !important;
  margin: 0 5px !important;
  font-size: 24px !important;
  border-radius: 12px !important;
  border: 2px solid #EDEFF2;
  background-color: #EDEFF2;
}
/* 
@media only screen and (max-width: 470px) {
  .margin-top--small .inputStyle {
    width: 2rem !important;
    height: 2rem;
    margin: 0 5px;
    font-size: 1rem;
  }
} */

.otp-form-container-outer{
  margin-top: 171px;
}

.error {
  border:  2px solid  #EF3145 !important;
  background-color: #fff !important;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  /* text-align: center; */
  /* margin-bottom: 1em; */
  font-size: 24px;
  /* color: rgb(34, 34, 34); */
  font-weight: 800;
  color: #464B55;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

/* label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
} */

.custom-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding: 10px 24px;
  gap: 6px;
  background: #61A60E;
  border-radius: 80px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-size: 12px;
  color: white
}

.return-loginbtn {
  font-family: 'DB Helvethaica X';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #61A60E;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding: 12px 24px;
}

.pass-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 16px;
  gap: 12px;
  background-color: #F5F6F7;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.custom-label {
  font-family: 'DB Helvethaica X';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #464B55;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px 20px 0px
}

.custom-label2 {
  font-family: 'DB Helvethaica X';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #464B55;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.title-lbl {
  color: #464B55;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.fade {
  opacity: 1;
  background-color: #fff;
}

.alert-error-lbl {
  /* font-style: italic; */
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Red */

  color: #EF3145;
  background-color: "#fff";
}

.otp-title {
  font-weight: 600;
  font-size: 24px;
}

.not-recieve-otp {
  text-align: center;
}

.not-recieved-text {
  text-align: center;
  margin-top: 15px;
  font-size: 20px;
}

.resend-text {
  color: #61A60E;
  margin-left: 2px;
}

.promo-btn-rt {
  margin-top: 48px;
}
@media screen and (max-width: 991px){
  .otp-form-container-outer{
    margin-top: 130px;
  }
}
@media screen and (max-width: 767px) {
  .inputStyle {
    display: flex;
    justify-content: center;
    width: 40px !important;
    height: 48px !important;
    margin: 0 5px !important;
    font-size: 20px !important;
    border-radius: 10px !important;
  }
}

@media screen and (max-width: 991px){
  .otp-form-container-outer{
    margin-top: 60px;
  }
}