.OrderManagementByProduct-head {
    padding: 30px 0 20px;
}

.OrderManagementByProduct-head h4 {
    font-weight: 500;
    font-size: 40px;
    line-height: 42px;
    color: #464B55;
}

.OrderManagementByProduct-head h4 span {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #989CA6;
}

.OrderManagementByProduct-head h5 {
    font-weight: 500;
    font-size: 32px;
    line-height: unset;
    color: #464B55;
    margin-top: -10px;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.OrderManagementByProduct-head button {
    padding: 10px 45px;
    color: #fff;
    background: #61A60E;
    border-radius: 32px;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    border: none;
}

.table-empty-data-main-container {
    text-align: center;
    margin-top: 79px;
    margin-bottom: 84px;
}

.table-empty-data-main-container .table-empty-data-image {
    height: 64px;
    width: 64px;
}

.table-empty-data-main-container .table-empty-data-heading {
    font-weight: 400;
    font-size: 20px;
    align-items: center;
    color: #989CA6;
    margin-top: 12px;
    margin-left: 10px;
}

.export-button-main-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
    border-radius: 50px;
    padding: 10px 24px;
    border: 1px solid #61A60E;
    outline: none;
    margin-top: 34px;
    float: right;
}

.export-button-main-container .export-button-heading {
    font-weight: 400;
    font-size: 20px;
    align-items: center;
    color: #61A60E;
}

.export-button-main-container .export-button-image {
    width: 18px;
    margin-right: 5px;
    height: 16px;
    margin-bottom: 8px;
}

.odmProduct-ProductDetails-ViewMore {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    /* border: 1px solid #D3D6DA; */
    /* border-radius: 0px 0px 8px 8px; */
    height: 44px;
}

.odmProduct-ProductDetails-ViewMore p {
    color: #989CA6;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
}

.odmProduct-ProductDetails-ViewMore img {
    margin-left: 10px;
}

.odmProduct-ProductDetails-ViewMore .css-1w8s6so{
    padding: 0 !important;
}

.odmProduct-ProductDetails-ViewMore .css-1w8s6so:hover{
    background-color: transparent !important;
}
.odmProduct-ProductDetails-ViewMore .css-1w8s6so:focus{
    background-color: transparent !important;
}

/* .OrderManagementByProduct .css-i4bv87-MuiSvgIcon-root {
    font-size: 30px;
    margin-top: -10px;
} */

.OrderManagementByProduct .css-mf1cb5-MuiButtonBase-root-MuiIconButton-root:hover {
    background-color: transparent !important;
}

.OrderManagementByProduct .css-mf1cb5-MuiButtonBase-root-MuiIconButton-root {
    transition: unset !important;
    padding: 0;
}

.OrderManagementByProduct .css-1q1u3t4-MuiTableRow-root {}

.OrderManagementByProduct .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    width: 16px;
    height: 16px;
}

.OrderManagementByProduct .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #61A60E !important;
    width: 16px;
    height: 16px;
}

.OrderManagementByProduct .css-11fojn7-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-size: 20px !important;
}

.OrderManagementByProduct-bg-grey {
    background: #F5F6F7;
}

.OrderManagementByProduct .css-rqglhn-MuiTable-root {
    /* border: 1px solid #D3D6DA; */
    /* border-radius: 8px 8px 0px 0px; */
    margin-bottom: 20px;
}

.OrderManagementByProduct .css-1yhpg23-MuiTableCell-root {
    padding: 0 !important;
}

.to-comfirm-table-main-container .css-11fojn7-MuiAutocomplete-root .MuiOutlinedInput-root {
    height: 100% !important;
    font-size: 20px;
}

.to-comfirm-table-main-container .css-1n0uhhu .MuiOutlinedInput-root {
    height: 100% !important;
    font-size: 20px;
}

.to-comfirm-table-main-container .css-11fojn7-MuiAutocomplete-root {
    height: 100% !important;
}

.to-comfirm-table-main-container .css-1n0uhhu {
    height: 100% !important;
}

.to-comfirm-table-main-container .css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option {
    font-size: 18px !important;
    width: 100% !important;
}

.to-comfirm-table-main-container .css-ue1yok .MuiAutocomplete-option {
    font-size: 18px !important;
    width: 100% !important;
}


.auto-complete-order-by-product-table h3{
    color:#464B55;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    text-transform: capitalize;
    width: 200px;
}

.auto-complete-order-by-product-table h3 span{
    color: #989CA6;
    font-size: 16px;
    font-weight: 400;
}
