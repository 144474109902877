.HeaderCheckoutPositionFixed{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
}
.HeaderCheckout {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 25px 90px 25px 25px;
}

.HeaderCheckout-lt {
    flex: 50%;
}

.HeaderCheckout-rt {
    flex: 50%;
    text-align: right;
}

.HeaderCheckout-rt img {
    width: 20px;
    cursor: pointer;
}

@media screen and (max-width: 1100px) {
    .HeaderCheckout {
        padding: 20px 50px 20px 20px;
    }
}

@media screen and (max-width: 991px) {
    .HeaderCheckout {
        padding: 20px;
    }
}

@media screen and (max-width: 767px) {
    .HeaderCheckout {
        padding: 12px 20px;
    }

    .HeaderCheckout-lt {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .HeaderCheckout-lt h4 {
        color: #464B55;
        font-size: 24px;
        font-weight: 600;
    }

    .HeaderCheckout-lt img {
        width: 15px;
        position: absolute;
        right: 18px;
        top: 22px;
        cursor: pointer;
    }
}