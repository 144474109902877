.OrderListHead{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px;
}
.OrderListHead h1{
    font-weight: 500;
    font-size: 56px;
    color: #464B55;
    text-transform: capitalize;
}
.OrderListHead button{
    background: #61A60E;
border-radius: 32px;
outline: none;
border: none;
color: #fff;
padding: 10px 25px;
font-size: 20px;
font-weight: 400;
}
.OrderListHead button span{
    font-size: 28px;
}
