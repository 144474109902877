.cancel-order-modal-main-container {
    position: fixed;
    z-index: 13000;
}
.cancel-order-modal-inner-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 10;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
}
.cancel-order-modal-custom-container {
    position: absolute;
    width: 576px;
    height: 394px;
    left: calc(50% - 576px/2);
    top: calc(50% - 394px/2);
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    text-align: center;
}
.cancel-order-modal-error-images {
    width: 88px;
    height: 88px;
}
.cancel-order-modal-common-container {
    margin-top: 48px;
    margin-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
}
.cancel-order-modal-main-heading {
    font-weight: 500;
    font-size: 48px;
    text-align: center;
    color: #464B55;
    margin-top: 24px;
    font-family: "DB Helvethaica X MEDIUM"!important;
}
.cancel-order-modal-main-paragraph {
    font-weight: 400;
    font-size: 20px;
    color: #464B55;
}
.cancel-order-modal-cancel-order-button {
    background: #fff;
    border-radius: 80px;
    border: none;
    font-size: 24px;
    outline: none;
    padding: 12px 24px;
    text-align: center;
    width: 100%;
    margin-top: 48px;
    border: 1px solid  #989CA6;
    font-weight: 400;
    font-size: 24px;
    align-items: center;
    color: #989CA6;
}
/* discard-button */
.cancel-order-modal-cancel-discard-button {
    background: #fff;
    border-radius: 80px;
    border: none;
    font-size: 24px;
    outline: none;
    padding: 12px 24px;
    text-align: center;
    width: 100%;
    margin-top: 48px;
    border: 1px solid #61A60E;
    font-weight: 400;
    font-size: 24px;
    align-items: center;
    color: #61A60E;
}
.cancel-order-modal-keep-order-button {
    background: #EF3145;
    border-radius: 80px;
    border: none;
    color: #fff;
    font-size: 24px;
    outline: none;
    padding: 12px 24px;
    text-align: center;
    width: 100%;
    margin-top: 48px;
}
.cancel-order-modal-keep-discard-button {
    background: #61A60E;
    border-radius: 80px;
    border: none;
    color: #fff;
    font-size: 24px;
    outline: none;
    padding: 12px 24px;
    text-align: center;
    width: 100%;
    margin-top: 48px;
}
.cancel-order-modal-delete-image {
    width: 14px;
    height: 16px;
    margin-right: 9px;
    margin-bottom: 4px;
}