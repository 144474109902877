.productCategory {
  cursor: pointer;
  line-height: 60px;
}

.chevronLeft,
.productCategory {
  position: relative;
}

.desktoparrowButton,
.desktoparrowButton1,
.leftArrow {
  display: flex;
  padding: var(--padding-sm);
  align-items: center;
  justify-content: center;
}

.leftArrow {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  flex-direction: column;
  box-sizing: border-box;
}

.desktoparrowButton,
.desktoparrowButton1 {
  /* border-radius: var(--br-lg); */
  /* background-color: var(--n10); */
  flex-direction: row;
}

.desktoparrowButton1 {
  background-color: var(--green);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
}

.desktoparrowButtonParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  font-size: var(--desktop-b2-med-size);
  color: var(--white);
  font-family: var(--font-font-awesome-6-pro);
}

.productCategoryParent {
  width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.vectorIcon,
.vectorIcon1,
.vectorIcon2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon1,
.vectorIcon2 {
  height: 89.08%;
  width: 89.08%;
  top: 5.46%;
  right: 5.46%;
  bottom: 5.46%;
  left: 5.46%;
}

.vectorIcon2 {
  mix-blend-mode: multiply;
}

.group2,
.maskGroup {
  position: absolute;
  height: 68.29%;
  width: 80.53%;
  top: 20.75%;
  right: 18.87%;
  bottom: 10.97%;
  left: 0.6%;
}

.maskGroup {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.group,
.pork {
  position: relative;
}

.group {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  width: 120px;
  height: 120px;
  flex-shrink: 0;
}

.pork {
  cursor: pointer;
  line-height: 32px;
}

.desktopcategory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.vectorIcon4 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0.06%;
  right: 0;
  bottom: -0.06%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.group5 {
  position: absolute;
  height: 91.42%;
  width: 69.94%;
  top: 18.81%;
  right: 21.17%;
  bottom: -10.24%;
  left: 8.89%;
}

.group3,
.vectorIcon8 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: -0.06%;
  right: 0;
  bottom: 0.06%;
  left: 0;
}

.vectorIcon8 {
  top: 0;
  right: -0.36%;
  bottom: 0;
  left: 0.36%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.group10,
.group6,
.group8 {
  position: absolute;
  height: 77.02%;
  width: 74.17%;
  top: 25.92%;
  right: 9.53%;
  bottom: -2.94%;
  left: 16.3%;
}

.group10,
.group6 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0.36%;
  bottom: 0;
  left: -0.36%;
}

.group10 {
  height: 120.27%;
  width: 83.6%;
  top: 24.03%;
  right: 10.57%;
  bottom: -44.3%;
  left: 5.83%;
}

.vectorIcon19,
.vectorIcon20,
.vectorIcon24,
.vectorIcon25 {
  position: absolute;
  height: 76.43%;
  width: 58.82%;
  top: 26.6%;
  right: 20.2%;
  bottom: -3.04%;
  left: 20.98%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon20,
.vectorIcon24,
.vectorIcon25 {
  height: 40.33%;
  width: 40.33%;
  top: 54.22%;
  right: 29.45%;
  bottom: 5.45%;
  left: 30.22%;
}

.vectorIcon24,
.vectorIcon25 {
  height: 57.58%;
  width: 57.57%;
  top: 42.42%;
  right: 42.43%;
  bottom: 0;
  left: 0;
}

.vectorIcon25 {
  height: 69.2%;
  width: 69.2%;
  top: 0;
  right: 0;
  bottom: 30.8%;
  left: 30.8%;
}

.group13 {
  position: absolute;
  height: 79.01%;
  width: 79%;
  top: 20.5%;
  right: 20.76%;
  bottom: 0.5%;
  left: 0.25%;
}

.processed1 {
  line-height: 32px;
}

.processed {
  margin-block-start: 0;
  margin-block-end: 0;
}

.meat1 {
  line-height: 24px;
}

.meat {
  margin: 0;
}

.processedMeat {
  cursor: pointer;
  position: relative;
}

.vectorIcon30,
.vectorIcon31 {
  position: absolute;
  height: 77.38%;
  width: 74.29%;
  top: 0;
  right: 25.71%;
  bottom: 22.62%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon31 {
  height: 81.06%;
  width: 77.85%;
  top: 18.94%;
  right: 0;
  bottom: 0;
  left: 22.15%;
}

.vectorContainer {
  position: absolute;
  height: 80.98%;
  width: 84.32%;
  top: 20.71%;
  right: 15.3%;
  bottom: -1.69%;
  left: 0.37%;
}

.vectorIcon35,
.vectorIcon36,
.vectorIcon37,
.vectorIcon38 {
  position: absolute;
  height: 19.72%;
  width: 18.94%;
  top: 21.25%;
  right: 52.94%;
  bottom: 59.04%;
  left: 28.12%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon36,
.vectorIcon37,
.vectorIcon38 {
  height: 4.02%;
  width: 4.02%;
  top: 31.15%;
  right: 26.37%;
  bottom: 64.83%;
  left: 69.61%;
}

.vectorIcon37,
.vectorIcon38 {
  height: 5.04%;
  width: 4.99%;
  top: 29.84%;
  right: 35.34%;
  bottom: 65.12%;
  left: 59.68%;
}

.vectorIcon38 {
  height: 5.09%;
  width: 5.84%;
  top: 53.63%;
  right: 27.58%;
  bottom: 41.28%;
  left: 66.58%;
}

.vectorIcon39,
.vectorIcon40,
.vectorIcon41,
.vectorIcon42,
.vectorIcon43 {
  position: absolute;
  height: 5.87%;
  width: 4.94%;
  top: 48.4%;
  right: 18.94%;
  bottom: 45.73%;
  left: 76.12%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon40,
.vectorIcon41,
.vectorIcon42,
.vectorIcon43 {
  height: 4.52%;
  width: 4.72%;
  top: 22.4%;
  right: 29.49%;
  bottom: 73.08%;
  left: 65.79%;
}

.vectorIcon41,
.vectorIcon42,
.vectorIcon43 {
  height: 3.34%;
  width: 3.77%;
  top: 39.03%;
  right: 38.4%;
  bottom: 57.63%;
  left: 57.83%;
}

.vectorIcon42,
.vectorIcon43 {
  height: 4.47%;
  width: 4.74%;
  top: 42.06%;
  right: 45.99%;
  bottom: 53.46%;
  left: 49.26%;
}

.vectorIcon43 {
  height: 16.87%;
  width: 21.04%;
  top: 35.19%;
  right: 62.07%;
  bottom: 47.94%;
  left: 16.89%;
}

.vectorIcon44,
.vectorIcon45,
.vectorIcon46,
.vectorIcon51,
.vectorIcon52 {
  position: absolute;
  height: 21.88%;
  width: 47.12%;
  top: 33.66%;
  right: 13.31%;
  bottom: 44.46%;
  left: 39.57%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon45,
.vectorIcon46,
.vectorIcon51,
.vectorIcon52 {
  height: 10.94%;
  width: 9.78%;
  top: 30.21%;
  right: 43.41%;
  bottom: 58.84%;
  left: 46.81%;
}

.vectorIcon46,
.vectorIcon51,
.vectorIcon52 {
  height: 37.23%;
  width: 71.17%;
  top: 48.42%;
  right: 16.26%;
  bottom: 14.35%;
  left: 12.57%;
}

.vectorIcon51,
.vectorIcon52 {
  height: 20.32%;
  width: 20.89%;
  top: 37.19%;
  right: 47.29%;
  bottom: 42.49%;
  left: 31.81%;
}

.vectorIcon52 {
  height: 20.97%;
  width: 21.54%;
  top: 40.94%;
  right: 43.01%;
  bottom: 38.09%;
  left: 35.45%;
}

.vectorIcon53,
.vectorIcon54,
.vectorIcon55,
.vectorIcon56,
.vectorIcon57,
.vectorIcon58 {
  position: absolute;
  height: 3.93%;
  width: 3.92%;
  top: 55.32%;
  right: 23.99%;
  bottom: 40.75%;
  left: 72.09%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon54,
.vectorIcon55,
.vectorIcon56,
.vectorIcon57,
.vectorIcon58 {
  top: 69.91%;
  right: 9.69%;
  bottom: 26.15%;
  left: 86.39%;
}

.vectorIcon55,
.vectorIcon56,
.vectorIcon57,
.vectorIcon58 {
  top: 56.05%;
  right: 10.63%;
  bottom: 40.02%;
  left: 85.45%;
}

.vectorIcon56,
.vectorIcon57,
.vectorIcon58 {
  width: 3.94%;
  top: 67.24%;
  right: 46.28%;
  bottom: 28.82%;
  left: 49.79%;
}

.vectorIcon57,
.vectorIcon58 {
  height: 3.9%;
  top: 70.03%;
  right: 20.09%;
  bottom: 26.06%;
  left: 75.97%;
}

.vectorIcon58 {
  top: 57.23%;
  right: 29.37%;
  bottom: 38.87%;
  left: 66.69%;
}

.vectorIcon59,
.vectorIcon60,
.vectorIcon61,
.vectorIcon62,
.vectorIcon63,
.vectorIcon64,
.vectorIcon66 {
  position: absolute;
  height: 3.9%;
  width: 3.94%;
  top: 67.52%;
  right: 29.32%;
  bottom: 28.57%;
  left: 66.75%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon60,
.vectorIcon61,
.vectorIcon62,
.vectorIcon63,
.vectorIcon64,
.vectorIcon66 {
  top: 82.04%;
  right: 19.05%;
  bottom: 14.06%;
  left: 77.01%;
}

.vectorIcon61,
.vectorIcon62,
.vectorIcon63,
.vectorIcon64,
.vectorIcon66 {
  top: 89.04%;
  right: 34.89%;
  bottom: 7.06%;
  left: 61.17%;
}

.vectorIcon62,
.vectorIcon63,
.vectorIcon64,
.vectorIcon66 {
  top: 75.81%;
  right: 35.63%;
  bottom: 20.29%;
  left: 60.43%;
}

.vectorIcon63,
.vectorIcon64,
.vectorIcon66 {
  top: 59.57%;
  right: 37.88%;
  bottom: 36.53%;
  left: 58.18%;
}

.vectorIcon64,
.vectorIcon66 {
  height: 3.62%;
  width: 2.95%;
  top: 53.5%;
  right: 40.74%;
  bottom: 42.88%;
  left: 56.31%;
}

.vectorIcon66 {
  height: 15.73%;
  width: 17.95%;
  top: 82.95%;
  right: 33.57%;
  bottom: 1.32%;
  left: 48.48%;
}

.group15 {
  position: absolute;
  height: 20.82%;
  width: 21.19%;
  top: 43.9%;
  right: 40.53%;
  bottom: 35.28%;
  left: 38.27%;
}

.vectorIcon67,
.vectorIcon68 {
  position: absolute;
  height: 3.9%;
  width: 3.94%;
  top: 64.67%;
  right: 14.82%;
  bottom: 31.43%;
  left: 81.24%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon68 {
  width: 3.93%;
  top: 71.7%;
  right: 37.06%;
  bottom: 24.4%;
  left: 59.01%;
}

.vectorIcon69,
.vectorIcon70,
.vectorIcon71,
.vectorIcon72,
.vectorIcon73 {
  position: absolute;
  height: 3.94%;
  width: 3.95%;
  top: 81.28%;
  right: 26.9%;
  bottom: 14.79%;
  left: 69.15%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon70,
.vectorIcon71,
.vectorIcon72,
.vectorIcon73 {
  height: 3.92%;
  width: 3.93%;
  top: 82.05%;
  right: 39.76%;
  bottom: 14.02%;
  left: 56.31%;
}

.vectorIcon71,
.vectorIcon72,
.vectorIcon73 {
  top: 90.47%;
  right: 50.71%;
  bottom: 5.6%;
  left: 45.36%;
}

.vectorIcon72,
.vectorIcon73 {
  height: 3.93%;
  width: 3.94%;
  top: 79.59%;
  right: 51.64%;
  bottom: 16.48%;
  left: 44.42%;
}

.vectorIcon73 {
  height: 50.25%;
  width: 49.91%;
  top: 2.24%;
  right: 52.25%;
  bottom: 47.51%;
  left: -2.16%;
}

.desktopcategory8 {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.desktopcategoryIcon,
.frameContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.desktopcategoryIcon {
  flex-direction: row;
  gap: 34px;
  text-align: center;
  font-size: var(--desktop-b1-med-size);
}

.frameContainer {
  flex-direction: column;
  gap: var(--gap-2xl);
}

.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-2xs);
  background-color: var(--white);
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
  width: 1376px;
  height: 520px;
}

.conceptCookingWithRawBeefIcon {
  position: absolute;
  top: 0;
  left: 152px;
  border-radius: 0 var(--br-2xs) var(--br-2xs) 0;
  width: 1224px;
  height: 520px;
  object-fit: cover;
}

.frameChild,
.frameItem {
  position: relative;
  height: 12px;
  flex-shrink: 0;
}

.frameChild {
  border-radius: var(--br-xs);
  background-color: var(--green);
  width: 24px;
  transform: rotate(90deg);
  transform-origin: 0 0;
}

.frameItem {
  width: 12px;
}

.rectangleGroup {
  position: absolute;
  height: 23.08%;
  top: 92.31%;
  bottom: -15.38%;
  left: calc(50% - 60px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  transform: rotate(-90deg);
  transform-origin: 0 0;
}

.vectorIcon74,
.vectorIcon75 {
  position: absolute;
  height: 18.14%;
  width: 11.31%;
  top: -0.82%;
  right: 40.37%;
  bottom: 82.69%;
  left: 48.32%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon75 {
  height: 92.27%;
  width: 100%;
  top: 7.73%;
  right: 0;
  bottom: 0;
  left: 0;
}

.div,
.off,
.upTo {
  position: relative;
  line-height: 38.74px;
  font-weight: 900;
}

.div,
.off {
  font-size: 66.66px;
  line-height: 66.66px;
}

.off {
  font-size: 71.66px;
}

.upToParent {
  position: absolute;
  top: 171.16px;
  left: 38.33px;
  width: 153.33px;
  height: 172.94px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.vectorIcon76,
.vectorIcon77,
.vectorIcon78 {
  position: absolute;
  height: 59.84%;
  width: 80.2%;
  top: 34.44%;
  right: 9.9%;
  bottom: 5.72%;
  left: 9.9%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon77,
.vectorIcon78 {
  height: 20.99%;
  width: 80.21%;
  top: 13.45%;
  right: 9.89%;
  bottom: 65.56%;
}

.vectorIcon78 {
  height: 18.13%;
  width: 11.54%;
  top: -0.81%;
  right: 47.04%;
  bottom: 82.68%;
  left: 41.42%;
}

.group16 {
  position: absolute;
  height: 76.59%;
  width: 16.67%;
  top: 7.45%;
  right: 79.67%;
  bottom: 15.96%;
  left: 3.66%;
  box-shadow: 15.292357444763184px 15.292357444763184px 21.86px rgba(0, 0, 0, 0.1);
}

.rectangleParent,
.sale1Icon {
  position: relative;
  width: 1376px;
  height: 520px;
  flex-shrink: 0;
}

.sale1Icon {
  width: 48px;
  height: 48px;
  object-fit: cover;
}

.promotion {
  position: relative;
  line-height: 60px;
}

.sale1Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.login {
  position: relative;
  line-height: 32px;
}

.frameChild2 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
}

.loginParent {
  flex-direction: column;
  justify-content: flex-start;
}

.desktopbutton,
.loginParent,
.rightArrow1 {
  display: flex;
  align-items: center;
}

.rightArrow1 {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-sm);
  box-sizing: border-box;
  justify-content: center;
  font-size: var(--font-size-sm);
  font-family: var(--font-font-awesome-6-pro);
}

.desktopbutton {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-4xs);
  font-size: var(--desktop-b1-med-size);
  color: var(--green);
}

.rectangleIcon {
  position: relative;
  border-radius: var(--br-3xs);
  width: 200px;
  height: 180px;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 0;
}

.vectorIcon79,
.vectorIcon80,
.vectorIcon81 {
  position: absolute;
  height: 54.76%;
  width: 30.37%;
  top: 32.45%;
  right: 63.95%;
  bottom: 12.78%;
  left: 5.68%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon80,
.vectorIcon81 {
  height: 72.11%;
  width: 96.34%;
  top: 0;
  right: 0;
  bottom: 27.89%;
  left: 3.66%;
}

.vectorIcon81 {
  height: 15.25%;
  width: 51.44%;
  top: 84.75%;
  right: 48.56%;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  mix-blend-mode: multiply;
}

.promo {
  position: absolute;
  top: 16.33%;
  left: 16.47%;
  line-height: 13px;
  font-weight: 900;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.desktopsaleTag {
  position: absolute;
  margin: 0 !important;
  top: 4px;
  left: 2px;
  width: 85px;
  height: 49px;
  flex-shrink: 0;
  z-index: 1;
}

.div1 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  width: 120px;
  height: 40px;
  flex-shrink: 0;
}

/* .container,
.div2 {
  display: flex;
  align-items: center;
} */

.div2 {
  position: relative;
  line-height: 24px;
  width: 12px;
  height: 24px;
  flex-shrink: 0;
}

/* .container {
  flex-direction: row;
  justify-content: flex-start;
} */

.kgpack {
  position: relative;
  font-size: var(--desktop-caption-med-size);
  line-height: 20px;
  color: var(--n40);
}

.frameParent7 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: var(--desktop-b2-med-size);
  color: var(--red);
}

.parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.kgFrozen {
  position: relative;
  line-height: 24px;
}

.downArrow {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: var(--desktop-caption-med-size);
  color: var(--n40);
  font-family: var(--font-font-awesome-6-pro);
}

.desktopvariantDropdown {
  align-self: stretch;
  border-radius: var(--br-md);
  background-color: var(--n0);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-lg) var(--padding-xs) var(--padding-xl);
  align-items: center;
  justify-content: space-between;
  font-size: var(--desktop-b2-med-size);
}

.desktopiconMenu {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  padding: var(--padding-sm);
  box-sizing: border-box;
}

.cart,
.desktopiconMenu,
.desktoploveButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.desktoploveButton {
  border-radius: var(--br-md);
  background-color: var(--n0);
  padding: var(--padding-2xs);
}

.cart {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  padding: var(--padding-sm);
  box-sizing: border-box;
}

.addToCart {
  position: relative;
  font-size: var(--desktop-b2-med-size);
  line-height: 24px;
  font-family: var(--desktop-h2);
}

.desktopaddToCartButton,
.desktoploveButtonParent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.desktopaddToCartButton {
  flex: 1;
  border-radius: var(--br-md);
  background-color: var(--green);
  height: 36px;
  padding: var(--padding-xs) var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-3xs);
  color: var(--white);
}

.desktoploveButtonParent {
  width: 200px;
  align-items: flex-start;
  gap: var(--gap-2xs);
  color: var(--n40);
  font-family: var(--font-font-awesome-6-pro);
}

.frameParent6,
.rectangleContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}

.frameParent6 {
  width: 200px;
  align-items: flex-start;
  z-index: 2;
  text-align: left;
  font-size: var(--desktop-caption-med-size);
  color: var(--n60);
  font-family: var(--desktop-h2);
}

.rectangleContainer {
  align-items: center;
  position: relative;
}

.desktopproductCard {
  border-radius: var(--br-2xs);
  background-color: var(--white);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  padding: var(--padding-lg);
  align-items: flex-start;
  justify-content: flex-start;
}

.desktopsaleTag4 {
  position: absolute;
  margin: 0 !important;
  top: 4px;
  left: 2px;
  width: 85px;
  height: 49px;
  flex-shrink: 0;
  z-index: 1;
  color: var(--white);
}

.vectorIcon94,
.vectorIcon95,
.vectorIcon96 {
  position: absolute;
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vectorIcon94 {
  height: 12.5%;
  width: 70.3%;
  top: 87.5%;
  right: 0;
  left: 29.7%;
  opacity: 0.6;
  mix-blend-mode: multiply;
}

.vectorIcon95,
.vectorIcon96 {
  height: 32.94%;
  width: 30.06%;
  top: 67.06%;
  right: 4.98%;
  left: 64.97%;
}

.vectorIcon96 {
  height: 83.31%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 16.69%;
  left: 0;
}

.new {
  position: relative;
  line-height: 13px;
  font-weight: 900;
}

.newParent {
  position: absolute;
  top: 7px;
  left: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.group17,
.group18 {
  position: absolute;
  height: 87.02%;
  width: 93.13%;
  top: 0;
  right: 6.87%;
  bottom: 12.98%;
  left: 0;
}

.group17 {
  height: 100%;
  width: 99.83%;
  right: 0.17%;
  bottom: 0;
}

.desktopsaleTag5 {
  position: absolute;
  margin: 0 !important;
  top: 2px;
  left: 2px;
  width: 57px;
  height: 56px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
  z-index: 2;
}

.desktoploveButtonParent2,
.parent7 {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.desktoploveButtonParent2 {
  top: 96px;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-2xs);
  color: var(--n40);
  font-family: var(--font-font-awesome-6-pro);
}

.frameParent14 {
  position: relative;
  width: 200px;
  height: 132px;
  flex-shrink: 0;
  z-index: 3;
  text-align: left;
  font-size: var(--desktop-caption-med-size);
  font-family: var(--desktop-h2);
}

.desktopproductCard4 {
  border-radius: var(--br-2xs);
  background-color: var(--white);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: var(--padding-lg);
  color: var(--n60);
}

.desktopproductCard4,
.desktopproductCard5,
.desktopproductCardParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.desktopproductCardParent {
  gap: var(--gap-md);
}

.desktopproductCard5 {
  border-radius: var(--br-2xs);
  background-color: var(--white);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: var(--padding-lg);
  color: var(--white);
}

.desktoploveButton6 {
  border-radius: var(--br-md);
  background-color: var(--red);
  padding: var(--padding-2xs);
  align-items: center;
}

.desktopaddToCartButton6,
.desktoploveButton6,
.desktoploveButtonParent4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.desktopaddToCartButton6 {
  flex: 1;
  border-radius: var(--br-md);
  background-color: var(--green);
  height: 36px;
  padding: var(--padding-xs) var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-3xs);
}

.desktoploveButtonParent4 {
  width: 200px;
  align-items: flex-start;
  gap: var(--gap-2xs);
  color: var(--white);
  font-family: var(--font-font-awesome-6-pro);
}

.frameParent23,
.upArrow {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: var(--desktop-b2-med-size);
}

.upArrow {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  padding: var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: var(--desktop-caption-med-size);
  font-family: var(--font-font-awesome-6-pro);
}

.desktopvariantDropdown6 {
  align-self: stretch;
  border-radius: var(--br-md);
  background-color: var(--white);
  border: 2px solid var(--green);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-lg) var(--padding-xs) var(--padding-xl);
  align-items: center;
  justify-content: space-between;
  font-size: var(--desktop-b2-med-size);
}

.frameParent22 {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
  z-index: 3;
  text-align: left;
  font-size: var(--desktop-caption-med-size);
  font-family: var(--desktop-h2);
}

.div28 {
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 224px;
  flex-shrink: 0;
}

.desktopdropdownOption {
  color: var(--green);
}

.desktopdropdownOption,
.desktopdropdownOption1,
.desktopdropdownOption2 {
  align-self: stretch;
  height: 36px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}

.desktopdropdownOption2 {
  background-color: var(--n0);
}

.desktopdropdownOptionParent,
.frameParent25 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: var(--desktop-b2-med-size);
}

.desktopdropdownOptionParent {
  margin: 0 !important;
  position: absolute;
  top: 284px;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--white);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  width: 200px;
  padding: var(--padding-lg) 0;
  box-sizing: border-box;
  align-items: center;
  z-index: 4;
  text-align: left;
  color: var(--n40);
  font-family: var(--desktop-h2);
}

.frameParent25 {
  align-items: flex-end;
  color: var(--almost-black);
}

.desktopplusMinusButtonChild {
  position: relative;
  width: 11.5px;
  height: 1.5px;
  flex-shrink: 0;
}

.desktopplusMinusButton {
  border-radius: var(--br-sm);
  background-color: var(--white);
  width: 28px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 14px var(--padding-md);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.div37 {
  flex: 1;
  position: relative;
  line-height: 24px;
}

.desktopplusMinusButtonItem {
  position: relative;
  width: 11.5px;
  height: 11.5px;
  flex-shrink: 0;
}

.desktopplusMinusButton1 {
  border-radius: var(--br-sm);
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  padding: var(--padding-md);
  align-items: center;
  justify-content: center;
}

.desktopplusMinusButtonParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}

.desktopaddToCartButton9 {
  flex: 1;
  border-radius: 40px;
  background-color: var(--green);
  height: 36px;
  flex-direction: column;
  padding: var(--padding-2xs);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--desktop-b2-med-size);
  color: var(--white);
  font-family: var(--desktop-h2);
}

.desktopaddToCartButton9,
.desktopproductCardGroup,
.frameParent5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.desktopproductCardGroup {
  flex-direction: row;
  gap: var(--gap-md);
  color: var(--n60);
}

.frameParent5 {
  flex-direction: column;
  gap: var(--gap-lg);
}

.rectangleDiv {
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--green);
  width: 24px;
  height: 12px;
  flex-shrink: 0;
}

.rectangleParent10 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.frameParent4 {
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--white);
  font-family: var(--font-montserrat);
}

.frameParent2,
.frameParent4,
.frameWrapper {
  display: flex;
  flex-direction: column;
}

.frameParent2 {
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xl);
}

.frameWrapper {
  border-radius: var(--br-2xs);
  background-color: var(--n0);
  padding: 48px 88px;
  align-items: flex-start;
  justify-content: flex-start;
}

.desktopsaleTag14 {
  position: absolute;
  margin: 0 !important;
  top: 2px;
  left: 2px;
  width: 57px;
  height: 56px;
  flex-shrink: 0;
  overflow: hidden;
  z-index: 1;
}

.desktopaddToCartButton14,
.frameParent29 {
  display: flex;
  font-family: var(--desktop-h2);
}

.frameParent29 {
  width: 200px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
  z-index: 2;
  text-align: left;
  font-size: var(--desktop-caption-med-size);
}

.desktopaddToCartButton14 {
  flex: 1;
  border-radius: var(--br-md);
  background-color: var(--n10);
  height: 36px;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: var(--desktop-b2-med-size);
}

.frameParent1,
.frameParent28 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.frameParent28 {
  justify-content: center;
  gap: var(--gap-xl);
  text-align: center;
  font-size: var(--font-size-xs);
  font-family: var(--font-montserrat);
}

.frameParent1 {
  justify-content: flex-start;
  gap: var(--gap-3xl);
}

.groupItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 1376px;
  height: 480px;
}

.letsShopAt {
  position: relative;
  line-height: 70px;
}

.search {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  padding: var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.login2 {
  position: relative;
  font-size: var(--desktop-b1-med-size);
  line-height: 32px;
  font-family: var(--desktop-h2);
}

.desktopbutton2,
.rightArrow3 {
  flex-shrink: 0;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
}

.rightArrow3 {
  width: 20px;
  height: 20px;
  flex-direction: column;
  padding: var(--padding-sm);
  align-items: center;
}

.desktopbutton2 {
  border-radius: 80px;
  background-color: var(--white);
  width: 264px;
  flex-direction: row;
  padding: var(--padding-lg) 24px;
  align-items: baseline;
  gap: var(--gap-3xs);
  font-size: var(--desktop-caption-med-size);
  color: var(--green);
  font-family: var(--font-font-awesome-6-pro);
}

.groupIcon {
  position: relative;
  width: 164px;
  height: 164px;
  flex-shrink: 0;
}

.groupGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 160px;
}

.varietyOfHighContainer {
  position: relative;
  line-height: 30px;
}

.varietyOfHighQualitySafeWrapper {
  width: 176px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.frameParent41 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 108px;
}

.frameParent39,
.frameParent40 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.frameParent40 {
  justify-content: flex-start;
  gap: var(--gap-lg);
  text-align: center;
  font-size: var(--font-size-2xl);
}

.frameParent39 {
  position: absolute;
  top: 57px;
  left: 88px;
  justify-content: center;
  gap: var(--gap-3xl);
}

.groupParent1 {
  position: relative;
  width: 1376px;
  height: 480px;
  flex-shrink: 0;
}

.desktopletsShopModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--desktop-h1-size);
  color: var(--white);
}

.frameDiv {
  align-items: flex-start;
  gap: var(--gap-3xl);
  text-align: left;
  font-size: var(--desktop-h2-size);
  color: var(--n60);
  font-family: var(--desktop-h2);
}

.frameDiv,
.frameGroup,
.groupContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.groupContainer {
  align-items: flex-start;
  gap: var(--gap-2xl);
  text-align: center;
  font-size: 45.24px;
  color: var(--white);
  font-family: var(--font-montserrat);
}

.frameGroup {
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  gap: var(--gap-3xl);
}

.frameParent {
  position: relative;
  width: 100%;
  height: 300px;
  text-align: left;
  font-size: var(--desktop-h2-size);
  color: var(--n60);
  font-family: var(--desktop-h2);
}