.sbb-carousel{
    background-color: #ffffff;
    border-radius: 100px;
    width: 90%;
    margin: auto;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
}
.sbb-carousel .sbb-carousel-item{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
}

.gridRow{
    width: 100%
}
.sbb-carousel .sbb-carousel-item .sbb-carousel-item-img{
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #989CA6;
}

.sbb-carousel .sbb-carousel-item .sbb-carousel-item-img:last-child{
    border-right: none;
}

.sbb-carousel .sbb-carousel-item img{
    width: 150px;
    height: 80px;
}
.shopByBrand-slider-section .sbb-carousel{
    width: 82%;
}
.shopByBrand-slider-section .kVtMtA{
    left: -64px;
    background: rgba(255, 255, 255, 0.8)!important;
    z-index: 99!important;
    display: none;
}
.shopByBrand-slider-section .bhRnqO{
    right: -64px;
    background: rgba(255, 255, 255, 0.8)!important;
    z-index: 99!important;
    display: none;
}
.shopByBrand-slider-section .gray-line-shopbrand {
  position: absolute;
  width:100%;
  height: 40%;
  border-left: 6px solid green;
  height: 500px;
}
.shopByBrand-slider-section .sbb-carousel .gray-line-shop-brand{
    position: absolute;
    top: 20%;
    height: 66%;
    border: 0.826667px solid #989CA6;
}
@media screen and (max-width: 991px) {
    .sbb-carousel{
        width: 65%;
    }
    .sbb-carousel .sbb-carousel-item img{
        width: 120px;
    }
}

@media screen and (max-width: 576px) {
    .sbb-carousel .sbb-carousel-item{
        border-right: none;
    }
}